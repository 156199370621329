import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { Tree as AntTree, TreeProps, Typography } from "antd";
import axios from "@/axios";
import useSWR from "swr";
import Spin from "@/shared/components/spin";
import TreeNodeIcon from "@/entities/tree-node/ui/icon";
import { DataNode as BaseDataNode } from "antd/es/tree";
import { TreeNode } from "@/models";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Course } from "@/entities/course/lib/modal";
import type RcTree from "rc-tree";
import styled from "styled-components";

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
};

type DataNode = BaseDataNode & {
  meta: {
    treeNode: TreeNode;
  };
};

const Tree = styled(AntTree<DataNode>)`
  .ant-tree-list-scrollbar {
    display: none;
  }
  .ant-tree-list-holder {
    overflow-y: auto !important;
    overflow-anchor: none;
  }
`;

type SWRHook = {
  mutate: () => Promise<any>;
  getData: () => any;
  scrollTo: (key: number) => void;
};

type CourseEmbedsTreeProps = Partial<TreeProps<DataNode>> & {
  id: string | number;
  swrRef?: Ref<SWRHook>;
  selectedTreeNode?: TreeNode;
};

const CourseEmbedsTree: React.FC<CourseEmbedsTreeProps> = ({
  id,
  swrRef,
  selectedTreeNode,
  ...treeProps
}) => {
  const { height } = useWindowDimensions();

  const scrollTo: SWRHook["scrollTo"] = (key) => {
    setTimeout(() => {
      if (treeRef.current && key) {
        treeRef.current.scrollTo({ key, align: "auto" });
      }
    }, 500);
  };

  useEffect(() => {
    const key = treeProps?.selectedKeys?.[0];
    scrollTo(Number(key));
  }, []);

  const treeRef = React.useRef<RcTree>(null);
  const { data, isLoading, mutate } = useSWR(
    `/api/courses/${String(id)}?include=tree_node,tree_node.tree`,
    async (url) => {
      return axios
        .get<OrionRestShowResponse<Course>>(url)
        .then((res) => res.data.data);
    },
  );

  useImperativeHandle<any, any>(swrRef, () => ({
    mutate,
    getData: () => data,
    scrollTo: scrollTo,
  }));

  if (isLoading) return <Spin />;

  const dataMapper = (node: any): DataNode => {
    return {
      key: node.id,
      title: <Typography.Text>{node.is_an.name}</Typography.Text>,
      icon: <TreeNodeIcon type={node.is_an_type} />,
      children: node.tree.map(dataMapper),
      meta: { treeNode: node },
    };
  };

  const treeData: TreeProps<DataNode>["treeData"] =
    data!.tree_node!.tree!.map(dataMapper);

  return (
    <Tree
      showIcon
      height={height - 172 - 20 - 40 - 32 - 12}
      ref={treeRef}
      treeData={treeData}
      {...treeProps}
    />
  );
};

export default CourseEmbedsTree;
export type { CourseEmbedsTreeProps, SWRHook, DataNode };
