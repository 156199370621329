import React from "react";
import { Course, Pool } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { ActionType } from "@ant-design/pro-table/es/typing";
import PoolRemoveButton from "@/entities/pool/ui/button-remove";
import PoolStartButton from "@/entities/pool/ui/button-start";
import PoolCompleteButton from "@/entities/pool/ui/button-complete";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { Tag } from "antd";
import { RestCallbacks } from "@/shared/rest/lib/types";
import Button from "@/shared/ant-design/button/ui/button";
import { useSearchParams } from "react-router-dom";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import useFeatures from "@/entities/features/lib/use.ts";
import PoolModalForm from "@/entities/pool/ui/modal-form.tsx";

type Record = Pool;

type Params = Partial<Pool>;

type TableProps = BaseProTableProps<Record, Params>;

type PoolTableProps = TableProps & {
  courseId: Course["id"];
  rest?: RestCallbacks<Record>;
};

const PoolTable: React.FC<PoolTableProps> = ({
  courseId,
  rest = {},
  ...props
}) => {
  const [, setUrlParams] = useSearchParams();
  const actionRef = React.useRef<ActionType>();
  const features = useFeatures();

  const request: TableProps["request"] = async (params, sort, filter) => {
    const config = {
      method: "POST",
      url: "/api/pools/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    config.data.filters.push({
      field: "course_id",
      operator: "=",
      value: courseId,
    });

    const data = await axios
      .request<OrionRestIndexResponse<Pool>>(config)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  /** Create form */

  const defaultProps: TableProps = {
    rowKey: "id",
    actionRef,
    request,
    search: false,
    options: false,
    toolBarRender: (_, { selectedRowKeys, selectedRows }) => {
      const toUpdateRecordKey =
        selectedRowKeys?.length === 1
          ? Number(selectedRowKeys?.[0])
          : undefined;

      return [
        <PoolRemoveButton
          key={"delete"}
          poolsIds={selectedRowKeys?.map(Number)}
          afterRemove={() => {
            actionRef.current?.clearSelected?.();
            actionRef.current?.reload();
            rest.onAfterDelete?.();
          }}
        />,
        <PoolStartButton
          key="start"
          poolsIds={selectedRowKeys?.map(Number)}
          afterUpdate={() => actionRef.current?.reload()}
        />,
        <PoolCompleteButton
          key="complete"
          poolsIds={selectedRowKeys?.map(Number)}
          afterUpdate={() => actionRef.current?.reload()}
        />,
        <>
          <PoolModalForm
            key={`update-${toUpdateRecordKey}`}
            courseId={courseId}
            rest={{
              type: "update",
              recordKey: toUpdateRecordKey,
              onAfterUpdate: (pool) => {
                actionRef.current?.reload();
                rest.onAfterUpdate?.(pool);
              },
            }}
          />
        </>,
        <Button
          key={"copy"}
          type={"primary"}
          disabled={selectedRows!.length !== 1}
          onClick={() => {
            setUrlParams((prev) => {
              if (selectedRows!.length !== 1) throw new Error("Invalid state");

              prev.set("pool_create", "true");
              prev.set("pool_create_from", selectedRows![0].id.toString());
              return prev;
            });
          }}
        >
          Копировать
        </Button>,
        <PoolModalForm
          key={"create"}
          courseId={courseId}
          rest={{
            type: "create",
            onAfterCreate: (pool) => {
              actionRef.current?.reload();
              rest.onAfterCreate?.(pool);
            },
          }}
        />,
      ];
    },
    pagination: { showSizeChanger: true },
    rowSelection: {},
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        filters: true,
        filterMode: "search",
        renderText: (text) => (
          <span style={{ boxSizing: "border-box", wordBreak: "break-word" }}>
            {text}
          </span>
        ),
      },
      {
        title: "Идентификатор потока",
        dataIndex: "key",
        filters: true,
        filterMode: "search",
        hideInTable: !features.isEnabled("pools_keys"),
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: {
          waiting_start: { text: <Tag color="orange">Ожидает запуска</Tag> },
          started: { text: <Tag color="green">Запущен</Tag> },
          completed: { text: <Tag color="red">Завершен</Tag> },
        },
        filters: true,
      },
      {
        title: "Дата и время создания",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
        sorter: true,
      },
      {
        title: "Дата и время начала",
        dataIndex: "starts_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: "Дата и время завершения",
        dataIndex: "ends_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: "Количество участников",
        dataIndex: "participants_count",
        sorter: true,
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return <ProTable<Pool> {...props} />;
};

export default PoolTable;
export type { PoolTableProps };
