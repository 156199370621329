import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import VersionContext from "@/entities/version/lib/context";
import { Version } from "@/entities/version/lib/type";
import ErrorPage from "@/shared/pages/error.tsx";

type Props = {
  children: React.ReactNode;
};

const VersionContextProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, error } = useSWR(
    "/api/.well-known/version",
    (url) =>
      axios
        .get<{ value: Version }>(url, { authorization: false })
        .then((res) => res.data.value),
    { refreshInterval: 60 * 1000, revalidateOnFocus: false },
  );

  if (isLoading) return <Spin />;
  if (error) return <ErrorPage errorMessage={error.message} />;
  if (!data) return <ErrorPage errorMessage={"Данные отсутствуют"} />;

  return (
    <VersionContext.Provider value={data}>{children}</VersionContext.Provider>
  );
};

export default VersionContextProvider;
