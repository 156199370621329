import React from "react";
import { Automation, Course } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { RestCallbacks } from "@/shared/rest/lib/types";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { statusValueEnum } from "@/entities/automation/lib/status-value-enum";
import { triggerValueEnum } from "@/entities/automation/lib/trigger-value-enum";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AutomationDeleteButton from "@/entities/automation/ui/delete-button";
import AutomationStatusToogleButton from "@/entities/automation/ui/status-toogle-button";
type Data = Automation;

type Params = Partial<Automation>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<Automation>;
};

const AutomationTable: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const toolBarRender: TableProps["toolBarRender"] = (
    action,
    { selectedRowKeys },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
    if (!action) throw new Error("action is undefined");

    const toUpdateRecordKey =
      selectedRowKeys.length === 1 ? Number(selectedRowKeys?.[0]) : undefined;

    return [
      <AutomationDeleteButton
        key={"delete"}
        recordKeys={selectedRowKeys.map(Number)}
        onAfterDelete={() => {
          action.reload();
          action.clearSelected?.();
        }}
      />,
      <AutomationStatusToogleButton
        key={"disabled"}
        status="disabled"
        recordKeys={selectedRowKeys.map(Number)}
        onAfterFinish={() => action.reload()}
      />,
      <AutomationStatusToogleButton
        key={"enabled"}
        status="enabled"
        recordKeys={selectedRowKeys.map(Number)}
        onAfterFinish={() => action.reload()}
      />,
      <Button
        key={"update"}
        type="primary"
        onClick={() => navigate(`/manage/automations/${toUpdateRecordKey}`)}
        disabled={!toUpdateRecordKey}
      >
        Редактировать
      </Button>,
      <Button
        key={"create"}
        type="primary"
        onClick={() => navigate("/manage/automations/create")}
      >
        Создать
      </Button>,
    ];
  };
  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/automations/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const data = await axios
      .request<OrionRestIndexResponse<Automation>>(actionConfig)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {},
    options: false,
    pagination: { showSizeChanger: true },
    hasQueryParams: true,
    request,
    toolBarRender,
    search: false,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        filters: true,
        filterMode: "search",
        render: (dom, { id: automation_id }) => {
          return <Link to={`/manage/automations/${automation_id}`}>{dom}</Link>;
        },
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueEnum: statusValueEnum,
        filters: true,
      },
      {
        title: "Условие назначения обучения",
        dataIndex: "trigger",
        valueEnum: triggerValueEnum,
        filters: true,
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        valueType: "dateTime",
        sorter: true,
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
      },
    ],
  };

  return <ProTable<Data, Params> {...defaultProps} />;
};

export default AutomationTable;
