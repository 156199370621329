import React from "react";
import { ButtonProps } from "antd/es/button";
import { Pool } from "@/models";
import { Button, message, Modal } from "antd";
import axios from "@/axios";
import { ModalFuncProps } from "antd/es/modal/interface";
import useDeletingErrorsModal from "@/entities/automation/hooks/use-deleting-errors-modal";

type PoolUpdateButtonProps = ButtonProps & {
  poolId?: Pool["id"];
  poolsIds?: Pool["id"][];
  afterUpdate?: () => void;
  confirmModalProps?: ModalFuncProps;
  newValue: Partial<Pool>;
};

const PoolUpdateButton: React.FC<PoolUpdateButtonProps> = ({
  poolId,
  poolsIds = [],
  afterUpdate,
  confirmModalProps,
  newValue,
  ...props
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [showDeletingErrorsModal, deletingErrorsModal] =
    useDeletingErrorsModal();

  if (poolId && !poolsIds.includes(poolId)) {
    poolsIds.push(poolId!);
  }

  const resources: { [key: string]: Partial<Pool> } = {};
  poolsIds.forEach((poolId) => {
    resources[poolId] = newValue;
  });

  const poolsWord = poolsIds.length > 1 ? "потоки" : "поток";

  const onClick = () => {
    modal.confirm({
      title: `Вы уверены, что хотите обновить ${poolsWord}?`,
      okText: "Да",
      okType: "danger",
      cancelText: "Отмена",
      onOk: () =>
        axios
          .patch(`/api/pools/batch`, { resources })
          .then(() => afterUpdate?.())
          .catch((err) => {
            message.error(err.response.data.message);
            if (err.response.status === 400) {
              console.log(err.response.data);
              return showDeletingErrorsModal(
                err.response.data.message,
                err.response.data.data,
              );
            }
          }),
      ...confirmModalProps,
    });
  };

  return (
    <>
      <React.Fragment key={"restriction-modal-holder"}>
        {deletingErrorsModal}
      </React.Fragment>
      <Button
        disabled={poolsIds.length === 0}
        type="primary"
        onClick={onClick}
        children="Обновить"
        {...props}
      />
      {contextHolder}
    </>
  );
};

export default PoolUpdateButton;
export type { PoolUpdateButtonProps };
