import React, { useRef } from "react";
import { Course } from "@/models";
import axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { message, Modal, Space, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { Test } from "@/entities/test/lib/model";
import { ActionType } from "@ant-design/pro-table/es/typing";
import Button from "@/shared/ant-design/button/ui/button";
import { testTypeValueEnum } from "@/entities/test/lib/type-value-enum";
import { dateSTime } from "@/shared/dayjs/lib/formats";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const [modal, modalHolder] = Modal.useModal();

  return (
    <>
      {modalHolder}
      <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
        <AutoBreadcrumb />
        <ProTable<Test, Partial<Test>>
          actionRef={actionRef}
          hasQueryParams
          rowKey={"id"}
          search={false}
          options={false}
          columns={[
            {
              title: "Курс",
              dataIndex: "course_id",
              sorter: "course.name",
              render: (dom, { course_id }) => {
                return <Link to={`/manage/courses/${course_id}`}>{dom}</Link>;
              },
              renderText: (_, { course }) => {
                return <>{course!.name}</>;
              },
              ...useDynamicColumnFilters({
                request: async (params) => {
                  const config: AxiosRequestConfig = {
                    method: "POST",
                    url: "/api/courses/search",
                    ...axiosConfigAdapter(),
                  };

                  if (params.search) {
                    config.data.filters.push({
                      field: "name",
                      operator: "ilike",
                      value: `%${params.search}%`,
                    });
                  }

                  return axios
                    .request<OrionRestIndexResponse<Course>>(config)
                    .then((response) => {
                      return response.data.data.map((course) => {
                        return { label: course.name, value: course.id };
                      });
                    });
                },
                filterSearch: true,
                withNullFilter: false,
              }),
            },
            {
              title: "Название теста",
              dataIndex: "name",
              render: (dom, { course_id, tree_node }) => {
                return (
                  <Link
                    to={{
                      pathname: `/manage/courses/${course_id}`,
                      search: `?tab=embeds&selected_tree_node=${tree_node!.id}`,
                    }}
                  >
                    {dom}
                  </Link>
                );
              },
              sorter: true,
              filters: true,
              filterMode: "search",
            },
            {
              title: "Тип",
              dataIndex: "type",
              valueEnum: testTypeValueEnum,
              filters: true,
            },
            {
              title: "Заметка",
              dataIndex: "note",
              valueType: "textarea",
              renderText: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: "Дата создания",
              dataIndex: "created_at",
              valueType: "dateTime",
              fieldProps: { format: dateSTime },
              sorter: true,
              defaultSortOrder: "descend",
            },
            {
              title: "Действие",
              valueType: "option",
              render: (_, { id: test_id }) => {
                return (
                  <Link to={`/manage/tests/${test_id}/reports`}>Отчеты</Link>
                );
              },
            },
          ]}
          request={async (params, sort, filter) => {
            const config = {
              method: "POST",
              url: "/api/tests/search",
              ...axiosConfigAdapter(params, sort, filter),
            };

            config.data.includes.push({ relation: "course" });
            config.data.includes.push({ relation: "tree_node" });

            return axios
              .request<OrionRestIndexResponse<Test>>(config)
              .then((response) => {
                return {
                  data: response.data.data,
                  success: true,
                  total: response.data.meta.total,
                };
              });
          }}
          rowSelection={{}}
          toolBarRender={(action, { selectedRows, selectedRowKeys }) => {
            const onDelete = async () => {
              if (selectedRowKeys!.length === 0) {
                message.error("Не выбрано ни одного теста");
                return;
              }

              modal.confirm({
                title: "Вы уверены, что хотите удалить тесты?",
                content: "Данное действие нельзя отменить",
                okText: "Да",
                cancelText: "Нет",
                onOk: async () => {
                  return axios
                    .delete("/api/tests/batch", {
                      data: { resources: selectedRowKeys },
                    })
                    .then(() => {
                      action?.clearSelected?.();
                      actionRef.current?.reload();
                      message.success("Тесты успешно удалены");
                    })
                    .catch((error) => {
                      message.error("Ошибка при удалении тестов");
                      console.error(error);
                    });
                },
              });
            };

            const onUpdate = async () => {
              if (selectedRows!.length > 1) {
                message.error(
                  "Невозможно изменить, выбрано больше одной записи",
                );
                return;
              }
              if (selectedRows!.length === 0) {
                message.error(
                  "Невозможно изменить, не выбрано не одной записи",
                );
                return;
              }

              const course_id = selectedRows![0].course_id;

              navigate(`/manage/courses/${course_id}?tab=embeds`);
            };

            return [
              <Button
                key={"delete"}
                onClick={onDelete}
                disabled={selectedRowKeys!.length === 0}
                type={"primary"}
                danger
              >
                Удалить
              </Button>,
              <Button
                key={"update"}
                onClick={onUpdate}
                disabled={selectedRows!.length !== 1}
                type={"primary"}
              >
                Изменить
              </Button>,
            ];
          }}
        />
      </Space>
    </>
  );
};

export default Page;
