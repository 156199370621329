import React, { useEffect, useRef, useState } from "react";
import { ServiceAccount } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { ActionType } from "@ant-design/pro-table/es/typing";
import { AxiosRequestConfig } from "axios";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import ServiceAccountDeleteButton from "@/entities/service-account/ui/delete-button";
import { RestCallbacks } from "@/shared/rest/lib/types";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import typeEnumValue from "@/entities/service-account/lib/type-enum-value.ts";
import ResetSecretsButton from "./reset-secrets-button";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ServiceAccountModalForm from "@/entities/service-account/ui/modal-form.tsx";

type Data = ServiceAccount;

type Params = Partial<ServiceAccount>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<ServiceAccount>;
};

const ServiceAccountsTable: React.FC<Props> = ({ rest, ...props }) => {
  const action = useRef<ActionType>();

  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/service-accounts/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const { data } =
      await axios.request<OrionRestIndexResponse<ServiceAccount>>(actionConfig);
    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const toolBarRender: TableProps["toolBarRender"] = (
    _,
    { selectedRowKeys },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");

    const toRestoreRecordKey =
      selectedRowKeys?.length === 1 ? Number(selectedRowKeys?.[0]) : undefined;
    return [
      <ServiceAccountDeleteButton
        key={"delete"}
        rest={{
          recordKey: selectedRowKeys.map(Number),
          onAfterDelete: () => {
            action.current?.reload();
            action.current?.clearSelected?.();
            rest?.onAfterDelete?.();
          },
        }}
      />,
      <ResetSecretsButton
        key={"reset-password"}
        recordKey={toRestoreRecordKey}
      />,
      <>
        <ServiceAccountModalForm
          key={`update-${toRestoreRecordKey}`}
          rest={{
            type: "update",
            recordKey: toRestoreRecordKey,
            onAfterUpdate: (record) => {
              action.current?.reload();
              rest?.onAfterUpdate?.(record);
            },
          }}
        />
      </>,
      <ServiceAccountModalForm
        key={"create"}
        rest={{
          type: "create",
          onAfterCreate: (record) => {
            action.current?.reload();
            rest?.onAfterCreate?.(record);
          },
        }}
      />,
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {},
    options: false,
    pagination: { showSizeChanger: true },
    toolBarRender,
    request,
    actionRef: action,
    search: false,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        filters: true,
        filterMode: "search",
      },
      {
        title: "Тип",
        dataIndex: "type",
        valueEnum: typeEnumValue,
        filters: true,
      },
      {
        title: "Почта для системных писем",
        dataIndex: "email",
      },
      {
        title: "Дата время последнего использования",
        dataIndex: "last_used_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: "Время создания",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
      },
    ],
  };

  return (
    <ProTable<Data, Params>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};

export default ServiceAccountsTable;
