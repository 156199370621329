import { ReactNode } from "react";
import { Automation } from "@/models.ts";

export const triggerValueEnum: Record<Automation["trigger"], ReactNode> = {
  on_member_saved_with_org_structure_assignments:
    "Сотрудник с подразделением/должностью",
  on_member_saved_without_org_structure_assignments:
    "Сотрудник без подразделения/должности",
  on_need_re_educate: "Назначение повторного обучения",
};
