import React from "react";
import { File, Lesson as BaseLesson } from "@/models";
import Preview from "@/entities/file/ui/preview";
import {
  Flex,
  Grid,
  message,
  Space,
  theme,
  Typography,
  UploadFile,
} from "antd";
import fileDownload from "js-file-download";
import { ProFormUploadButton } from "@ant-design/pro-components";
import styled from "styled-components";
import axios from "@/axios";

const { useBreakpoint } = Grid;

const PlayerWrapper = styled.div`
  overflow: hidden;

  .video-react-controls-enabled {
    padding: 0 !important;
    height: 100% !important;
    overflow: hidden;
  }

  .video-react-video {
    position: static !important;
  }
`;

type LessonDescriptionsProps = {
  dataSource: Lesson;
};

interface Lesson extends BaseLesson {
  attachment_files: (File & UploadFile)[];
}

const LessonDescriptions: React.FC<LessonDescriptionsProps> = ({
  dataSource,
}) => {
  const screens = useBreakpoint();
  const { token } = theme.useToken();
  dataSource?.attachment_files?.forEach((file) => (file.status = "done"));

  const PreviewWrapper: React.FC<{
    type: string;
    children: React.ReactNode;
  }> = ({ type, children }) => {
    switch (type.split("/")[1]) {
      case "pdf":
        return (
          <div
            style={{
              flex: "auto",
              overflow: "auto",
              paddingRight: token.padding,
              minHeight: `${
                dataSource?.attachment_files?.length ? "720px" : "auto"
              }`,
            }}
          >
            {children}
          </div>
        );
    }

    switch (type.split("/")[0]) {
      case "video":
        return (
          <PlayerWrapper
            style={{
              minHeight: `${
                dataSource?.attachment_files?.length ? "500px" : "auto"
              }`,
            }}
          >
            {children}
          </PlayerWrapper>
        );
      default:
        return (
          <Typography.Text>
            Невозможно отобразить данный тип файла
          </Typography.Text>
        );
    }
  };

  const onDownload = async (file: UploadFile) => {
    axios
      .get(file.url!, {
        authorization: false,
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, file.name);
      })
      .catch(() => {
        message.error("Ошибка загрузки файла");
      });
  };

  return (
    <Flex
      vertical
      gap={8}
      style={{
        height: `calc(100vh - 64px - 16px * 2 - 22px - 8px - 16px * 2 - 32px - 8px ${
          !screens.lg ? "- 32px - 8px" : ""
        })`,
        width: "100%",
        overflow: "auto",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {dataSource.name}
      </Typography.Title>
      <Typography.Text>{dataSource.description}</Typography.Text>
      {dataSource.content_file ? (
        <PreviewWrapper type={dataSource.content_file.type}>
          <Preview
            key={dataSource.content_file.id}
            file={dataSource.content_file as any}
          />
        </PreviewWrapper>
      ) : null}
      {dataSource.attachment_files &&
        dataSource.attachment_files.length > 0 && (
          <Space direction={"vertical"}>
            <Typography.Text style={{ margin: 0 }}>
              Прикреплённые файлы
            </Typography.Text>
            <ProFormUploadButton
              value={dataSource?.attachment_files}
              max={0}
              buttonProps={{ disabled: true, hidden: true }}
              fieldProps={{
                listType: "picture-card",
                onDownload: onDownload,
                showUploadList: {
                  showDownloadIcon: true,
                  showRemoveIcon: false,
                  showPreviewIcon: false,
                },
              }}
            />
          </Space>
        )}
    </Flex>
  );
};

export default LessonDescriptions;
