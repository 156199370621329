import React, { useEffect, useState } from "react";
import {
  ProForm,
  ProFormCheckbox,
  ProFormList,
  ProFormListProps,
  ProFormProps,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import {
  ActionBasedOnOrgStructureEntries,
  Automation,
  AutomationOnMemberSavedWithoutOrgStructureAssignments,
  CoursePool,
  LearningMatrixEntry,
} from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import dayjs from "dayjs";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import {
  Button,
  Col,
  Divider,
  Flex,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  message,
  theme,
} from "antd";
import { triggerValueEnum } from "@/entities/automation/lib/trigger-value-enum";
import { date } from "@/shared/dayjs/lib/formats";
import { statusValueEnum } from "@/entities/automation/lib/status-value-enum";
import MemberDepartmentInput from "@/entities/member/ui/department-input";
import MemberPositionInput from "@/entities/member/ui/position-input";
import CourseSelect from "@/entities/course/ui/select";
import PoolSelect from "@/entities/pool/ui/select";
import { DeleteOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { FieldData } from "rc-field-form/es/interface";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import PoolTemplateSelect from "@/entities/pool-template/ui/select";
import AutomationDeleteButton from "@/entities/automation/ui/delete-button";
import AutomationStatusToogleButton from "@/entities/automation/ui/status-toogle-button";
import { ProFormStyled } from "@/entities/automation/ui/pro-form-styled";
import DepartmentPositionSelectDependentMatrix from "./departmant-position-select-dependent-matrix";
import { v4 as uuidv4 } from "uuid";

type Record = Automation & {
  action: Automation["action"] & {
    type: boolean & Automation["action"]["type"];
  };
  learning_matrix_entries: ((Omit<
    ActionBasedOnOrgStructureEntries["org_structure_entries"][0],
    "course_pools"
  > & {
    course_pools: (CoursePool & { pool_template_id: number })[];
  }) & {
    disabled?: boolean;
  })[];
  course_pools: (CoursePool & { pool_template_id: number })[];
};

type AutomationFormProps = ProFormProps<Record> & {
  rest:
    | { type: "create"; onAfterCreate?: () => void }
    | {
        type: "update";
        recordKey: Automation["id"];
        onAfterUpdate?: () => void;
      };
};

const AutomationForm: React.FC<AutomationFormProps> = ({ rest, ...props }) => {
  const { token } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, modalHolder] = Modal.useModal();
  const navigate = useNavigate();
  const [error, setError] = React.useState<Error | null>(null);
  const [automationStatus, setAutomationStatus] = useState<
    Automation["status"] | null
  >(null);

  const [form] = ProForm.useForm<Record>();
  const learning_matrix_entries_scope = ProForm.useWatch(
    ["action", "learning_matrix_entries_scope"],
    form,
  );
  const actionType = ProForm.useWatch(["action", "type"], form);
  const trigger = ProForm.useWatch(["trigger"], form);

  /** Dynamic pool-select filter */
  const learning_matrix_entries = ProForm.useWatch(
    ["learning_matrix_entries"],
    form,
  );
  const coursePools = ProForm.useWatch(["course_pools"], form);

  useEffect(() => {
    if (rest.type === "create") {
      setIsLoading(true);
      axios
        .get(`/api/automations`)
        .then((res) => res.data.meta.total)
        .then((totalRecords) => {
          form.setFieldValue(
            "name",
            `Условие автоназначения № ${totalRecords + 1} от ${dayjs().format(
              date,
            )}`,
          );
        })
        .catch((err) => {
          message.error("Не удалось получить данные записей автоназначения");
          setError(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (actionType === false) {
      form.setFieldValue(
        ["action", "learning_matrix_entries_scope"],
        undefined,
      );
    } else if (actionType === true) {
      if (rest.type === "create") {
        form.setFieldValue(["learning_matrix_entries"], undefined);
      }
    }
  }, [actionType]);

  const mergeLearningMatrixEntries = (entries: any) => {
    return entries.reduce((acc: any, record: any) => {
      const existingIndex = acc.findIndex(
        (item: any) =>
          item.department_id === record.department_id &&
          item.position_id === record.position_id,
      );

      if (existingIndex === -1) {
        acc.push(record);
      } else {
        const existing = acc[existingIndex];

        record.course_pools.push(...existing.course_pools);

        acc[existingIndex] = {
          ...existing,
          ...record,
        };
      }

      return acc;
    }, []);
  };

  const getAllLearningMatrixEntries = async (type: typeof rest.type) => {
    setIsLoading(true);

    let page = 1;
    let onPage = 50;
    const limit = 50;

    const learningMatrixEntries = [] as LearningMatrixEntry[];

    while (onPage === limit) {
      const res = await axios
        .post<{ data: LearningMatrixEntry[] }>(
          `/api/learning-matrix-entries/search`,
          {},
          {
            params: {
              page,
              limit,
            },
          },
        )
        .then((res) =>
          res.data.data.filter((record) => record.course_type === "course"),
        )
        .finally(() => setIsLoading(false));
      page++;
      onPage = res.length;
      learningMatrixEntries.push(...res);
    }
    const records = learningMatrixEntries.map((record) => {
      return {
        ...record,
        course_pools: [
          {
            course_id: record.course_id,
            pool_type: "pool",
            uuid: uuidv4(),
            learning_matrix_entry_id: record.id,
          },
        ],
      };
    });

    let mergeRecords = mergeLearningMatrixEntries(records);

    let currentLearningMatrixEntries = [] as Record["learning_matrix_entries"];

    if (type === "update") {
      currentLearningMatrixEntries =
        form.getFieldsValue().learning_matrix_entries;

      const mergeArraysWithPriority = (
        arr1: Record["learning_matrix_entries"],
        arr2: Record["learning_matrix_entries"],
      ) => {
        const merged = [] as Record["learning_matrix_entries"];
        const seen = {} as any;

        arr1.forEach((item) => {
          const key = `${item.department_id}-${item.position_id}`;
          if (!seen[key]) {
            merged.push(item);
            seen[key] = true;
          }
        });

        arr2.forEach((item) => {
          const key = `${item.department_id}-${item.position_id}`;
          if (!seen[key]) {
            merged.push(item);
            seen[key] = true;
          }
        });

        return merged;
      };

      if (currentLearningMatrixEntries.length > 0) {
        mergeRecords = mergeArraysWithPriority(
          currentLearningMatrixEntries,
          mergeRecords,
        );
      }
    }

    form.setFieldValue("learning_matrix_entries", mergeRecords);

    return;
  };

  const getLearningMatrixRecords = async (filter: { [key: string]: any }) => {
    const axiosConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/learning-matrix-entries/search",
      ...axiosConfigAdapter(
        {
          pageSize: 20,
        },
        {},
        filter,
      ),
    };

    return await axios
      .request<OrionRestIndexResponse<LearningMatrixEntry>>(axiosConfig)
      .then(({ data }) => data.data)
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const checkDublicateCoursePools = (
    coursePools: AutomationOnMemberSavedWithoutOrgStructureAssignments["action"]["course_pools"],
  ) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;

    coursePools.forEach((item, index) => {
      if (!item.course_id) return;
      if (item.course_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.course_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["course_pools", index, "course_id"],
            errors: ["Курс дублируется, выберите другой курс или удалите поле"],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const checkDublicateCoursePoolsInOrgStructure = (
    coursePools: AutomationOnMemberSavedWithoutOrgStructureAssignments["action"]["course_pools"],
    indexLearningMatrixEntry: number,
  ) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;

    coursePools.forEach((item, index) => {
      if (!item.course_id) return;
      if (item.course_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.course_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: [
              "learning_matrix_entries",
              indexLearningMatrixEntry,
              "course_pools",
              index,
              "course_id",
            ],
            errors: ["Курс дублируется, выберите другой курс или удалите поле"],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const checkDublicateOrgStructureAssignments = (
    orgStructureEntries: ActionBasedOnOrgStructureEntries["org_structure_entries"],
  ) => {
    const indexesOrgStructureEntries = {
      doubleIndex: [],
    } as any;

    orgStructureEntries.forEach((item, index) => {
      if (!item.department_id && !item.position_id) return;
      if (
        `${item.department_id}${item.position_id}` in indexesOrgStructureEntries
      ) {
        indexesOrgStructureEntries.doubleIndex.push(index);
      }
      indexesOrgStructureEntries[`${item.department_id}${item.position_id}`] =
        index;
    });

    if (indexesOrgStructureEntries.doubleIndex.length > 0) {
      form.setFields(
        indexesOrgStructureEntries.doubleIndex.map(
          (index: number): FieldData => ({
            name: ["learning_matrix_entries", index, "department_id"],
            errors: [
              "Сочетание Подразделение/Должность дублируется, выберите другое Подразделение или удалите поле",
            ],
          }),
        ),
      );
      form.setFields(
        indexesOrgStructureEntries.doubleIndex.map(
          (index: number): FieldData => ({
            name: ["learning_matrix_entries", index, "position_id"],
            errors: [
              "Сочетание Подразделение/Должность дублируется, выберите другую Должность или удалите поле",
            ],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const mutateDataBasedOnActionTypeFromRequest = (values: Record) => {
    const data: Automation = { ...values };

    if (values.learning_matrix_entries) {
      values.learning_matrix_entries = values.learning_matrix_entries.map(
        (record) => {
          return {
            ...record,
            course_pools: record.course_pools
              ? record.course_pools.map((coursePools) => {
                  if (coursePools.pool_type === "pool_template") {
                    return {
                      ...coursePools,
                      pool_id: coursePools.pool_template_id,
                    };
                  } else {
                    return coursePools;
                  }
                })
              : [],
          };
        },
      );
    }

    switch (data.action.type) {
      case "based_on_education_matrix":
        if (learning_matrix_entries_scope === "selected") {
          if (
            checkDublicateOrgStructureAssignments(
              values.learning_matrix_entries,
            )
          )
            return false;
        }
        data.action.learning_matrix_entry_pools =
          values.learning_matrix_entries!.flatMap((matrixRecord: any) => {
            return matrixRecord.course_pools;
          });
        break;
      case "based_on_course_pools":
        if (checkDublicateCoursePools(values.course_pools)) return false;
        data.action.course_pools = values.course_pools.map((coursePools) => {
          if (coursePools.pool_type === "pool_template") {
            return {
              ...coursePools,
              pool_id: coursePools.pool_template_id,
            };
          } else {
            return coursePools;
          }
        });
        break;
      case "based_on_org_structure_entries":
        if (
          checkDublicateOrgStructureAssignments(values.learning_matrix_entries)
        )
          return false;
        const checkDublicateCoursePoolsInOrgStructureResult: boolean[] =
          values.learning_matrix_entries.map((item, index) =>
            checkDublicateCoursePoolsInOrgStructure(item.course_pools, index),
          );

        if (checkDublicateCoursePoolsInOrgStructureResult.includes(true))
          return false;
        data.action.org_structure_entries = values.learning_matrix_entries;
        break;
      default:
        throw new Error("Unknown action type");
    }

    return data;
  };

  const mutateDataBasedOnActionTypeFromResponse = async (
    automation: Automation,
  ) => {
    const data: any = {
      name: automation.name,
      trigger: automation.trigger,
      status: automation.status,
      action: automation.action,
    };
    switch (automation.action.type) {
      case "based_on_course_pools":
        data.course_pools = automation.action.course_pools.map(
          (coursePools) => {
            if (coursePools.pool_type === "pool_template") {
              return {
                ...coursePools,
                pool_template_id: coursePools.pool_id,
              };
            } else {
              return coursePools;
            }
          },
        );
        break;
      case "based_on_org_structure_entries":
        data.action.type = false;
        data.learning_matrix_entries =
          automation.action.org_structure_entries.map((record) => ({
            disabled: true,
            ...record,
          }));
        break;
      case "based_on_education_matrix":
        setIsLoading(true);
        data.action.type = true;

        const learningMatrixEntriesIds =
          automation.action.learning_matrix_entry_pools.map(
            (pools) => pools.learning_matrix_entry_id,
          );

        const fetchLearningMatrixRecords = async (ids: number[]) => {
          const learningMatrixEntries: LearningMatrixEntry[] = [];

          const batchSize = 20;
          let startIndex = 0;
          let endIndex = Math.min(startIndex + batchSize, ids.length);

          while (startIndex < ids.length) {
            const batchIds = ids.slice(startIndex, endIndex);

            const filter = {
              id: batchIds,
            };

            const res = await getLearningMatrixRecords(filter);

            learningMatrixEntries.push(...res!);

            startIndex = endIndex;
            endIndex = Math.min(startIndex + batchSize, ids.length);
          }
          return learningMatrixEntries;
        };

        const learningMatrixEntries = await fetchLearningMatrixRecords(
          learningMatrixEntriesIds,
        ).finally(() => setIsLoading(false));

        const automationLearningMatrixEntries =
          automation.action.learning_matrix_entry_pools.flatMap((pools) => {
            const learningMatrixEntry = learningMatrixEntries.find(
              (record) => record.id === pools.learning_matrix_entry_id,
            );
            if (learningMatrixEntry) {
              return [
                {
                  department_id: learningMatrixEntry.department_id,
                  position_id: learningMatrixEntry.position_id,
                  disabled: true,
                  course_pools: [
                    {
                      ...pools,
                      course_id: learningMatrixEntry.course_id,
                    },
                  ],
                },
              ];
            } else {
              return [];
            }
          });

        data.learning_matrix_entries = mergeLearningMatrixEntries(
          automationLearningMatrixEntries,
        );
        break;
      default:
        throw new Error("Unknown action type");
    }
    if (data.learning_matrix_entries) {
      data.learning_matrix_entries = data.learning_matrix_entries.map(
        (record: Record["learning_matrix_entries"][0]) => {
          return {
            ...record,
            course_pools: record.course_pools
              ? record.course_pools.map((coursePools) => {
                  if (coursePools.pool_type === "pool_template") {
                    return {
                      ...coursePools,
                      pool_template_id: coursePools.pool_id,
                    };
                  } else {
                    return coursePools;
                  }
                })
              : [],
          };
        },
      );
    }
    return data;
  };

  const fetchAutomationData = async (id: number) => {
    setIsLoading(true);
    return await axios
      .get<OrionRestShowResponse<Automation>>(`/api/automations/${id}`)
      .then(async (res) => {
        setAutomationStatus(res.data.data.status);
        return res.data.data;
      })
      .catch((err) => {
        console.error(err);
        message.error(
          err.response.data.message ??
            "Ошибка при получении условия автоназначения обучения",
        );
        setError(err);
      })
      .finally(() => setIsLoading(false));
  };

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    grid: true,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  if (rest.type === "create") {
    defaultProps.onFinish = async (values) => {
      const data = mutateDataBasedOnActionTypeFromRequest(values);
      if (!data) return false;

      return await axios
        .post<OrionRestCreateResponse<Automation>>("/api/automations", data)
        .then((res) => {
          message.success(
            "Условие успешно создано, для начала работы нажмите “Запустить”",
          );
          rest.onAfterCreate?.();
          navigate(`/manage/automations/${res.data.data.id}`);
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ??
              "Ошибка при создании условия автоназначения обучения",
          );
          console.error(err);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          if (err.response.status === 400) {
            modal.confirm({
              title: "Создание условия автоназначения",
              width: "60%",
              footer: null,
              icon: null,
              closable: true,
              content: (
                <Space direction="vertical">
                  <Typography.Text>
                    Условие с аналогичными настройками уже существует:
                  </Typography.Text>
                  <Link to={`/manage/automations/${err.response.data.data.id}`}>
                    {err.response.data.data.name}
                  </Link>
                </Space>
              ),
            });
          }

          return false;
        });
    };
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      setIsLoading(true);
      const automation = await fetchAutomationData(rest.recordKey);
      return mutateDataBasedOnActionTypeFromResponse(automation!);
    };

    defaultProps.onFinish = async (values) => {
      const data = mutateDataBasedOnActionTypeFromRequest(values);
      if (!data) return false;
      return modal.confirm({
        title: "Редактировать условие автоназначения",
        width: "60%",
        icon: null,
        closable: true,
        okText: "Сохранить",
        cancelText: "Отменить",
        content: (
          <Space direction="vertical">
            <Typography.Text type="secondary">
              Выбранная запись будет сохранена с новыми значениями параметров
            </Typography.Text>
            <ProFormStyled form={form} submitter={false}>
              <ProFormRadio.Group
                name="on_updated_action"
                initialValue={"change_new_education"}
                options={[
                  {
                    label: (
                      <Space direction="vertical">
                        <Typography.Text>
                          Изменения только для новых обучений
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          Внесенные изменения не будут применены для
                          существующих обучений
                        </Typography.Text>
                      </Space>
                    ),
                    value: "change_new_education",
                  },
                  {
                    label: (
                      <Space direction="vertical">
                        <Typography.Text>
                          Изменения для старых и новых обучений
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          Внесенные изменения будут применены для существующих и
                          новых назначенных обучений
                        </Typography.Text>
                      </Space>
                    ),
                    value: "change_all_education",
                  },
                ]}
              />
            </ProFormStyled>
          </Space>
        ),
        onOk: async () => {
          return axios
            .put<OrionRestUpdateResponse<Automation>>(
              `/api/automations/${rest.recordKey}`,
              {
                ...data,
                on_updated_action: form.getFieldValue("on_updated_action"),
              },
            )
            .then((res) => {
              message.success("Условие автоназначения успешно обновлено");
              return true;
            })
            .catch((err) => {
              console.error(err);
              const messageText = err.response.data.message ?? err.message;
              message.error(
                `Ошибка при обновлении условия автоназначения: ${messageText}`,
              );

              if (err.response.status === 422) {
                setValidationErrorsToFormFields(form, err.response.data.errors);
              }

              return false;
            });
        },
      });
    };
  }

  if (error) {
    throw error;
  }
  /** Pre Render */
  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  const Submitter: React.FC = () => {
    const resetText = rest.type === "create" ? "создание" : "редактирование";
    return (
      <Flex justify={"end"} gap={token.padding} style={{ width: "100%" }}>
        <Button
          onClick={() => {
            modal.confirm({
              title: `Отменить ${resetText} условия автоназначения?`,
              onOk: () => navigate("/manage/automations"),
              okText: "Подтвердить",
            });
          }}
        >
          Отменить
        </Button>
        {rest.type === "update" && (
          <>
            <AutomationDeleteButton
              recordKeys={[Number(rest.recordKey)]}
              onAfterDelete={() => navigate("/manage/automations")}
            />
            {automationStatus === "enabled" && (
              <AutomationStatusToogleButton
                recordKeys={[Number(rest.recordKey)]}
                status="disabled"
                onAfterFinish={async () => fetchAutomationData(rest.recordKey)}
              />
            )}
            {automationStatus === "disabled" && (
              <AutomationStatusToogleButton
                recordKeys={[Number(rest.recordKey)]}
                status="enabled"
                onAfterFinish={async () => fetchAutomationData(rest.recordKey)}
              />
            )}
          </>
        )}
        <Button
          type={"primary"}
          onClick={() => {
            form.submit();
          }}
        >
          Сохранить
        </Button>
      </Flex>
    );
  };

  const CoursePoolList: React.FC<{
    courseSelectDisabled?: boolean;
    creatorButtonProps: ProFormListProps<Record>["creatorButtonProps"];
  }> = ({ creatorButtonProps, courseSelectDisabled = false }) => {
    return (
      <ProFormList
        name={["course_pools"]}
        rules={[
          {
            validator: (_, value) => {
              if (!value || value.length < 1) {
                return Promise.reject(
                  new Error("Минимальное количество курсов – 1"),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        creatorButtonProps={creatorButtonProps}
        deleteIconProps={
          actionType !== true
            ? {
                tooltipText: "Удалить",
              }
            : false
        }
        copyIconProps={false}
        min={1}
        max={20}
        creatorRecord={{
          pool_type: "pool",
          uuid: uuidv4(),
        }}
        initialValue={
          actionType === true
            ? []
            : [{ course_id: undefined, pool_type: "pool", uuid: uuidv4() }]
        }
      >
        {(_, index, action) => {
          const courseId = action.getCurrentRowData()?.course_id;
          const poolType = action.getCurrentRowData()?.pool_type;

          return (
            <ProForm.Group>
              <CourseSelect
                name={"course_id"}
                colProps={{ span: 8 }}
                disabled={courseSelectDisabled}
                rules={[{ required: true }]}
                debounceTime={500}
                params={{ index }}
                onChange={() => {
                  action.setCurrentRowData({
                    pool_id: undefined,
                    pool_template_id: undefined,
                  });
                }}
              />
              <ProFormSelect
                colProps={{ span: 8 }}
                label={
                  <Typography.Text ellipsis={{ tooltip: true }}>
                    Параметры потока автоназначения
                  </Typography.Text>
                }
                valueEnum={{
                  pool: "Добавлять в существующий",
                  pool_template: "Создавать поток по шаблону",
                }}
                initialValue={"pool"}
                rules={[{ required: true }]}
                name={["pool_type"]}
                onChange={() => {
                  action.setCurrentRowData({
                    pool_id: undefined,
                    pool_template_id: undefined,
                  });
                }}
              />
              {poolType === "pool" && (
                <PoolSelect
                  params={{ course_id: courseId, index }}
                  colProps={{ span: 8 }}
                  label="Поток"
                  disabled={!courseId}
                  placeholder={"Выберите поток"}
                  rules={[{ required: true }]}
                  name={"pool_id"}
                  showSearch
                  debounceTime={500}
                />
              )}
              {poolType === "pool_template" && (
                <PoolTemplateSelect
                  params={{ course_id: courseId, index }}
                  colProps={{ span: 8 }}
                  label="Шаблон потока"
                  disabled={!courseId}
                  placeholder={"Выберите шаблон"}
                  rules={[{ required: true }]}
                  name={"pool_template_id"}
                  debounceTime={500}
                  showSearch
                />
              )}
            </ProForm.Group>
          );
        }}
      </ProFormList>
    );
  };

  return (
    <Spin spinning={isLoading}>
      {modalHolder}
      <Submitter />
      <ProForm<Record> {...props}>
        <ProFormText
          name="name"
          label="Название"
          rules={[{ required: true, max: 255 }]}
        />
        <ProFormSelect
          label="Условие назначения обучения"
          colProps={{ span: 12 }}
          valueEnum={triggerValueEnum}
          rules={[{ required: true }]}
          name={"trigger"}
          onChange={(value) => {
            switch (value) {
              case "on_member_saved_without_org_structure_assignments":
                form.setFieldValue(["action", "type"], "based_on_course_pools");
                form.setFieldValue(
                  ["action", "learning_matrix_entries_scope"],
                  undefined,
                );
                break;
              case "on_need_re_educate":
                form.setFieldValue(["action", "type"], true);
                break;
              case "on_member_saved_with_org_structure_assignments":
                if (actionType === "based_on_course_pools") {
                  form.setFieldValue(["action", "type"], false);
                }
                break;
              case undefined:
                break;
              default:
                throw new Error("Unknown trigger");
            }
          }}
          disabled={rest.type === "update"}
        />
        <ProFormSelect
          colProps={{ span: 12 }}
          valueEnum={statusValueEnum}
          hidden
          initialValue={"disabled"}
          rules={[{ required: true }]}
          name={"status"}
        />
        <ProFormCheckbox
          disabled={trigger === "on_need_re_educate" || rest.type === "update"}
          hidden={
            trigger === "on_member_saved_without_org_structure_assignments" ||
            !trigger
          }
          initialValue={false}
          transform={(value) => {
            if (value === true) {
              return "based_on_education_matrix";
            } else if (value === false) {
              return "based_on_org_structure_entries";
            } else {
              return "based_on_course_pools";
            }
          }}
          name={["action", "type"]}
        >
          На основе матрицы
        </ProFormCheckbox>
        <ProFormSelect
          label="Охват автоматизации"
          colProps={{ span: 12 }}
          valueEnum={{
            all: "Создать для всех",
            selected: "Создать для указанных подразделений/должностей",
          }}
          onChange={(value) => {
            if (value === "all") {
              getAllLearningMatrixEntries(rest.type);
            }
          }}
          hidden={actionType !== true}
          rules={[{ required: actionType === true }]}
          name={["action", "learning_matrix_entries_scope"]}
        />
        {trigger === "on_member_saved_without_org_structure_assignments" && (
          <CoursePoolList
            creatorButtonProps={{
              creatorButtonText: "Добавить обучение",
              type: "primary",
              block: false,
              icon: false,
            }}
          />
        )}
        {(trigger === "on_member_saved_with_org_structure_assignments" ||
          trigger === "on_need_re_educate") && (
          <ProFormList
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.length < 1) {
                    return Promise.reject(
                      new Error(
                        "Минимальное количество подразделений/должностей – 1",
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            alwaysShowItemLabel
            creatorButtonProps={
              actionType === true &&
              (!learning_matrix_entries_scope ||
                learning_matrix_entries_scope === "all")
                ? false
                : {
                    creatorButtonText: "Добавить подразделение/должность",
                    type: "primary",
                    block: false,
                    icon: false,
                  }
            }
            copyIconProps={false}
            deleteIconProps={false}
            name="learning_matrix_entries"
            min={1}
            creatorRecord={
              actionType === false ? { uuid: uuidv4() } : undefined
            }
          >
            {(_, indexMatrix, action) => {
              const currentRowData = learning_matrix_entries?.[indexMatrix];

              return (
                <ProForm.Group>
                  <Flex style={{ width: "100%" }} align="center">
                    <Row style={{ width: "100%" }} gutter={token.paddingXXS}>
                      {actionType === true &&
                      learning_matrix_entries_scope === "selected" ? (
                        <DepartmentPositionSelectDependentMatrix
                          form={form}
                          index={indexMatrix}
                          disabled={currentRowData?.disabled}
                        />
                      ) : (
                        <>
                          <Col span={12}>
                            <MemberDepartmentInput
                              whereHaveInLearningMatrix={actionType === true}
                              label="Подразделение"
                              disabled={
                                learning_matrix_entries_scope === "all" ||
                                currentRowData?.disabled
                              }
                              placeholder={
                                learning_matrix_entries_scope === "all"
                                  ? "Без значения"
                                  : "Выберите значение"
                              }
                              rules={[
                                { required: !currentRowData?.position_id },
                              ]}
                              name={"department_id"}
                            />
                          </Col>
                          <Col span={12}>
                            <MemberPositionInput
                              whereHaveInLearningMatrix={actionType === true}
                              label="Должность"
                              disabled={
                                learning_matrix_entries_scope === "all" ||
                                currentRowData?.disabled
                              }
                              rules={[
                                { required: !currentRowData?.department_id },
                              ]}
                              name={"position_id"}
                              placeholder={
                                learning_matrix_entries_scope === "all"
                                  ? "Без значения"
                                  : "Выберите значение"
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Row>

                    {learning_matrix_entries_scope !== "all" &&
                      learning_matrix_entries?.length > 1 && (
                        <Button
                          icon={<DeleteOutlined />}
                          type={"text"}
                          danger
                          onClick={() => {
                            action.remove(indexMatrix);
                          }}
                        />
                      )}
                  </Flex>
                  {(currentRowData?.position_id ||
                    currentRowData?.department_id) &&
                    currentRowData?.course_pools?.length === 0 && (
                      <Typography.Text type="danger">
                        Для указанных подразделение/должность не найдено
                        обучение в матрице обучения.
                      </Typography.Text>
                    )}
                  <CoursePoolList
                    creatorButtonProps={
                      actionType === false
                        ? {
                            creatorButtonText: "Добавить обучение",
                            type: "primary",
                            block: false,
                            icon: false,
                          }
                        : false
                    }
                    courseSelectDisabled={actionType === true}
                  />
                  <Divider style={{ margin: "8px 0" }} />
                </ProForm.Group>
              );
            }}
          </ProFormList>
        )}
      </ProForm>
    </Spin>
  );
};
export default AutomationForm;
export type { AutomationFormProps };
