import React from "react";
import useSWR from "swr";
import { Member } from "@/models";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import AccessDeniedResult from "@/entities/me/ui/access-denied-result";
import MeContext from "@/entities/me/lib/context";

type Props = {
  children: React.ReactNode;
};

const MeContextProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, error } = useSWR(
    "/api/me",
    (url) => axios.get<Member>(url).then((res) => res.data),
    { refreshInterval: 60 * 1000 },
  );

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("No data");
  if (data.status !== "active") return <AccessDeniedResult />;

  return <MeContext.Provider value={data}>{children}</MeContext.Provider>;
};

export default MeContextProvider;
