import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import FeaturesContext from "@/entities/features/lib/context.tsx";
import { FeaturesData } from "@/entities/features/lib/model.ts";

type Props = {
  children: React.ReactNode;
};

const FeaturesContextProvider: React.FC<Props> = ({ children }) => {
  const { data, ...request } = useSWR<FeaturesData, any, string>(
    "/api/features",
    async (url) => {
      return axios.get(url, { authorization: false }).then((res) => res.data);
    },
    { refreshInterval: 60 * 1000 },
  );

  if (request.isLoading) return <Spin />;
  if (request.error) throw request.error;
  if (!data) throw new Error("Feature flags not loaded");

  return (
    <FeaturesContext.Provider value={data}>{children}</FeaturesContext.Provider>
  );
};

export default FeaturesContextProvider;
