import React from "react";
import { Space, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { useParams } from "react-router-dom";
import MemberForm from "@/entities/member/ui/form";

const Page: React.FC = () => {
  const { member_id } = useParams();
  const { token } = theme.useToken();

  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        direction={"vertical"}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "calc(100vh - 64px - 32px - 22px - 8px)",
          backgroundColor: token.colorBgContainer,
        }}
      >
        <MemberForm rest={{ type: "update", recordKey: member_id }} />
      </Space>
    </Space>
  );
};

export default Page;
