import React from "react";
import {
  BankOutlined,
  BookOutlined,
  BulbOutlined,
  SettingOutlined,
  ShareAltOutlined,
  NodeIndexOutlined,
  LineChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useMe from "@/entities/me/lib/use";
import TestIcon from "@/entities/test/ui/icon";
import NavigationMenu, {
  NavigationMenuProps,
} from "@/shared/navigation-menu/ui";
import { Tooltip } from "antd";

const addKeys = (
  items: NavigationMenuProps["items"],
): NavigationMenuProps["items"] => {
  return items.map((item) => {
    if (!item.children) {
      item.key = item.path;
    }

    if (item.children) {
      item.key = item.children.reduce((prev, curr) => prev + curr.path, "");
      item.children = addKeys(item.children);
    }

    return item;
  });
};

const Menu: React.FC = () => {
  const member = useMe();

  const items: NavigationMenuProps["items"] = [
    {
      path: "/manage/courses",
      label: "Курсы",
      icon: <BookOutlined />,
    },
    {
      path: "/manage/tests",
      label: "Тесты",
      icon: <TestIcon />,
    },
    {
      label: "Пользователи",
      icon: <UserOutlined />,
      children: [
        {
          label: (
            <Tooltip title="Список пользователей" placement="right">
              Список пользователей
            </Tooltip>
          ),
          path: "/manage/members",
        },
        {
          label: "Группы",
          path: "/manage/groups",
        },
      ],
    },
    {
      path: "/manage/space",
      label: "Компания",
      icon: <BankOutlined />,
    },
    {
      path: "/manage/learning-matrix",
      label: "Матрица обучения",
      icon: <ShareAltOutlined />,
    },
    {
      path: "/manage/automations",
      label: "Автоназначение",
      icon: <NodeIndexOutlined />,
    },
    {
      path: "/manage/service-accounts",
      label: "Интеграции",
      icon: <SettingOutlined />,
    },
    {
      path: "/manage/dashboards",
      label: "Дашборд",
      icon: <LineChartOutlined />,
    },
    {
      path: "/manage/license",
      label: "Лицензия",
      icon: <BulbOutlined />,
    },
  ]
    .flatMap((item) => {
      return addKeys([item as any]);
    })
    // RBAC
    .filter((item) => {
      if (member.role === "administrator") return true;
      if (!item.path?.startsWith("/manage")) return true;

      return false;
    });

  return (
    <NavigationMenu
      style={{ height: "100%", width: "100%" }}
      items={items}
      mode={"inline"}
    />
  );
};

export default Menu;
