import React, { useEffect, useState } from "react";
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormProps,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { message, Spin } from "antd";
import { Course, Pool, PoolTemplate } from "@/models";
import CourseSelect from "@/entities/course/ui/select-with-search";
import dayjs from "dayjs";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { RestProps } from "@/shared/rest/lib/types";
import useSWR from "swr";
import { useSearchParams } from "react-router-dom";
import {
  dateSTime,
  dateSTimeNormalize,
  toISOString,
} from "@/shared/dayjs/lib/formats";
import GenerateDocumentsForm from "@/entities/pool/ui/generate-documents-form-group";
import useFeatures from "@/entities/features/lib/use.ts";

type PoolFormProps = ProFormProps<Pool> & {
  rest: RestProps<Pool>;
  courseId: Course["id"];
};

const PoolForm: React.FC<PoolFormProps> = ({ rest, courseId, ...props }) => {
  const [urlParams] = useSearchParams();
  const features = useFeatures();
  const {
    data: course,
    isLoading: isCourseLoading,
    error: courseLoadingError,
  } = useSWR(`/api/courses/${courseId}`, async (url) => {
    return axios
      .get<OrionRestShowResponse<Course>>(url)
      .then((res) => res.data.data);
  });
  const [form] = ProForm.useForm<Pool>(props.form);
  const is_generates_documents = ProForm.useWatch(
    "is_generates_documents",
    form,
  );
  const is_unlimited = ProForm.useWatch("is_unlimited", form);
  const status = ProForm.useWatch("status", form);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (urlParams.get("pool_create") !== null) {
      if (urlParams.get("pool_create_from") !== null) {
        axios
          .get<OrionRestShowResponse<Pool>>(
            `/api/pools/${urlParams.get("pool_create_from")}`,
          )
          .then((res) => res.data.data)
          .then((pool) => {
            pool.command_code = null;
            pool.command_date = null;
            pool.protocol_code = null;
            pool.protocol_date = null;

            pool.starts_at = toISOString(dayjs());
            pool.name = `${pool.training_program_name} от ${dayjs().format(
              dateSTime,
            )}`;

            form.setFieldsValue(pool as any);
          })
          .catch((err) => {
            message.error("Не удалось получить данные для копирования");
            setError(err);
          });
      }
    }
  }, [urlParams]);

  if (isCourseLoading) return <Spin />;
  if (courseLoadingError) throw courseLoadingError;
  if (!course) throw new Error("Course not found");

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: false,
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(
      {
        submitter: { searchConfig: { submitText: "Создать" } },
      },
      defaultProps,
    );
    defaultProps.request = async () => {
      return axios
        .post<OrionRestIndexResponse<PoolTemplate>>(
          `/api/pool-templates/search`,
          {
            filters: [
              {
                field: "course_id",
                operator: "=",
                value: course.id,
              },
              {
                field: "is_default",
                operator: "=",
                value: "true",
              },
            ],
          },
        )
        .then(({ data }) => {
          if (data.data.length > 1) {
            throw new Error(
              "Поддержка нескольких шаблонов потока по умолчанию в данный момент недоступна.",
            );
          } else if (data.data.length === 1) {
            const { value } = data.data[0];
            value.protocol_date = toISOString(dayjs());
            const initialValues = {
              course_id: course.id,
              name: `${course.name} от ${dayjs().format(dateSTime)}`,
              status: "waiting_start",
              starts_at: toISOString(dayjs()),
              is_unlimited: false,
              ends_at: toISOString(dayjs().add(value.pool_duration, "day")),
              commission_members: [
                { full_name: "", position: "" },
                { full_name: "", position: "" },
              ],
            };

            return {
              ...value,
              ...initialValues,
            };
          } else {
            throw new Error("Не удалось получить шаблон потока по умолчанию");
          }
        })
        .catch((err) => {
          setError(err);
        });
    };
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Pool>>("/api/pools", values)
        .then((res) => {
          message.success("Поток успешно создан");
          rest.onAfterCreate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании потока",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      disabled: status === "completed",
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Pool>>(`/api/pools/${rest.recordKey}`)
        .then((res) => res.data.data)
        .then((pool) => {
          if (pool.status === "completed") {
            message.warning("Нельзя изменить поток, который завершен");
          }
          if (pool.status === "started") {
            message.warning(
              "Некоторые настройки нельзя изменить в запущенном потоке",
            );
          }
          return pool;
        });
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<Pool>>(
          `/api/pools/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Поток успешно обновлен");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении потока: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  if (error) throw error;

  /** Render */

  return (
    <ProForm<Pool> {...props}>
      <ProFormDigit label={"ID"} name={"id"} disabled hidden />
      <CourseSelect
        label={"Курс"}
        name={"course_id"}
        disabled
        rules={[{ required: true }]}
        hidden
      />
      <ProFormSelect
        options={[
          { value: "waiting_start", label: "Ожидает запуска" },
          { value: "started", label: "Запущен" },
          { value: "completed", label: "Завершен" },
        ]}
        label={"Статус"}
        name={"status"}
        disabled
        rules={[{ required: true }]}
        hidden
      />
      <ProFormText
        label="Название"
        name="name"
        rules={[{ required: true, max: 255 }]}
      />
      <ProFormText
        label={"Идентификатор потока"}
        name={"key"}
        hidden={!features.isEnabled("pools_keys")}
      />
      <ProFormDateTimePicker
        label={"Дата и время начала"}
        name={"starts_at"}
        rules={[{ required: true }]}
        disabled={rest.type === "update" && status !== "waiting_start"}
        fieldProps={{ format: dateSTime }}
        normalize={dateSTimeNormalize}
      />
      <ProFormSwitch
        label={"Бессрочный"}
        name={"is_unlimited"}
        rules={[{ required: true }]}
        fieldProps={{
          onChange: (checked) => {
            if (checked) {
              form.setFieldValue("ends_at", null);
            }
          },
        }}
      />
      <ProFormDateTimePicker
        label={"Дата и время завершения"}
        name={"ends_at"}
        rules={[{ required: !is_unlimited }]}
        hidden={is_unlimited}
        disabled={rest.type === "update" && status === "completed"}
        fieldProps={{ format: dateSTime }}
        normalize={dateSTimeNormalize}
      />
      <ProFormSelect
        options={[
          { value: "arbitrary", label: "Произвольный" },
          { value: "consistent", label: "Последовательный" },
        ]}
        label={"Порядок просмотра материалов"}
        name={"content_view_order"}
        rules={[{ required: true }]}
        disabled={rest.type === "update" && status !== "waiting_start"}
      />
      <ProFormSelect
        options={[
          { value: "all_materials", label: "Пройти все материалы" },
          { value: "final_test", label: "Пройти итоговое тестирование" },
        ]}
        label={"Условие завершения обучения"}
        name={"completion_condition"}
        rules={[{ required: true }]}
        disabled={rest.type === "update" && status !== "waiting_start"}
      />
      <ProFormSwitch
        label={"Формирование документов"}
        name={"is_generates_documents"}
        rules={[{ required: true }]}
      />
      <GenerateDocumentsForm
        collapsible
        collapsed={!is_generates_documents}
        isRequiredLabels={is_generates_documents}
      />
    </ProForm>
  );
};
export default PoolForm;
export type { PoolFormProps };
