import axios from "@/axios.ts";
import { Dashboard, Member } from "@/models.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { Card, Col, Flex, Row, Spin, Typography } from "antd";
import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import React from "react";
import { ProForm } from "@ant-design/pro-components";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";

export const metadata: IndicatorContentMetadata = {
  name: "system_users",
  title: "Пользователи системы",
  position: 1,
};

const IndicatorContentSystemUsersStatistics: React.FC = () => {
  const form = ProForm.useFormInstance<Dashboard>();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );

  const { data, isLoading, error } = useSWR(
    "/api/members/search",
    async (url) => {
      const axiosConfig: AxiosRequestConfig = {
        method: "POST",
        url,
        ...axiosConfigAdapter(),
      };

      const statuses = ["active", "deactivated"];

      const data = {} as Record<string, number>;

      for (const status of statuses) {
        axiosConfig.data.filters = [
          {
            field: "status",
            operator: "=",
            value: status,
          },
        ];

        const totalPoolParticipantsByStatus = await axios
          .request<OrionRestIndexResponse<Member>>(axiosConfig)
          .then((res) => res.data.meta.total);

        data[status] = totalPoolParticipantsByStatus;
      }

      const totalValue = Object.entries(data).reduce(
        (acc, [key, value]) => acc + value,
        0,
      );

      data.all = totalValue;
      return data;
    },
  );

  if (error) throw error;

  return (
    <>
      {is_visible && (
        <Col span={24}>
          <Card title={metadata.title}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Flex vertical align="center" gap={0}>
                  <Typography.Text strong>Всего</Typography.Text>
                  <Spin spinning={isLoading}>
                    <Typography.Text>{data?.all}</Typography.Text>
                  </Spin>
                </Flex>
              </Col>
              <Col span={8}>
                <Flex vertical align="center" gap={0}>
                  <Typography.Text strong>Активные</Typography.Text>
                  <Spin spinning={isLoading}>
                    <Typography.Text>{data?.active}</Typography.Text>
                  </Spin>
                </Flex>
              </Col>
              <Col span={8}>
                <Flex vertical align="center" gap={0}>
                  <Typography.Text strong>Деактивированные</Typography.Text>
                  <Spin spinning={isLoading}>
                    <Typography.Text>{data?.deactivated}</Typography.Text>
                  </Spin>
                </Flex>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </>
  );
};
export default IndicatorContentSystemUsersStatistics;
