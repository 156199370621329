import React from "react";
import {
  ProForm,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Group } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { message } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";

type GroupFormProps = ProFormProps<Group> & {
  rest: RestProps<Group>;
};

const GroupForm: React.FC<GroupFormProps> = ({ rest, ...props }) => {
  const [form] = ProForm.useForm<Group>(props.form);

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
    });
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Group>>("/api/groups", values)
        .then((res) => {
          message.success("Группа успешно создана");
          rest.onAfterCreate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании группы",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Group>>(`/api/groups/${rest.recordKey}`)
        .then((res) => res.data.data);
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<Group>>(
          `/api/groups/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Группа успешно обновлён");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении группы: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <ProForm<Group> {...props}>
      <ProFormText name="name" label="Название" rules={[{ required: true }]} />
      <ProFormTextArea name="description" label="Описание" />
    </ProForm>
  );
};
export default GroupForm;
export type { GroupFormProps };
