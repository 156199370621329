import React from "react";
import { Department, Group, GroupParticipant, Position } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { Space, Typography } from "antd";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { AxiosRequestConfig } from "axios";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import MemberEducationPurposeModalForm from "@/features/member/education-purpose/ui/modal-form";
import GroupParticipantForm from "@/entities/group-participant/ui/form-batch";
import GroupParticipantDeleteButton from "@/entities/group-participant/ui/delete-button";

type Data = GroupParticipant;

type Params = Partial<GroupParticipant>;

type TableProps = BaseProTableProps<Data, Params>;

type GroupParticipantTableProps = TableProps & {
  groupId: Group["id"];
};

const GroupParticipantTable: React.FC<GroupParticipantTableProps> = ({
  groupId,
  ...props
}) => {
  const request: TableProps["request"] = async (
    params,
    sort,
    filter: Record<string, (string | number | null)[] | null>,
  ) => {
    const {
      ["member,org_structure_assignments,department_id"]: departmentIds,
      ["member,org_structure_assignments,position_id"]: positionIds,
      ...filters
    } = filter;

    const config = {
      method: "POST",
      url: "/api/group-participants/search",
      ...axiosConfigAdapter(params, sort, filters),
    };
    if (departmentIds) {
      config.data.scopes.push({
        name: "orWhereDoesntHaveInOrgStructureAssignments",
        parameters: [
          departmentIds.includes(null),
          "department_id",
          departmentIds,
        ],
      });
    }

    if (positionIds) {
      config.data.scopes.push({
        name: "orWhereDoesntHaveInOrgStructureAssignments",
        parameters: [positionIds.includes(null), "position_id", positionIds],
      });
    }

    config.data.filters.push({
      field: "group_id",
      operator: "=",
      value: groupId,
    });

    config.data.includes.push({
      relation: "member",
    });
    config.data.includes.push({
      relation: "member.org_structure_assignments.department",
    });
    config.data.includes.push({
      relation: "member.org_structure_assignments.position",
    });

    const data = await axios
      .request<OrionRestIndexResponse<GroupParticipant>>(config)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  /** Create form */

  const defaultProps: TableProps = {
    rowKey: "id",
    request,
    search: false,
    options: false,
    toolBarRender: (action, { selectedRowKeys, selectedRows }) => {
      if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
      if (!selectedRows) throw new Error("selectedRows is undefined");
      if (!action) throw new Error("action is undefined");

      const membersIds = selectedRows.map((el) => Number(el.member_id));

      return [
        <GroupParticipantDeleteButton
          key="delete"
          recordKeys={selectedRowKeys.map(Number)}
          onAfterDelete={() => {
            action.reloadAndRest?.();
            action.clearSelected?.();
          }}
        />,
        <GroupParticipantForm
          key="addMembers"
          modal
          groupId={[groupId]}
          onAfterAdd={() => action.reload()}
        />,
        <MemberEducationPurposeModalForm
          key="education-purpose"
          membersIds={membersIds}
        />,
      ];
    },
    pagination: { showSizeChanger: true },
    rowSelection: {
      preserveSelectedRowKeys: true,
    },
    columns: [
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        filters: true,
        filterMode: "search",
        sorter: true,
      },
      {
        title: "Подразделение",
        dataIndex: ["member", "org_structure_assignments", "department_id"],
        render: (_, { member: { org_structure_assignments } }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-department`}>
                  {el.department?.name ? el.department?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/departments/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Department>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Должность",
        dataIndex: ["member", "org_structure_assignments", "position_id"],
        render: (_, { member: { org_structure_assignments } }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-position`}>
                  {el.position?.name ? el.position?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/positions/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Дата добавления",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
      },
    ],
  };

  return (
    <ProTable<GroupParticipant>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};

export default GroupParticipantTable;
export type { GroupParticipantTableProps };
