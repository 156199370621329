import React from "react";
import WithAuth from "@/shared/components/with-auth";

import useMe from "@/entities/me/lib/use";
import { Outlet } from "react-router-dom";

const Context: React.FC = () => {
  const member = useMe();

  return (
    <WithAuth type={"confident"} accessed={member.role === "administrator"}>
      <Outlet />
    </WithAuth>
  );
};

export default Context;
