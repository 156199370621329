import React from "react";
import { Link, useParams } from "react-router-dom";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Pool, TestParticipant } from "@/models";
import { message, Space } from "antd";
import { AxiosRequestConfig } from "axios";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters.ts";
import { ModalForm, ProFormDigit } from "@ant-design/pro-components";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import dayjs from "dayjs";

type Record = TestParticipant;

const Page: React.FC = () => {
  const { test_id } = useParams();

  if (test_id === undefined) throw new Error("test_id is undefined");
  const batchUpdate = async (
    records: TestParticipant[],
    values: any,
    type: "append" | "rewrite",
  ) => {
    let resources: { [key: string]: TestParticipant } = {};

    records.forEach((item) => {
      if (type === "append") {
        item.test_snapshot.testing_attempts_limit =
          item.test_snapshot.testing_attempts_limit +
          values.testing_attempts_limit;
      } else {
        item.test_snapshot.testing_attempts_limit =
          values.testing_attempts_limit;
      }
      resources[item.id] = item;
    });

    return axios
      .patch(`/api/test-participants/batch`, { resources })
      .then(() => {
        message.success("У участника обновлено количество попыток");
        return true;
      })
      .catch((error) => {
        message.error("В время добавления произошла ошибка");
        console.error(error);
        return false;
      });
  };

  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <ProTable<Record>
        request={async (params, sort, filter) => {
          const config: AxiosRequestConfig = {
            method: "POST",
            url: "/api/test-participants/search",
            ...axiosConfigAdapter(params, sort, filter),
          };

          config.data.includes = [
            { relation: "latest_exam.pool_participant" },
            { relation: "latest_exam.pool_participant.pool" },
            { relation: "latest_exam.pool_participant.member" },
            { relation: "latest_exam" },
            { relation: "test" },
          ];

          config.data.aggregates = [
            {
              relation: "exams",
              type: "count",
              filters: [
                {
                  field: "status",
                  operator: "in",
                  value: ["completed", "failed"],
                },
              ],
            },
          ];

          config.data.filters.push({
            field: "latest_exam.status",
            operator: "not in",
            value: ["to_check", "checking"],
          });

          if (test_id) {
            config.data.filters.push({
              field: "test_id",
              operator: "=",
              value: test_id,
            });
          }

          const data = await axios
            .request<OrionRestIndexResponse<Record>>(config)
            .then((res) => res.data);

          return {
            data: data.data,
            success: true,
            total: data.meta.total,
          };
        }}
        rowKey={"id"}
        rowSelection={{}}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
          },
          {
            title: "ФИО",
            dataIndex: [
              "latest_exam",
              "pool_participant",
              "member",
              "full_name",
            ],
            filters: true,
            filterMode: "search",
            render: (dom, record) => {
              const member_id =
                record.latest_exam!.pool_participant!.member!.id;

              return <Link to={`/manage/members/${member_id}`}>{dom}</Link>;
            },
          },
          {
            title: "Поток",
            dataIndex: ["latest_exam", "pool_participant", "pool", "id"],
            renderText: (_, record) => {
              return <>{record.latest_exam!.pool_participant!.pool!.name}</>;
            },
            render: (dom, record) => {
              const course_id =
                record.latest_exam!.pool_participant!.pool!.course_id;
              const pool_id = record.latest_exam!.pool_participant!.pool!.id;

              return (
                <Link to={`/manage/courses/${course_id}/pools/${pool_id}`}>
                  {dom}
                </Link>
              );
            },
            valueType: "select",
            ...useDynamicColumnFilters<Record, string>({
              request: async (params) => {
                const config: AxiosRequestConfig = {
                  method: "POST",
                  url: `/api/pools/search`,
                  data: {
                    filters: [],
                    includes: [],
                  },
                };

                if (params.search) {
                  config.data.filters.push({
                    field: "name",
                    operator: "ilike",
                    value: `%${params.search}%`,
                  });
                }

                return axios
                  .request<OrionRestIndexResponse<Pool>>(config)
                  .then((res) => res.data.data)
                  .then((data) => {
                    return data.map((pool) => ({
                      label: pool.name,
                      value: pool.id,
                    }));
                  });
              },
              filterSearch: true,
            }),
          },
          {
            title: "Дата последней попытки",
            dataIndex: ["latest_exam", "created_at"],
            sorter: true,
            render: (_, record) => {
              if (
                record.exams_count === 0 &&
                record.latest_exam?.status === "not_started"
              ) {
                return "-";
              } else {
                return dayjs(record.latest_exam?.created_at).format(dateSTime);
              }
            },
          },
          {
            title: "Результат",
            dataIndex: ["latest_exam", "status"],
            valueType: "select",
            valueEnum: {
              not_started: { text: "Не начат" },
              launched: { text: "Запущен" },
              completed: { text: "Завершен" },
              failed: { text: "Провален" },
            },
            filters: true,
          },
          {
            title: "Использовано попыток",
            dataIndex: ["exams_count"],
          },
          {
            title: "Всего попыток",
            dataIndex: ["test_snapshot", "testing_attempts_limit"],
          },
          {
            title: "Действие",
            render: (_, record, __, action) => {
              return (
                <ModalForm
                  modalProps={{
                    destroyOnClose: true,
                  }}
                  onFinish={(values) =>
                    batchUpdate([record], values, "rewrite").finally(() => {
                      action?.reload();
                    })
                  }
                  title={"Изменение попыток"}
                  trigger={
                    <Button
                      disabled={
                        !record.test_snapshot.is_limits_testing_attempts
                      }
                      type={"link"}
                    >
                      Изменить попытки
                    </Button>
                  }
                >
                  <ProFormDigit
                    name="testing_attempts_limit"
                    label="Количество попыток"
                    rules={[{ required: true, max: 200, type: "number" }]}
                  />
                </ModalForm>
              );
            },
          },
        ]}
        toolBarRender={(action, { selectedRows }) => {
          return [
            <ModalForm
              modalProps={{
                destroyOnClose: true,
              }}
              onFinish={(values) =>
                batchUpdate(selectedRows!, values, "append").finally(() => {
                  action?.reload();
                })
              }
              title={"Добавить попытки"}
              trigger={
                <Button
                  disabled={
                    selectedRows!.length === 0 ||
                    selectedRows!.length !==
                      selectedRows!.filter(
                        (value) =>
                          value.test_snapshot.is_limits_testing_attempts,
                      ).length
                  }
                  type={"primary"}
                >
                  Добавить попытки
                </Button>
              }
            >
              <ProFormDigit
                name="testing_attempts_limit"
                label="Количество попыток"
                rules={[{ required: true, max: 200, type: "number" }]}
              />
            </ModalForm>,
          ];
        }}
        options={false}
        search={false}
        pagination={{ showSizeChanger: true }}
        hasQueryParams
      />
    </Space>
  );
};

export default Page;
