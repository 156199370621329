import React from "react";
import NavigationMenu, {
  NavigationMenuProps,
} from "@/shared/navigation-menu/ui";
import {
  BankOutlined,
  FileProtectOutlined,
  FileZipOutlined,
  FlagOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

const Menu: React.FC = () => {
  const items: NavigationMenuProps["items"] = [
    {
      path: "/spaces",
      label: "Пространства",
      icon: <BankOutlined />,
    },
    {
      path: "/licenses",
      label: "Лицензии",
      icon: <FileProtectOutlined />,
    },
    {
      path: "/feature-flags",
      label: "Флаги функциональности",
      icon: <FlagOutlined />,
    },
    {
      path: "/feature-flags-packages",
      label: "Пакеты флагов функциональности",
      icon: <FileZipOutlined />,
    },
    {
      path: "/dashboard",
      label: "Дашборд",
      icon: <LineChartOutlined />,
    },
  ]
    // Add key
    .map((item) => {
      return {
        ...item,
        key: item.path,
      };
    });

  return (
    <NavigationMenu
      mode={"horizontal"}
      items={items}
      style={{ flex: 1, minWidth: 0 }}
    />
  );
};

export default Menu;
