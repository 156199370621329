import React from "react";
import {
  Divider,
  Flex,
  Grid,
  Modal,
  ModalProps,
  Space,
  SpaceProps,
  Spin,
  theme,
  Typography,
} from "antd";
import { ProCard, ProForm, ProFormSelect } from "@ant-design/pro-components";
import { Exam } from "@/models";
import axios from "@/axios";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { AxiosRequestConfig } from "axios";
import ExamTable from "@/entities/exam/ui/table";
import ExamDescriptions from "@/entities/exam/ui/descriptions";
import ExamReplyQuestionsTable from "@/entities/exam/ui/reply-questions-table";
import ExamReplyQuestionsDescriptions from "@/entities/exam/ui/reply-questions-descriptions";
import { deepmerge } from "deepmerge-ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

interface Answer {
  value: string;
  is_correct: boolean;
  is_replied: boolean;
  is_correct_variant: boolean;
}

interface Question {
  text: string;
  points: number;
  type: "multiple" | "single";
  answers: Answer[];
  is_correct: boolean;
  is_completed: boolean;
}

interface Record {
  exam_id: Exam["id"];
}

type Props = ModalProps &
  SpaceProps & {
    modal?: boolean;
    test_id?: string;
  };

const ResultTest: React.FC<Props> = ({ modal = false, test_id, ...props }) => {
  const { pool_participant_id } = useParams();
  const screens = Grid.useBreakpoint();

  const { token } = theme.useToken();

  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: "/api/exams/search",
    data: {
      includes: [{ relation: "test_participant" }],
      filters: [
        {
          field: "status",
          operator: "in",
          value: ["completed", "failed"],
        },
        {
          field: "test_participant.test_id",
          operator: "=",
          value: test_id,
        },
        {
          field: "pool_participant_id",
          operator: "=",
          value: pool_participant_id,
        },
      ],
    },
  };
  const {
    data: exams,
    isLoading,
    error,
  } = useSWR(axiosConfig, (config) =>
    axios<OrionRestIndexResponse<Exam>>(config).then((res) => res.data.data),
  );
  const [form] = ProForm.useForm<Record>();
  const exam_id = ProForm.useWatch("exam_id", form);

  const Component = modal ? Modal : Space;
  const spaceProps: SpaceProps = {
    direction: "vertical",
    style: { width: "100%" },
  };
  props = deepmerge(spaceProps, props);

  if (modal) {
    const modalFormProps = {
      modalProps: {
        destroyOnClose: true,
      },
      width: screens.xl ? "60%" : "80%",
      style: { maxWidth: "1200px" },
      footer: null,
    };
    props = deepmerge(modalFormProps, props);
  }

  const getExam = () => exams!.find((exam) => exam.id === exam_id);

  if (!pool_participant_id || !test_id) {
    throw new Error("pool_participant_id or test_id is undefined");
  }

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!exams) throw new Error("exams is undefined");

  const lastExamAttempt =
    exams.length > 0
      ? exams?.reduce((latest: Exam, current: Exam) => {
          const latestDate = latest.ended_at
            ? new Date(latest.ended_at)
            : new Date(0);
          const currentDate = current.ended_at
            ? new Date(current.ended_at)
            : new Date(0);

          return latestDate > currentDate ? latest : current;
        }).id
      : undefined;

  const ExamStatus: React.FC<{ color: string }> = ({ color }) => {
    return (
      <span
        style={{
          display: "inline-block",
          marginRight: "8px",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: color,
        }}
      />
    );
  };

  return (
    <Component {...props}>
      <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
        <Flex justify="space-between">
          <Typography.Title level={5} style={{ margin: 0 }}>
            Результат теста
          </Typography.Title>
        </Flex>
        <ProForm<Record>
          form={form}
          submitter={false}
          autoFocus={false}
          autoFocusFirstInput={false}
        >
          <ProFormSelect
            name={"exam_id"}
            label={"Выберите попытку"}
            initialValue={lastExamAttempt}
            options={exams.map(({ status, id, attempt_number }) => {
              return {
                title: `Попытка ${attempt_number}`,
                label: (
                  <div>
                    <ExamStatus
                      color={status === "completed" ? token.green6 : token.red6}
                    />
                    Попытка №{attempt_number}
                  </div>
                ),
                value: id,
              };
            })}
            allowClear={false}
          />
        </ProForm>
        {exam_id &&
          (modal ? (
            <>
              <ExamTable
                dataSource={exams.filter((exam) => exam.id === exam_id)}
                pagination={false}
                toolBarRender={false}
                search={false}
                columns={[
                  { dataIndex: "attempt_number", hideInTable: true },
                  { dataIndex: "ended_at" },
                  { dataIndex: "spent_time" },
                  { dataIndex: ["result", "current_score"] },
                  { dataIndex: "status" },
                ]}
              />
              {getExam()!.test_participant?.test_snapshot!
                .show_results_format === "indicate" && (
                <ExamReplyQuestionsTable
                  dataSource={getExam()!.result.questions}
                  search={false}
                  pagination={false}
                  toolBarRender={false}
                  scroll={{ y: 400 }}
                  columns={[
                    { dataIndex: "text" },
                    { dataIndex: "points" },
                    { dataIndex: "answers.*.text.join()" },
                    { dataIndex: "is_correct" },
                  ]}
                />
              )}
            </>
          ) : (
            <>
              <ExamDescriptions
                dataSource={getExam()}
                layout="horizontal"
                columns={[
                  { dataIndex: "spent_time" },
                  { dataIndex: "ended_at" },
                  { dataIndex: ["result", "current_score"] },
                  { dataIndex: "status" },
                ]}
              />
              {getExam()!.test_participant?.test_snapshot!
                .show_results_format === "indicate" && (
                <>
                  <Divider type="horizontal" />
                  <Typography.Text strong>Подробнее:</Typography.Text>
                  <Space
                    direction="vertical"
                    style={{
                      maxHeight: "50vh",
                      overflow: "scroll",
                    }}
                    size={16}
                  >
                    {getExam()!.result.questions.map(
                      (question: Question, index: any) => (
                        <ProCard
                          key={index}
                          bordered
                          results={12}
                          extra={
                            question.is_correct ? (
                              <CheckCircleTwoTone twoToneColor={token.green6} />
                            ) : (
                              <CloseCircleTwoTone twoToneColor={token.red6} />
                            )
                          }
                          headStyle={{
                            backgroundColor: question.is_correct
                              ? token.green1
                              : token.red1,
                            padding: token.paddingXS,
                            borderRadius: 4,
                          }}
                          bodyStyle={{ padding: token.paddingXS }}
                          title={
                            <Typography.Text strong>
                              {question.text}
                            </Typography.Text>
                          }
                        >
                          <ExamReplyQuestionsDescriptions
                            key={question.text + question.points}
                            dataSource={question}
                            columns={[
                              { dataIndex: "points" },
                              { dataIndex: "answers.*.text.join()" },
                            ]}
                          />
                        </ProCard>
                      ),
                    )}
                  </Space>
                </>
              )}
            </>
          ))}
      </Space>
    </Component>
  );
};

export default ResultTest;
