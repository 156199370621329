import React from "react";
import {
  ModalForm,
  ModalFormProps as BaseModalFormProps,
  ProForm,
  ProFormDateTimePicker,
  ProFormList,
  ProFormSelect,
  ProFormSwitch,
} from "@ant-design/pro-components";
import {
  Course,
  Member,
  Pool,
  PoolParticipant as BasePoolParticipant,
} from "@/models";
import { deepmerge } from "deepmerge-ts";
import { ButtonProps } from "antd/es/button";
import { Button, message } from "antd";
import axios from "@/axios";
import { dateSTime, dateSTimeNormalize } from "@/shared/dayjs/lib/formats";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";

type PoolParticipant = BasePoolParticipant & {
  course_id?: Course["id"];
};

type Data = {
  pools_participants: PoolParticipant[];
};

type PoolWithParticipants = Partial<PoolParticipant> & {
  membersIds: Member["id"][];
};

type ModalFormProps = BaseModalFormProps<Data>;

type MemberEducationPurposeModalFormProps = ModalFormProps & {
  membersIds: Member["id"][];
  triggerButtonProps?: ButtonProps;
};

const MemberEducationPurposeModalForm: React.FC<
  MemberEducationPurposeModalFormProps
> = ({ membersIds, triggerButtonProps = {}, ...modalFormProps }) => {
  const defaultTriggerButtonProps: ButtonProps = {
    disabled: membersIds.length === 0,
    type: "primary",
  };

  triggerButtonProps = deepmerge(defaultTriggerButtonProps, triggerButtonProps);

  const trigger = (
    <Button {...triggerButtonProps}>
      {triggerButtonProps?.children ?? "Назначить обучение"}
    </Button>
  );

  const [form] = ProForm.useForm();
  const poolsParticipants = ProForm.useWatch("pools_participants", form);

  const defaultModalFormProps: ModalFormProps = {
    title: "Назначение обучения",
    trigger,
    form,
    modalProps: {
      destroyOnClose: true,
      width: `calc(1130px + 24px * 2)`,
    },
    initialValues: {
      pools_participants: [{}],
    },
    onFinish: async (values) => {
      const poolsIds = values.pools_participants.map(
        (poolParticipant) => poolParticipant.pool_id,
      );
      const filters = {
        id: poolsIds ?? null,
      };
      const existingPoolsParticipants = await axios
        .post<OrionRestIndexResponse<Pool>>("/api/pools/search", {
          includes: [{ relation: "participants" }],
          ...axiosConfigAdapter({}, {}, filters),
        })
        .then((res) =>
          res.data.data.map((pool) => ({
            pool_id: pool.id,
            membersIds: pool.participants!.map(
              (participant) => participant.member_id,
            ),
          })),
        )
        .catch((err) => {
          message.error(
            err.response.data.message ??
              "Ошибка при добавлении участников в поток",
          );
        });

      const selectedPoolsParticipants = values.pools_participants.map(
        (poolParticipant) => ({
          ...poolParticipant,
          membersIds,
        }),
      );

      const filterUniqueMembers = (
        existing: PoolWithParticipants[],
        selected: PoolWithParticipants[],
      ) => {
        return selected.map((selectedPool) => {
          const existingGroup = existing.find(
            (pool) => pool.pool_id === selectedPool.pool_id,
          );
          if (!existingGroup) {
            return selectedPool;
          }

          const uniqueMembers = selectedPool.membersIds.filter((memberId) => {
            return !existingGroup.membersIds.includes(memberId);
          });

          return {
            ...selectedPool,
            membersIds: uniqueMembers,
          };
        });
      };
      const resources = [] as PoolParticipant[];
      if (existingPoolsParticipants) {
        const poolsParticipants = filterUniqueMembers(
          existingPoolsParticipants,
          selectedPoolsParticipants,
        );

        poolsParticipants.forEach((poolParticipant) => {
          delete poolParticipant.course_id;
          const { membersIds, ...rest } = poolParticipant;
          resources.push(
            ...poolParticipant.membersIds.map(
              (memberId) =>
                ({
                  ...rest,
                  member_id: memberId,
                } as PoolParticipant),
            ),
          );
        });
      } else {
        const poolParticipants = values.pools_participants.flatMap(
          (poolParticipant) => {
            delete poolParticipant.course_id;

            return membersIds.map(
              (memberId) =>
                ({
                  ...poolParticipant,
                  member_id: memberId,
                } as PoolParticipant),
            );
          },
        );
        resources.push(...poolParticipants);
      }

      if (resources.length === 0) {
        message.error("Пользователи уже есть в указанных потоке/потоках");
        return false;
      }

      return axios
        .post("/api/pool-participants/batch", {
          resources,
        })
        .then(() => {
          message.success("Обучение успешно назначено");
          return true;
        })
        .catch((error) => {
          message.error(error.response.data.message);
          return false;
        });
    },
  };

  modalFormProps = deepmerge(defaultModalFormProps, modalFormProps);

  return (
    <ModalForm {...modalFormProps}>
      <ProFormList
        min={1}
        name="pools_participants"
        creatorButtonProps={{ creatorButtonText: "Добавить обучение" }}
        copyIconProps={{ tooltipText: "Дублировать" }}
        deleteIconProps={{ tooltipText: "Удалить" }}
      >
        {(_, index, action) => {
          const participant = poolsParticipants?.[index];

          return (
            <ProForm.Group>
              <ProFormSelect
                name={"course_id"}
                showSearch
                label={"Курс"}
                width={"sm"}
                rules={[{ required: true }]}
                debounceTime={500}
                style={{ minWidth: "242px" }}
                request={async (params) => {
                  return axios
                    .post<{ data: Course[] }>("/api/courses/search", {
                      filters: [
                        {
                          field: "name",
                          operator: "ilike",
                          value: `%${params?.keyWords ?? ""}%`,
                        },
                      ],
                      sort: [{ direction: "desc", field: "created_at" }],
                    })
                    .then((res) => {
                      return res.data.data.map((course) => ({
                        label: course.name,
                        value: course.id,
                      }));
                    });
                }}
                onChange={() => {
                  action.setCurrentRowData({
                    pool_id: undefined,
                    is_unlimited: undefined,
                    starts_at: undefined,
                    ends_at: undefined,
                  });
                }}
              />
              <ProFormSelect
                name={"pool_id"}
                showSearch
                label={"Поток"}
                width={"sm"}
                rules={[{ required: true }]}
                debounceTime={500}
                disabled={poolsParticipants?.[index]?.course_id === undefined}
                style={{ minWidth: "242px" }}
                params={{ course_id: participant?.course_id }}
                request={async (params) => {
                  if (params?.course_id === undefined) {
                    return Promise.resolve([]);
                  }

                  return axios
                    .post<{ data: Pool[] }>("/api/pools/search", {
                      filters: [
                        {
                          field: "name",
                          operator: "ilike",
                          value: `%${params!.keyWords ?? ""}%`,
                        },
                        {
                          field: "course_id",
                          operator: "=",
                          value: params!.course_id!,
                        },
                      ],
                      sort: [{ direction: "desc", field: "created_at" }],
                    })
                    .then((res) => {
                      return res.data.data.map((pool) => ({
                        value: pool.id,
                        label: pool.name,
                      }));
                    });
                }}
                onChange={async (value) => {
                  if (value === undefined) {
                    action.setCurrentRowData({
                      is_unlimited: undefined,
                      starts_at: undefined,
                      ends_at: undefined,
                    });
                    return;
                  }

                  const {
                    data: { data: pool },
                  } = await axios.get<{
                    data: Pool;
                  }>(`/api/pools/${value}`);

                  action.setCurrentRowData({
                    is_unlimited: pool.is_unlimited,
                    starts_at: pool.starts_at,
                    ends_at: pool.is_unlimited ? undefined : pool?.ends_at,
                  });
                }}
              />
              <ProFormDateTimePicker
                name={"starts_at"}
                label={"Начало"}
                rules={[{ required: true }]}
                disabled={participant?.pool_id === undefined}
                fieldProps={{ format: dateSTime }}
                normalize={dateSTimeNormalize}
              />
              <ProFormSwitch
                name={"is_unlimited"}
                label={"Б"}
                tooltip={"Бессрочный"}
                style={{ minWidth: "100px" }}
                disabled={participant?.pool_id === undefined}
              />
              <ProFormDateTimePicker
                name={"ends_at"}
                label={"Конец"}
                rules={[{ required: !participant?.is_unlimited }]}
                disabled={
                  participant?.pool_id === undefined ||
                  participant?.is_unlimited
                }
                fieldProps={{ format: dateSTime }}
                normalize={dateSTimeNormalize}
              />
            </ProForm.Group>
          );
        }}
      </ProFormList>
    </ModalForm>
  );
};

export default MemberEducationPurposeModalForm;
export type { MemberEducationPurposeModalFormProps };
