import React from "react";
import { Outlet } from "react-router-dom";
import LayoutInfo from "@/pages/layout-info.tsx";
import LayoutSentryUserFeedbackModal from "@/pages/layout-sentry-user-feedback-modal.tsx";

const Layout: React.FC = () => {
  return (
    <>
      <LayoutInfo />
      <LayoutSentryUserFeedbackModal />
      <Outlet />
    </>
  );
};

export default Layout;
