import React from "react";
import MembersTable from "@/entities/member/ui/table";
import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const Page: React.FC = () => {
  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <MembersTable
        style={{
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
        }}
        hasQueryParams
        columns={[
          {
            dataIndex: "full_name",
            render: (dom, { id }) => (
              <Link to={`/manage/members/${id}`}>{dom}</Link>
            ),
            fixed: "left",
          },
          { dataIndex: "role", fixed: "left", width: 125 },
          { dataIndex: "email", fixed: "left" },
          { dataIndex: "username" },
          { dataIndex: "status", width: 150 },
          {
            dataIndex: ["org_structure_assignments", "department_id"],
            width: 200,
          },
          {
            dataIndex: ["org_structure_assignments", "position_id"],
            width: 200,
          },
          {
            dataIndex: ["groups", "id"],
            width: 200,
            render: (_, { groups }) => {
              if (groups && groups.length === 0) {
                return "-";
              }
              return (
                <Typography.Text ellipsis={{ tooltip: true }}>
                  {groups?.map((el, index) => (
                    <Link
                      key={`${el.id}-position`}
                      to={`/manage/groups/${el.id}`}
                    >
                      {el.name ? el.name : "-"}
                      {index !== groups.length - 1 ? ", " : ""}
                    </Link>
                  ))}
                </Typography.Text>
              );
            },
          },
          { dataIndex: "created_at", fixed: "right" },
        ]}
      />
    </Space>
  );
};

export default Page;
