import React from "react";
import {
  ProForm,
  ProFormCheckbox,
  ProFormDigit,
  ProFormList,
  ProFormListProps,
  ProFormProps,
  ProFormRadio,
  ProFormSelect,
  ProFormSelectProps,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Col, Divider, Flex, message, Row } from "antd";
import BaseQuestion from "@/entities/test/lib/question";
import {
  CaretDownOutlined,
  CaretUpFilled,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { TestFormRecord } from "@/entities/test/ui/form";
import { v4 as uuidv4 } from "uuid";

type Question = BaseQuestion & {
  is_deleted?: boolean;
};

type TestQuestionFormProps = {
  currentIndex: number;
  form: ProFormProps<TestFormRecord>["form"];
};

// TODO: Валидация на минимум один правильный ответ
const TestQuestionFormFields: React.FC<TestQuestionFormProps> = ({
  form: _form,
  currentIndex,
}) => {
  const [form] = ProForm.useForm<TestFormRecord>(_form);

  const questions = ProForm.useWatch("questions", form);
  const question = questions?.[currentIndex];

  const setQuestion = (question: Question) => {
    const questions = form.getFieldValue("questions");
    form.setFieldsValue({
      questions: [
        ...questions.slice(0, currentIndex),
        question,
        ...questions.slice(currentIndex + 1),
      ],
    });
  };

  const onTypeChange: ProFormSelectProps<Question["type"]>["onChange"] = (
    value,
  ) => {
    const question: Question = form.getFieldValue(["questions", currentIndex]);
    // При смене с одиночного на множественный и обратно, сбрасывается значение is_correct
    setQuestion({
      ...question,
      type: value,
      answers: question.answers.map((answer) => {
        return {
          ...answer,
          is_correct: false,
        };
      }),
    });

    message.warning(
      "Из-за смены типа вопроса, сброшено значения правильности ответов",
    );
  };

  const onAfterAddAnswer: ProFormListProps<
    Question["answers"]
  >["onAfterAdd"] = (_, addedIndex) => {
    const question: Question = form.getFieldValue(["questions", currentIndex]);

    question.answers[addedIndex!] = {
      is_correct: false,
      // @ts-ignore
      value: null,
      uuid: uuidv4(),
    };

    setQuestion(question);
  };

  const IsCorrectComponent =
    question?.type === "multiple" ? ProFormCheckbox : ProFormRadio;

  const onIsCorrectChange = (event: any) => {
    const type = form.getFieldValue(["questions", currentIndex, "type"]);

    if (type !== "single") return;

    // questions_0_answers_2_is_correct
    //                     ^
    const targetIndex = Number(event.target.id!.split("_")[3]);

    const question: Question = form.getFieldValue(["questions", currentIndex]);

    setQuestion({
      ...question,
      answers: question.answers.map((answer, index) => {
        return {
          ...answer,
          is_correct: index === targetIndex,
        };
      }),
    });
  };

  const switchDeleteMark = () => {
    const questionsForm = form.getFieldValue(["questions"]);
    questionsForm[currentIndex].is_deleted =
      !questions[currentIndex].is_deleted;

    form.setFieldsValue({ questions: questionsForm });
  };

  return (
    <>
      <ProFormCheckbox
        name={["questions", currentIndex, "is_deleted"]}
        hidden
      />

      <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
        <Button
          type={question?.is_deleted ? "default" : "primary"}
          danger={!question?.is_deleted}
          onClick={switchDeleteMark}
        >
          {question?.is_deleted ? "Восстановить вопрос" : "Удалить вопрос"}
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Сохранить
        </Button>
      </Flex>
      <Divider />
      <Row style={{ width: "100%" }} justify={"space-between"}>
        <Col span={17}>
          <ProFormSelect<Question["type"]>
            name={["questions", currentIndex, "type"]}
            label={"Тип вопроса"}
            options={[
              { value: "multiple", label: "Множественный выбор" },
              { value: "single", label: "Одиночный выбор" },
            ]}
            allowClear={false}
            rules={[{ required: true }]}
            onChange={onTypeChange}
          />
        </Col>
        <Col span={6}>
          <ProFormDigit
            name={["questions", currentIndex, "points"]}
            label={"Балл"}
            disabled
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
      <ProFormText
        name={["questions", currentIndex, "uuid"]}
        hidden
        rules={[{ required: true }]}
      />
      <ProFormText
        name={["questions", currentIndex, "text"]}
        label={"Текст вопроса"}
        rules={[{ required: true }]}
      />
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 454px - 16px - 20px)",
          overflowY: "auto",
        }}
      >
        <ProFormList
          style={{ marginBottom: 0 }}
          name={["questions", currentIndex, "answers"]}
          label={"Варианты ответов"}
          creatorButtonProps={{
            type: "primary",
            position: "bottom",
            creatorButtonText: "Добавить вариант ответа",
          }}
          min={2}
          max={10}
          onAfterAdd={onAfterAddAnswer}
          actionRender={(fieldProps, { add, remove, move }) => {
            const lastIndex =
              form.getFieldValue(["questions", currentIndex, "answers"])
                .length - 1;

            return [
              <Button
                key={"move-down"}
                icon={<CaretDownOutlined />}
                type={"text"}
                disabled={fieldProps.name === lastIndex}
                onClick={() => {
                  move(fieldProps.name, fieldProps.name + 1);
                }}
              />,
              <Button
                key={"move-up"}
                icon={<CaretUpFilled />}
                type={"text"}
                disabled={fieldProps.name === 0}
                onClick={() => {
                  move(fieldProps.name, fieldProps.name - 1);
                }}
              />,
              <Button
                key={"duplicate"}
                icon={<CopyOutlined />}
                type={"text"}
                disabled={question?.answers.length >= 10}
                onClick={() => {
                  const defaultValue = form.getFieldValue([
                    "questions",
                    currentIndex,
                    "answers",
                    fieldProps.name,
                  ]);

                  if (questions[currentIndex].type === "single")
                    defaultValue.is_correct = false;

                  add(defaultValue, fieldProps.name + 1);
                }}
              />,
              <Button
                key={"remove"}
                icon={<DeleteOutlined />}
                disabled={!question || question?.answers.length <= 2}
                type={"text"}
                onClick={() => {
                  remove(fieldProps.name);
                }}
              />,
            ];
          }}
        >
          <ProForm.Group>
            <IsCorrectComponent
              name={"is_correct"}
              valuePropName={"checked"}
              rules={[{ required: true }]}
              fieldProps={{
                onChange: onIsCorrectChange,
              }}
            >
              Правильный ответ
            </IsCorrectComponent>
            <ProFormText
              name={"value"}
              rules={[{ required: true }]}
              allowClear={false}
            />
            <ProFormText name={"uuid"} hidden rules={[{ required: true }]} />
          </ProForm.Group>
        </ProFormList>
      </div>
    </>
  );
};
export default TestQuestionFormFields;
export type { TestQuestionFormProps };
