import React from "react";
import { Card, Statistic } from "antd";
import useSWR from "swr";
import axios from "@/axios.ts";

const IndicatorContentOnline: React.FC = () => {
  const { data, error } = useSWR(
    ["/api/dashboards/statistics/online"],
    async ([url]) => {
      return axios.get<{ data: number }>(url).then((res) => res.data.data);
    },
    { refreshInterval: 1000 },
  );

  if (error) throw error;

  return (
    <Card title={"Онлайн платформы"}>
      <Statistic value={data} />
    </Card>
  );
};

export default IndicatorContentOnline;
