import React, { useState } from "react";
import { Department, Member, Position } from "@/models";
import axios from "@/axios";
import MemberForm from "@/entities/member/ui/form";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import MemberEducationPurposeModalForm from "@/features/member/education-purpose/ui/modal-form";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { Space, Tag, Typography } from "antd";
import useSpace from "@/entities/space/lib/use";
import MemberResetPasswordButton from "@/features/member/reset-password/ui/button";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum.tsx";
import ExportButton from "@/entities/export/ui/button";
import ImportButton from "@/entities/import/ui/button";

type Params = {
  role: string;
};

type MembersTableProps = ProTableProps<Member, Params>;

const MembersTable: React.FC<MembersTableProps> = ({ ...props }) => {
  const space = useSpace();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const defaultProps: MembersTableProps = {
    rowKey: "id",
    pagination: { showSizeChanger: true },
    options: false,
    search: false,
    toolBarRender: (action, { selectedRowKeys }) => {
      if (action === undefined) {
        throw new Error("action is undefined");
      }

      if (selectedRowKeys === undefined) {
        throw new Error("selectedRowKeys is undefined");
      }

      return [
        <MemberResetPasswordButton
          key="reset-password"
          membersIds={selectedRowKeys.map(Number)}
          onAfterFinish={() => action.reload()}
        />,
        <MemberEducationPurposeModalForm
          key="education-purpose"
          membersIds={selectedRowKeys.map(Number)}
        />,
        <ExportButton
          metaFields={{ members_ids: selectedRowKeys.map(Number) }}
          type="export_members"
        />,
        <ImportButton
          key="import"
          type="members"
          onAfterFinish={() => action.reload()}
        />,
        <MemberForm
          rest={{
            type: "create",
            onAfterCreate: () => action.reload(),
          }}
          modal
        />,
      ];
    },
    request: async (
      params,
      sort,
      filter: Record<string, (string | number | null)[] | null>,
    ) => {
      if (sort === undefined || Object.keys(sort).length === 0) {
        sort = { created_at: "descend" };
      }

      const {
        ["org_structure_assignments,department_id"]: departmentIds,
        ["org_structure_assignments,position_id"]: positionIds,
        ["groups,id"]: groupsIds,
        ...filters
      } = filter;

      const config: AxiosRequestConfig = {
        method: "POST",
        url: `/api/members/search`,
        ...axiosConfigAdapter(params, sort, filters),
      };

      if (departmentIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInOrgStructureAssignments",
          parameters: [
            departmentIds.includes(null),
            "department_id",
            departmentIds,
          ],
        });
      }

      if (positionIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInOrgStructureAssignments",
          parameters: [positionIds.includes(null), "position_id", positionIds],
        });
      }

      if (groupsIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInGroups",
          parameters: [groupsIds.includes(null), groupsIds],
        });
      }

      config.data.includes.push(
        {
          relation: "org_structure_assignments.position",
        },
        {
          relation: "org_structure_assignments.department",
        },
        {
          relation: "groups",
        },
      );
      const data = await axios
        .request<OrionRestIndexResponse<Member>>(config)
        .then((res) => res.data);

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    rowSelection: {
      preserveSelectedRowKeys: true,
      selectedRowKeys,
      onChange: (keys) => {
        setSelectedRowKeys(keys);
      },
    },
    scroll: {
      x: 1400,
      y: `calc(100vh - ${selectedRowKeys.length ? 393 : 332}px)`,
    },
    columns: [
      {
        title: "ФИО",
        dataIndex: "full_name",
        filters: true,
        filterMode: "search",
        sorter: true,
      },
      {
        title: "Роль",
        dataIndex: "role",
        valueType: "select",
        valueEnum: {
          administrator: {
            text: <Tag color="error">Администратор</Tag>,
          },
          employee: {
            text: <Tag color="processing">Сотрудник</Tag>,
          },
        },
        filters: true,
      },
      {
        title: "Почта",
        dataIndex: "email",
        filters: true,
        filterMode: "search",
        hideInTable: !space.authentication_types.includes("email+password"),
        copyable: true,
      },
      {
        title: "Логин",
        dataIndex: "username",
        filters: true,
        filterMode: "search",
        hideInTable: !space.authentication_types.includes("username+password"),
        copyable: true,
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: statusValueEnum,
        defaultFilteredValue: ["active"],
        filters: true,
      },
      {
        title: "Подразделение",
        dataIndex: ["org_structure_assignments", "department_id"],
        render: (_, { org_structure_assignments }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-department`}>
                  {el.department?.name ? el.department?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/departments/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Department>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Должность",
        dataIndex: ["org_structure_assignments", "position_id"],
        render: (_, { org_structure_assignments }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-position`}>
                  {el.position?.name ? el.position?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/positions/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Группa",
        dataIndex: ["groups", "id"],
        render: (_, { groups }) => {
          return (
            <Typography.Text ellipsis={{ tooltip: true }}>
              {groups?.map((el) => el.name).join(", ")}
            </Typography.Text>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/groups/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((group) => ({
                  label: group.name,
                  value: group.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Дата добавления",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return <ProTable<Member, Params> {...props} />;
};

export default MembersTable;
export type { MembersTableProps };
