import React from "react";
import { Link, useParams } from "react-router-dom";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Space } from "antd";
import { ActionType } from "@ant-design/pro-table/es/typing";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const Page: React.FC = () => {
  const { pool_id } = useParams();

  let actionRef = React.useRef<ActionType>();

  return (
    <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <PoolParticipantTable
        hasQueryParams
        poolId={Number(pool_id!)}
        actionRef={actionRef}
        columns={[
          {
            dataIndex: ["member", "full_name"],
            render: (node, { member }) => (
              <Link to={`/manage/members/${member.id}?tab=pools`}>{node}</Link>
            ),
          },
          { dataIndex: "registry_number" },
          { dataIndex: "status" },
          { dataIndex: "progress_percent" },
          { dataIndex: "starts_at" },
          { dataIndex: "started_at" },
          { dataIndex: "ends_at" },
          { dataIndex: "ended_at" },
        ]}
      />
    </Space>
  );
};

export default Page;
