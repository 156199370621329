import React from "react";
import CoursesTable from "@/entities/course/ui/table";
import { Link, useNavigate } from "react-router-dom";
import { Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const Page: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <AutoBreadcrumb />
      <CoursesTable
        hasQueryParams
        scroll={{ y: "calc(100vh - 300px)" }}
        style={{
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
        }}
        columns={[
          {
            dataIndex: "name",
            render: (dom, { id: course_id }) => {
              return <Link to={`/manage/courses/${course_id}`}>{dom}</Link>;
            },
          },
          { dataIndex: "key" },
          { dataIndex: "description" },
          { dataIndex: "created_at" },
        ]}
        rest={{
          onAfterCreate: (course) => {
            navigate(`/manage/courses/${course.id}?tab=embeds`);
          },
        }}
      />
    </Space>
  );
};

export default Page;
