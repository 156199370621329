import React, { useEffect, useState } from "react";
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormDigitProps,
  ProFormItem,
  ProFormItemProps,
  ProFormProps,
  ProFormSelectProps,
  ProFormSwitch,
  ProFormSwitchProps,
  ProFormText,
} from "@ant-design/pro-components";
import { Member as BaseMember, Pool, PoolParticipant } from "@/models";
import { deepmerge } from "deepmerge-ts";
import PoolSelect from "@/entities/pool/ui/select";
import { Col, Flex, Row, Spin, Transfer, TransferProps } from "antd";
import useSWRInfinite from "swr/infinite";
import axios from "@/axios";
import MemberDepartmentInput from "@/entities/member/ui/department-input";
import PositionInput from "@/entities/member/ui/position-input";
import { debounce } from "@/shared/helpers/debounce";
import dayjs from "dayjs";
import { TransferDirection } from "antd/lib/transfer";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import {
  dateSTime,
  dateSTimeNormalize,
  toISOString,
} from "@/shared/dayjs/lib/formats";

type Member = BaseMember & {
  meta: OrionRestIndexResponse<Member>["meta"];
};

type Data = PoolParticipant;

type FormProps = ProFormProps<Data>;

type PoolParticipantFormProps = FormProps & {
  fieldsProps?: {
    id?: ProFormDigitProps;
    poolId?: ProFormSelectProps;
    memberId?: React.ComponentProps<typeof ProFormText>;
    name?: React.ComponentProps<typeof ProFormText>;
    startsAt?: React.ComponentProps<typeof ProFormDateTimePicker>;
    is_unlimited?: ProFormSwitchProps;
    endsAt?: React.ComponentProps<typeof ProFormDateTimePicker>;
    membersIds?: ProFormItemProps & {
      transferProps?: TransferProps<Member>;
    };
  };
};

const PoolParticipantForm: React.FC<PoolParticipantFormProps> = ({
  form: formProp,
  ...props
}) => {
  const [form] = ProForm.useForm(formProp);
  const poolId = ProForm.useWatch("pool_id", form);
  const isUnlimited = ProForm.useWatch("is_unlimited", form);

  const [isUnlimitedDisabled, setIsUnlimitedDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (!poolId) return;

    axios
      .get<{ data: Pool }>(`/api/pools/${poolId}`)
      .then((res) => res.data.data)
      .then((pool) => {
        setIsUnlimitedDisabled(!pool.is_unlimited);

        form.setFieldsValue({
          is_unlimited: pool.is_unlimited,
          starts_at: toISOString(dayjs()),
          ends_at: pool.is_unlimited ? undefined : pool.ends_at,
        });
      });
  }, [poolId]);

  useEffect(() => {
    if (isUnlimited) form.setFieldsValue({ ends_at: null });
  }, [isUnlimited]);

  const defaultProps: PoolParticipantFormProps = {
    initialValues: {
      is_unlimited: false,
    },
    fieldsProps: {
      id: { name: "id", label: "ID", hidden: true },
      poolId: { name: "pool_id", label: "Поток" },
      memberId: { name: "member_id", label: "Участник" },
      startsAt: {
        name: "starts_at",
        label: "Планируемая дата начала",
        fieldProps: { format: dateSTime },
        normalize: dateSTimeNormalize,
      },
      endsAt: {
        name: "ends_at",
        label: "Планируемая дата завершения",
        hidden: isUnlimited,
        rules: [{ required: !isUnlimited }],
        fieldProps: { format: dateSTime },
        normalize: dateSTimeNormalize,
      },
    },
  };

  props = deepmerge(defaultProps, props);
  const { fieldsProps } = props;

  const MemberTransfer: React.FC<
    TransferProps<Member> & { value?: string[] }
  > = ({ ...props }) => {
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [targetMembers, setTargetMembers] = useState<Member[]>([]);
    const [error, setError] = React.useState<Error | null>(null);
    const [form] = ProForm.useForm();

    useEffect(() => {
      if (props.value && data) {
        setTargetKeys(props.value);
        axios
          .post(`/api/members/search`, {
            filters: [
              {
                field: "id",
                operator: "in",
                value: props.value,
              },
            ],
          })
          .then((res) => {
            setTargetMembers(res.data.data);
          })
          .catch((err) => {
            setError(err);
          });
      }
    }, []);

    const pageSize = 25;

    const {
      data,
      mutate,
      size,
      setSize,
      isLoading,
      error: errorSWR,
      isValidating,
    } = useSWRInfinite(
      (pageIndex: number, previousPageData: Member[]) => {
        if (previousPageData && !previousPageData.length) return null;
        return [`/api/members/search`, pageIndex + 1, poolId];
      },
      async ([url, current, poolId]) => {
        const config: AxiosRequestConfig = {
          method: "POST",
          url,
          ...axiosConfigAdapter({ current, pageSize }),
        };

        const searchValues = form.getFieldsValue();

        if (searchValues["full_name"]) {
          config.data.filters.push({
            field: "full_name",
            operator: "ilike",
            value: `%${searchValues["full_name"]}%`,
          });
        }
        if (searchValues["department_id"]) {
          config.data.filters.push({
            field: "org_structure_assignments.department_id",
            operator: "ilike",
            value: `%${searchValues["department_id"]}%`,
          });
        }
        if (searchValues["position_id"]) {
          config.data.filters.push({
            field: "org_structure_assignments.position_id",
            operator: "ilike",
            value: `%${searchValues["position_id"]}%`,
          });
        }
        if (poolId) {
          config.data.scopes.push({
            name: "whereDoesntHaveInPool",
            parameters: [poolId],
          });
        }

        return axios
          .request<OrionRestIndexResponse<Member>>(config)
          .then((res) => {
            return res.data.data.map((member) => ({
              ...member,
              meta: res.data.meta,
            }));
          });
      },
      {
        revalidateFirstPage: false,
      },
    );

    useEffect(() => {
      if (!isValidating && isSelectAll) {
        const allMembers = data ? data.flat() : [];
        const keys: string[] = allMembers.flatMap((member) => {
          if (
            targetKeys.includes(String(member.id)) &&
            !selectedKeys.includes(String(member.id))
          ) {
            return [];
          }
          return [String(member.id)];
        });
        setSelectedKeys(keys);
        setIsSelectAll(false);
      }
    }, [isValidating, isSelectAll]);

    const total = data?.flat()[0]?.meta?.total || 0;

    const onChange: TransferProps<Member>["onChange"] = (
      nextTargetKeys,
      direction,
      moveKeys,
    ) => {
      if (direction === "right") {
        const nextDataTargetMembers = nextTargetKeys.flatMap((id) => {
          const member = data!
            .flat()
            .find((member) => String(member.id) === id);
          if (member) {
            return [member];
          } else return [];
        });
        setTargetMembers((prevState) => [
          ...prevState,
          ...nextDataTargetMembers,
        ]);
      } else {
        setTargetMembers((prevState) => {
          return prevState.filter((member) =>
            nextTargetKeys.includes(String(member.id)),
          );
        });
      }
      setTargetKeys(nextTargetKeys);
      props.onChange?.(nextTargetKeys, direction, moveKeys);
    };

    const onSelectChange: TransferProps<Member>["onSelectChange"] = async (
      sourceSelectedKeys,
      targetSelectedKeys,
    ) => {
      if (
        sourceSelectedKeys.length > 0 &&
        sourceSelectedKeys.length >= data!.flat().length - targetKeys.length &&
        /** selected more than 1 key */
        sourceSelectedKeys.length +
          targetSelectedKeys.length -
          selectedKeys.length >
          1
      ) {
        const maxSize = Math.ceil(total / pageSize);

        await setSize(maxSize);

        setIsSelectAll(true);
      }
      setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onScroll = (
      _: TransferDirection,
      event: React.SyntheticEvent<HTMLUListElement, Event>,
    ) => {
      const errorScrollHeight = 10;
      const { scrollHeight, clientHeight, scrollTop } = event.currentTarget;
      const isEndScroll =
        scrollHeight - (clientHeight + scrollTop) <= errorScrollHeight;

      if (isEndScroll && size < Math.ceil(total / pageSize)) {
        setSize(size + 1);
      }
    };

    useEffect(() => {
      mutate();
    }, []);

    const debouncedMutate = debounce(mutate, 200);

    if (isLoading) return <Spin />;
    if (errorSWR) throw new Error(errorSWR);
    if (error) throw error;
    if (!data) throw new Error("No data");

    const dataSource = [...targetMembers, ...data.flat()];

    return (
      <>
        <ProForm
          form={form}
          onValuesChange={() => debouncedMutate()}
          submitter={false}
        >
          <Row gutter={12}>
            <Col span={8}>
              <ProFormText name={"full_name"} placeholder="Поиск по ФИО" />
            </Col>
            <Col span={8}>
              <MemberDepartmentInput
                hideAddInput
                name={"department_id"}
                placeholder="Поиск по отделу"
              />
            </Col>
            <Col span={8}>
              <PositionInput
                hideAddInput
                name={"position_id"}
                placeholder="Поиск по должности"
              />
            </Col>
          </Row>
        </ProForm>
        <Transfer<Member>
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onScroll={onScroll}
          disabled={isValidating}
          onSelectChange={onSelectChange}
          dataSource={dataSource}
          rowKey={(record) => String(record.id)}
          titles={["Все участники пространства", "Участники потока"]}
          render={({ full_name, email }) => `${full_name} (${email})`}
          selectAllLabels={[
            ({ selectedCount, totalCount }) =>
              `${selectedCount}/${Math.max(
                total - targetKeys.length,
                0,
              )} элем.`,
            ({ selectedCount, totalCount }) =>
              `${selectedCount}/${totalCount} элем.`,
          ]}
          {...props}
          onChange={onChange}
          style={{ justifyContent: "space-between" }}
          listStyle={{ width: "350px", height: "280px" }}
        />
      </>
    );
  };

  return (
    <ProForm<Data>
      {...props}
      form={form}
      submitter={{
        render(props, dom) {
          return <Flex justify="end">{dom}</Flex>;
        },
        resetButtonProps: false,
        searchConfig: {
          submitText: "Сохранить",
        },
      }}
    >
      <ProFormDigit {...fieldsProps?.id} />
      <PoolSelect {...fieldsProps?.poolId} />
      <ProFormDateTimePicker {...fieldsProps?.startsAt} />
      <ProFormSwitch
        label="Бессрочный"
        name="is_unlimited"
        rules={[{ required: true }]}
        disabled={isUnlimitedDisabled}
        {...fieldsProps?.is_unlimited}
      />
      <ProFormDateTimePicker {...fieldsProps?.endsAt} />
      <ProFormItem
        name={"members_ids"}
        label="Участники"
        {...fieldsProps?.membersIds}
      >
        <MemberTransfer {...fieldsProps?.membersIds?.transferProps} />
      </ProFormItem>
    </ProForm>
  );
};

export default PoolParticipantForm;
export type { PoolParticipantFormProps };
