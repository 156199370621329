import React from "react";
import { Group } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { Typography } from "antd";
import GroupModalForm from "@/entities/group/ui/modal-form";
import GroupDeleteButton from "@/entities/group/ui/delete-button";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import MemberEducationPurposeModalForm from "@/features/member/education-purpose/ui/modal-form";
import GroupParticipantForm from "@/entities/group-participant/ui/form-batch";
import { RestCallbacks } from "@/shared/rest/lib/types";

type Data = Group;

type Params = Partial<Group>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<Group>;
};

const GroupTable: React.FC<Props> = ({ rest, ...props }) => {
  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/groups/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    actionConfig.data.includes.push({
      relation: "participants",
    });

    const { data } =
      await axios.request<OrionRestIndexResponse<Group>>(actionConfig);
    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const toolBarRender: TableProps["toolBarRender"] = (
    action,
    { selectedRowKeys, selectedRows },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
    if (!selectedRows) throw new Error("selectedRows is undefined");
    if (!action) throw new Error("action is undefined");

    const membersIds = selectedRows.flatMap((item) =>
      item.participants.map((participant) => participant.member_id),
    );

    const membersIdsWithoutDuplicates = membersIds.filter(
      (value, index, self) => self.indexOf(value) === index,
    );

    return [
      <GroupDeleteButton
        key={"delete"}
        recordKeys={selectedRowKeys.map(Number)}
        afterRemove={() => {
          action.reload();
          action.clearSelected?.();
        }}
      />,
      <GroupModalForm
        key={`update-${selectedRowKeys.join("-")}`}
        rest={{
          type: "update",
          recordKey: selectedRowKeys,
          onAfterUpdate: () => {
            action.reload();
          },
        }}
      />,
      <GroupModalForm
        key={"create"}
        rest={{
          type: "create",
          onAfterCreate: (record) => {
            rest?.onAfterCreate?.(record);
          },
        }}
      />,
      <GroupParticipantForm
        key="addMembers"
        modal
        groupId={selectedRowKeys.map(Number)}
        onAfterAdd={() => action.reload()}
      />,
      <MemberEducationPurposeModalForm
        key="education-purpose"
        membersIds={membersIdsWithoutDuplicates}
      />,
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {
      preserveSelectedRowKeys: true,
    },
    options: false,
    pagination: { showSizeChanger: true },
    request,
    toolBarRender,
    search: false,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        filters: true,
        filterMode: "search",
      },
      {
        title: "Описание",
        dataIndex: "description",
        render: (_, record) => (
          <Typography.Text>{record.description}</Typography.Text>
        ),
      },
      {
        title: "Количество участников",
        dataIndex: "participants_count",
        sorter: true,
      },
    ],
  };

  return (
    <ProTable<Data, Params>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};

export default GroupTable;
