import React from "react";
import { Result, theme } from "antd";

const Page: React.FC = () => {
  const {
    token: { sizeMD, colorBgBase, borderRadius },
  } = theme.useToken();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        height: "calc(100vh - 64px - 32px)",
        alignItems: "center",
        justifyContent: "center",
        padding: `${sizeMD}px`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: colorBgBase,
      }}
    >
      <Result
        status={"info"}
        title={"Разделы администратора"}
        subTitle={
          "Для просмотра контента выберите соответствующий раздел в боковом меню"
        }
      />
    </div>
  );
};

export default Page;
