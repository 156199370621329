import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./app.tsx";
import "./main.css";
import "./sentry.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
