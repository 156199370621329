import React, { useEffect } from "react";
import LicenseDescriptions from "@/entities/license/ui/descriptions.tsx";
import useSpace from "@/entities/space/lib/use.tsx";
import PageContainer from "@/shared/ant-design/page-container/ui";
import ImportLicenseForm from "@/entities/license/ui/import-button.tsx";
import useMode from "@/entities/mode/lib/use.tsx";
import { Flex } from "antd";

const Page: React.FC = () => {
  const space = useSpace();
  const mode = useMode();

  return (
    <PageContainer>
      <Flex style={{ width: "100%", height: "100%" }}>
        <LicenseDescriptions dataSource={space.license!} />
        {mode === "self-hosted" && (
          <ImportLicenseForm onAfterImport={space.refresh} />
        )}
      </Flex>
    </PageContainer>
  );
};

export default Page;
