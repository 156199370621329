import React from "react";
import { License } from "@/models";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axios from "@/axios";
import statusValueEnum from "@/entities/license/lib/status-value-enum";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import ProTableForAdmin from "../pro-table-for-admin";
import ExportLicenseButton from "@/entities/license/ui/export-button";
import bytesToHumanReadable from "@/shared/helpers/bytes-to-human-readable";
import { Tag } from "antd";
import LicenseModalForm from "@/entities/license/ui/modal-form.tsx";

const Page: React.FC = () => {
  /** Logic */

  const [someSelected, setSomeSelected] = React.useState(false);

  /** Render */

  return (
    <>
      <ProTableForAdmin<License>
        rowKey={"id"}
        search={false}
        options={false}
        hasQueryParams={true}
        scroll={{
          y: `calc(100vh - ${someSelected ? 393 : 332}px)`,
        }}
        pagination={{ showSizeChanger: true, defaultPageSize: 100 }}
        rowSelection={{
          onChange: (selectedRowKeys) => {
            setSomeSelected(selectedRowKeys.length > 0);
          },
        }}
        toolBarRender={(action, { selectedRows }) => {
          if (action === undefined) {
            throw new Error("action is undefined");
          }

          if (selectedRows === undefined) {
            throw new Error("selectedRowKeys is undefined");
          }

          const selectedLicense =
            selectedRows.length === 1 ? selectedRows[0] : undefined;

          return [
            <ExportLicenseButton
              key={"export"}
              recordKey={
                selectedLicense && selectedLicense.space === null
                  ? selectedLicense.id
                  : undefined
              }
            />,
            <>
              <LicenseModalForm
                key={`update`}
                rest={{
                  type: "update",
                  recordKey: selectedLicense?.id,
                  onAfterUpdate: () => {
                    action.reload();
                  },
                }}
              />
            </>,
            <LicenseModalForm
              key={`create`}
              rest={{
                type: "create",
                onAfterCreate: () => {
                  action.reload();
                },
              }}
            />,
          ];
        }}
        request={async (params, sort, filter) => {
          const config: AxiosRequestConfig = {
            method: "POST",
            url: "/api/licenses/search",
            ...axiosConfigAdapter(params, sort, filter),
          };

          config.data.includes.push({ relation: "space" });

          const data = await axios<OrionRestIndexResponse<License>>(
            config,
          ).then((response) => response.data);

          return {
            data: data.data,
            total: data.meta.total,
            success: true,
          };
        }}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            sorter: true,
            filters: true,
            filterMode: "search",
            copyable: true,
          },
          {
            title: "Режим пространства",
            dataIndex: "space_mode",
            valueEnum: {
              cloud: { text: <Tag color="blue">Cloud</Tag> },
              "self-hosted": {
                text: <Tag color="blue-inverse">Self-hosted</Tag>,
              },
            },
            filters: true,
          },
          {
            title: "Название",
            dataIndex: "name",
            sorter: true,
            filters: true,
            filterMode: "search",
          },
          {
            title: "UUID Пространства",
            dataIndex: "space_uuid",
            ellipsis: true,
            sorter: true,
            filters: true,
            filterMode: "search",
            copyable: true,
          },
          {
            title: "Статус",
            dataIndex: "status",
            valueEnum: statusValueEnum,
            filters: true,
          },
          {
            title: "Дата-время истечения активации",
            dataIndex: "expires_at",
            valueType: "dateTime",
            fieldProps: { format: dateSTime },
            ellipsis: true,
            sorter: true,
          },
          {
            title: "Максимальное количество активных участников",
            dataIndex: "max_active_members",
            sorter: true,
          },
          {
            title: "Максимальный объем используемой памяти",
            dataIndex: "max_memory_usage",
            renderText: (value: number) =>
              value ? bytesToHumanReadable(value) : "-",
            sorter: true,
          },
        ]}
      />
    </>
  );
};

export default Page;
