import React from "react";
import MemberForm from "@/entities/member/ui/form";
import useMe from "@/entities/me/lib/use";
import { Space, theme } from "antd";

const Page: React.FC = () => {
  const member = useMe();
  const { token } = theme.useToken();

  return (
    <Space
      direction={"vertical"}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Space
        direction={"vertical"}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "calc(100vh - 64px - 32px)",
          background: token.colorBgContainer,
        }}
      >
        <MemberForm
          rest={{
            type: "update",
            recordKey: member.id,
          }}
          mode="personal"
        />
      </Space>
    </Space>
  );
};

export default Page;
