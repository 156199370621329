import { useRef } from "react";
import type { ProTableProps as AntProTableProps } from "@ant-design/pro-table";
import { ProTable as AntProTable } from "@ant-design/pro-table";
import { ParamsType } from "@ant-design/pro-provider";
import {
  ActionType,
  ProColumns as AntProColumns,
} from "@ant-design/pro-table/es/typing";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Space, theme } from "antd";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import useQueryState from "@/shared/hooks/use-query-state.ts";

type ProColumns<DataType, ValueType> = Omit<
  AntProColumns<DataType, ValueType>,
  "filterMode"
> & {
  filterMode?: AntProColumns<DataType, ValueType>["filterMode"] | "search";
};

type ProTableProps<DataType, Params, ValueType = "text"> = Omit<
  AntProTableProps<DataType, Params, ValueType>,
  "columns"
> & {
  columns?: ProColumns<DataType, ValueType>[];
  hasQueryParams?: boolean | { paramName: string };
};

function ProTable<
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = "text",
>(props: ProTableProps<DataType, Params, ValueType>) {
  const { hasQueryParams = false } = props;

  const { token } = theme.useToken();
  const [tableConfigURL, setTableConfigURL] = useQueryState<
    Record<string, any>
  >(
    typeof hasQueryParams === "object"
      ? hasQueryParams.paramName
      : hasQueryParams
        ? "query"
        : undefined,
    {
      valueType: "object",
    },
  );
  if (typeof props.actionRef === "function") {
    throw new Error(
      "actionRef as function is not supported. Please use useRef instead",
    );
  }

  let actionRef = useRef<ActionType | undefined | null>();
  if (props.actionRef) {
    actionRef = props.actionRef;
  }

  const defaultProps: AntProTableProps<DataType, Params, ValueType> = {
    //
  };

  const overrideProps: AntProTableProps<DataType, Params, ValueType> = {
    actionRef,
  };

  props.columns?.forEach((column) => {
    // --------------------------------  Default Filtered And Sorted by Query params -------------------------------- //
    if (hasQueryParams) {
      if (Object.keys(tableConfigURL?.sort ?? {}).length > 0) {
        column.defaultSortOrder =
          tableConfigURL?.sort?.[column.dataIndex as string] ||
          tableConfigURL?.sort?.[column.sorter as string];
      }

      const filteredValue = tableConfigURL?.filter?.[column.dataIndex as string]
        ? Array.isArray(tableConfigURL?.filter?.[column.dataIndex as string])
          ? tableConfigURL.filter?.[column.dataIndex as string]
          : [tableConfigURL.filter?.[column.dataIndex as string], "searchMode"]
        : undefined;

      column.filteredValue = filteredValue;

      column.defaultFilteredValue = filteredValue;
    }

    // --------------------------------  Filters mode: Search -------------------------------- //

    if (column.filterMode === "search") {
      column.filterMode = undefined;

      if (column.filterIcon === undefined) {
        column.filterIcon = <SearchOutlined />;
      }

      if (column.filterDropdown === undefined) {
        column.filterDropdown = ({
          confirm,
          clearFilters,
          selectedKeys,
          setSelectedKeys,
        }) => {
          const onSearch = async () => {
            confirm();
          };

          const onSearchReset = async () => {
            clearFilters?.();
            confirm({ closeDropdown: false });
          };

          return (
            <Space direction={"vertical"} style={{ padding: token.paddingXS }}>
              <Input
                value={selectedKeys[0]}
                placeholder="Введите значение"
                size="small"
                onChange={(e) => {
                  setSelectedKeys(
                    e.target.value ? [e.target.value, "searchMode"] : [],
                  );
                }}
                onPressEnter={() => onSearch()}
              />

              <Flex justify={"space-between"}>
                <Button
                  size={"small"}
                  onClick={onSearchReset}
                  type={"link"}
                  disabled={!selectedKeys[0]}
                >
                  Сбросить
                </Button>
                <Button size={"small"} type={"primary"} onClick={onSearch}>
                  ОК
                </Button>
              </Flex>
            </Space>
          );
        };
        column.onFilter = (value) => {
          return value
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase());
        };
      }
    }
  });

  const request: typeof props.request = async (params, sort, filter) => {
    Object.entries(filter).forEach(([key, value]) => {
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        value[1] === "searchMode"
      ) {
        //@ts-ignore
        filter[key] = value[0];
      }
    });

    // --------------------------------  It's crunch -------------------------------- //
    if (sort && Object.keys(sort).length) {
      if (
        sort[Object.keys(sort)[0]] !== "descend" &&
        sort[Object.keys(sort)[0]] !== "ascend"
      ) {
        sort = {};
      }
      const column = props.columns?.find((column) => {
        return column.dataIndex === Object.keys(sort)[0];
      });
      if (column && typeof column.sorter === "string") {
        sort[column.sorter] = sort[Object.keys(sort)[0]];
        delete sort[Object.keys(sort)[0]];
      }
    }
    // ---------------------------------------------------------------- //

    if (hasQueryParams) {
      const filterUrl: Record<string, any> = {};
      for (const key in filter) {
        if (filter[key]) {
          filterUrl[key] = filter[key];
        }
      }
      setTableConfigURL({ filter: filterUrl, sort });
    }

    if (!props.request) throw new Error("request is undefined");
    return props.request(params, sort, filter);
  };

  props = deepmergeProTableProps(defaultProps, props as any, overrideProps);

  return (
    <AntProTable<DataType, Params, ValueType>
      {...(props as any)}
      request={props.request ? request : undefined}
    />
  );
}

export default ProTable;
export type { ProTableProps };
