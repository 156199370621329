import React from "react";
import { Button, message } from "antd";
import axios from "@/axios.ts";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest.tsx";
import { Export } from "@/entities/export/lib/model.ts";
import fileDownload from "js-file-download";

type ExportButtonProps = {
  metaFields?: Export["meta_fields"];
  type: Export["type"];
} & {
  trigger?: JSX.Element;
};

const ExportButton: React.FC<ExportButtonProps> = ({
  metaFields,
  type,
  trigger = <Button type={"primary"}>Экспорт</Button>,
}) => {
  const entityExport = async () => {
    message.info("Экспорт начался, ожидайте");

    let attempts = 120;

    let exportRecord = await axios
      .post<OrionRestCreateResponse<Export>>("/api/exports", {
        meta_fields: metaFields,
        status: "to_process",
        type,
      })
      .then((res) => res.data.data);
    while (exportRecord.status !== "processed" && attempts--) {
      exportRecord = await axios
        .get<OrionRestShowResponse<Export>>(
          `/api/exports/${exportRecord.id}?include=content`,
        )
        .then((res) => res.data.data);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (exportRecord.status === "processed") {
      let url = exportRecord.content?.[0].url!;
      message.success("Экспорт завершился");

      axios
        .get(url, {
          responseType: "blob",
          authorization: false,
        })
        .then((res) => {
          fileDownload(res.data, url.split("?")[0].split("/").slice(-1)[0]);
        });

      return Promise.resolve(false);
    } else {
      message.error("Ошибка при экпортировании");
      return Promise.resolve(false);
    }
  };

  return <>{React.cloneElement(trigger, { onClick: entityExport })}</>;
};

export default ExportButton;
