import React from "react";
import {
  ProForm,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Course } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { message } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useFeatures from "@/entities/features/lib/use.ts";

type CourseFormProps = ProFormProps<Course> & {
  rest: RestProps<Course>;
};

const CourseForm: React.FC<CourseFormProps> = ({
  rest,
  ...props
}) => {
  const features = useFeatures();
  const [form] = ProForm.useForm<Course>(props.form);

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
    });
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Course>>("/api/courses", values)
        .then((res) => {
          message.success("Курс успешно создан");
          rest.onAfterCreate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании курса",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Course>>(`/api/courses/${rest.recordKey}`)
        .then((res) => res.data.data);
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<Course>>(
          `/api/courses/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Курс успешно обновлён");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении курса: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <ProForm<Course> {...props}>
      <ProFormText
        name="name"
        label="Название"
        rules={[{ required: true, max: 255 }]}
      />
      {features.isEnabled("courses_keys") && (
        <ProFormText
          name="key"
          label="Идентификатор"
          rules={[
            { max: 100 },
            {
              pattern: /^[^\s]+$/,
              message: "Идентификатор не должен содержать пробелы",
            },
          ]}
        />
      )}
      <ProFormTextArea name="description" label="Описание" />
    </ProForm>
  );
};

export default CourseForm;
export type { CourseFormProps };
