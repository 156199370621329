import React from "react";
import { Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import GroupTable from "@/entities/group/ui/table";
import { Link, useNavigate } from "react-router-dom";

const Page: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <GroupTable
        hasQueryParams
        columns={[
          {
            dataIndex: "name",
            render: (_, { id: group_id, name }) => {
              return <Link to={`/manage/groups/${group_id}`}>{name}</Link>;
            },
            width: 200,
            ellipsis: true,
          },
          { dataIndex: "description" },
          { dataIndex: "participants_count", width: 150, align: "center" },
        ]}
        rest={{
          onAfterCreate: (group) => {
            navigate(`/manage/groups/${group.id}`);
          },
        }}
      />
    </Space>
  );
};

export default Page;
