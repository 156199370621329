import React, { useEffect } from "react";
import useVersion from "@/entities/version/lib/use.tsx";
import useMode from "@/entities/mode/lib/use.tsx";

const LayoutInfo: React.FC = () => {
  const version = useVersion();
  const mode = useMode();

  useEffect(() => {
    console.log(
      `%cДобро пожаловать в Синица!\n%cВерсия: ${version}\nТип: ${mode}`,
      "font-size: 16px;",
      "font-size: 10px;",
    );
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 3,
        right: 3,
        fontSize: 10,
        opacity: 0.2,
        zIndex: 9999,
      }}
    >
      {version} - {mode}
    </div>
  );
};

export default LayoutInfo;
