import React, { useEffect, useState } from "react";
import ThemeContext, { ThemeValue } from "@/shared/theme/lib/context.tsx";

type ThemeContextProviderProps = {
  children: React.ReactNode;
};

const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({
  children,
}) => {
  const [theme, _setTheme] = useState<ThemeValue>("light");

  const setTheme = (themeAlgorithm?: ThemeValue): void => {
    if (!themeAlgorithm) {
      themeAlgorithm = localStorage.getItem("theme") as "light" | "dark";
    }

    if (!themeAlgorithm) {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      themeAlgorithm = darkThemeMq.matches ? "dark" : "light";
    }

    if (!["light", "dark"].includes(themeAlgorithm)) {
      throw new Error("Invalid theme algorithm");
    }
    _setTheme(themeAlgorithm);
    localStorage.setItem("theme", themeAlgorithm);
  };

  useEffect(() => {
    localStorage.removeItem("themeAlgorithm");
    setTheme();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        value: theme,
        set: setTheme,
        switch: () => setTheme(theme === "light" ? "dark" : "light"),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
export type { ThemeContextProviderProps };
