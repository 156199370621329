import React, { useState } from "react";
import { Button, Divider, Input, Space, message } from "antd";
import useSWR from "swr";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { Position } from "@/models";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import useSpace from "@/entities/space/lib/use";
import Spin from "@/shared/components/spin";

type MemberPositionInputProps = ProFormSelectProps & {
  hideAddInput?: boolean;
  whereHaveInLearningMatrix?: boolean;
};

const MemberPositionInput: React.FC<MemberPositionInputProps> = ({
  hideAddInput = false,
  whereHaveInLearningMatrix = false,
  ...props
}) => {
  const space = useSpace();
  const { data, isLoading, mutate } = useSWR(
    "/api/positions/search",
    async (url) => {
      let page = 1;
      let onPage = 50;
      const limit = 50;

      const positions = [] as Position[];

      while (onPage === limit) {
        const res = await axios
          .post<OrionRestCreateResponse<Position[]>>(
            url,
            {},
            {
              params: {
                page,
                limit,
              },
            },
          )
          .then((res) => res.data.data)
          .catch((error) => {
            message.error(
              error.response.data.message ?? "Ошибка получения должностей",
            );
          });
        page++;
        onPage = res!.length;
        positions.push(...res!);
      }

      return positions;
    },
  );

  const [name, setName] = useState("");

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = async () => {
    await axios
      .post<OrionRestUpdateResponse<Position>>(`/api/positions`, {
        name,
        space_id: space.id,
      })
      .then(async (res) => {
        await mutate();
        return res.data.data;
      })
      .catch((error) => {
        message.error(
          error.response.data.message ?? "Ошибка добавления должности",
        );
      });

    setName("");
  };

  if (isLoading) return <Spin />;

  return (
    <ProFormSelect<Position>
      options={data?.map((value) => ({
        value: value.id,
        label: value.name,
      }))}
      rules={[{ required: false }]}
      showSearch
      fieldProps={{
        dropdownRender: (menu) => (
          <>
            {menu}
            {!hideAddInput && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Введите значение"
                    value={name}
                    required
                    minLength={3}
                    maxLength={255}
                    onChange={onNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => addItem()}
                    disabled={!name}
                  >
                    Добавить должность
                  </Button>
                </Space>
              </>
            )}
          </>
        ),
      }}
      {...props}
    />
  );
};

export default MemberPositionInput;
