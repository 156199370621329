import React, { useState } from "react";
import { Dashboard, Member } from "@/models.ts";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { Link } from "react-router-dom";
import { Card, Col, Space, Typography } from "antd";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { ProForm } from "@ant-design/pro-components";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";

export const metadata: IndicatorContentMetadata = {
  name: "latest_added_members",
  title: "Последние добавленные сотрудники",
  position: 5,
};

const IndicatorContentLatestAddedMembers: React.FC = () => {
  const [error, setError] = useState<Error | null>(null);
  const form = ProForm.useFormInstance<Dashboard>();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );

  const request: ProTableProps<Member, any>["request"] = async () => {
    return axios
      .post<OrionRestIndexResponse<Member>>(
        "/api/members/search",
        {
          sort: [{ field: "created_at", direction: "desc" }],
          includes: [
            { relation: "org_structure_assignments" },
            { relation: "org_structure_assignments.position" },
            { relation: "org_structure_assignments.department" },
          ],
        },
        { params: { limit: 10 } },
      )
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  if (error) throw error;

  return (
    <>
      {is_visible && (
        <Col span={24}>
          <Card title={metadata.title} bodyStyle={{ padding: 8 }}>
            <ProTable<Member>
              rowKey={"id"}
              search={false}
              toolBarRender={false}
              request={request}
              pagination={false}
              style={{ width: "100%" }}
              size={"small"}
              columns={[
                {
                  title: "ФИО",
                  dataIndex: "full_name",
                  render: (text, record) => (
                    <Link to={`/manage/members/${record.id}`}>{text}</Link>
                  ),
                },
                {
                  title: "Подразделение",
                  dataIndex: ["org_structure_assignments", "department_id"],
                  render: (_, { org_structure_assignments }) => {
                    return (
                      <Space direction="vertical">
                        {org_structure_assignments?.map((el) => (
                          <Typography.Text key={`${el.id}-department`}>
                            {el.department?.name ? el.department?.name : "-"}
                          </Typography.Text>
                        ))}
                      </Space>
                    );
                  },
                },
                {
                  title: "Должность",
                  dataIndex: ["org_structure_assignments", "position_id"],
                  render: (_, { org_structure_assignments }) => {
                    return (
                      <Space direction="vertical">
                        {org_structure_assignments?.map((el) => (
                          <Typography.Text key={`${el.id}-position`}>
                            {el.position?.name ? el.position?.name : "-"}
                          </Typography.Text>
                        ))}
                      </Space>
                    );
                  },
                },
                {
                  title: "Дата добавления",
                  dataIndex: "created_at",
                  valueType: "dateTime",
                  fieldProps: { format: dateSTime },
                },
              ]}
            />
          </Card>
        </Col>
      )}
    </>
  );
};

export default IndicatorContentLatestAddedMembers;
