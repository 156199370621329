import React from "react";
import MyEducationList from "@/widgets/my-education/ui/list";
import { useNavigate } from "react-router-dom";
import { Space, Grid } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const Page: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <AutoBreadcrumb />
      <MyEducationList
        onItem={(record) => ({
          onClick: () => navigate(`/education/${record.id}`),
        })}
      />
    </Space>
  );
};

export default Page;
