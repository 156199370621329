import React, { useEffect } from "react";
import { Button } from "antd";
import PoolParticipantCreateForm, {
  PoolParticipantCreateFormProps,
} from "@/entities/pool-participant/ui/form-create";
import { ModalForm, ModalFormProps } from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import { ButtonProps } from "antd/es/button";
import { useSearchParams } from "react-router-dom";

type PoolParticipantModalFormCreateProps = ModalFormProps & {
  formProps?: PoolParticipantCreateFormProps;
  triggerProps?: ButtonProps;
};

const PoolParticipantModalFormCreate: React.FC<
  PoolParticipantModalFormCreateProps
> = ({ formProps, triggerProps, ...props }) => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (urlParams.get("pool_participant_create") !== null) {
      setOpen(urlParams.get("pool_participant_create") === "true");
      urlParams.delete("pool_participant_create");
      setUrlParams(urlParams);
    }
  }, []);

  const defaultProps: PoolParticipantModalFormCreateProps = {
    open,
    modalProps: { destroyOnClose: true, onCancel: () => setOpen(false) },
    title: "Добавление участника в поток",
    trigger: (
      <Button
        {...deepmerge(
          {
            type: "primary",
            onClick: () => setOpen(true),
          },
          triggerProps ?? {},
        )}
      >
        Добавить в поток
      </Button>
    ),
    component: () => (
      <PoolParticipantCreateForm
        {...formProps}
        afterCreate={(pool) => {
          setOpen(false);
          formProps?.afterCreate?.(pool);
        }}
      />
    ),
  };

  props = deepmerge(defaultProps, props);

  return <ModalForm {...props} />;
};

export default PoolParticipantModalFormCreate;
export type { PoolParticipantModalFormCreateProps };
