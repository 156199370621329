import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Section } from "@/models";
import { Typography } from "antd";

type SectionDescriptionsProps = ProDescriptionsProps<Section>;

const SectionDescriptions: React.FC<SectionDescriptionsProps> = ({
  ...props
}) => {
  return (
    <ProDescriptions<Section> {...props}>
      <ProDescriptions.Item
        dataIndex={"name"}
        span={24}
        render={(content) => (
          <Typography.Title level={5} style={{ margin: 0 }}>
            {content}
          </Typography.Title>
        )}
      />
      <ProDescriptions.Item
        dataIndex={"description"}
        span={24}
        render={(content) => <Typography.Text>{content}</Typography.Text>}
      />
    </ProDescriptions>
  );
};
export default SectionDescriptions;
export type { SectionDescriptionsProps };
