import React from "react";
import useMe from "@/entities/me/lib/use";
import useSWR from "swr";
import axios from "@/axios";
import { Outlet, useParams } from "react-router-dom";
import NotFound from "@/shared/pages/not-found";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { PoolParticipant } from "@/models";

const Context: React.FC = () => {
  const me = useMe();
  const { pool_participant_id } = useParams();
  const {
    data: poolParticipant,
    isLoading,
    error,
  } = useSWR(
    `/api/pool-participants/${pool_participant_id!}`,
    (url) =>
      axios
        .get<OrionRestShowResponse<PoolParticipant>>(url)
        .then((res) => res.data.data),
    {
      refreshInterval: 10000,
    },
  );

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!poolParticipant) throw new Error("Data is undefined");

  if (me.id !== poolParticipant.member_id) {
    return <NotFound />;
  }

  return (
    <Spin
      spinning={poolParticipant.dependencies_status !== "actual"}
      tip={"Ваш Курс был изменен, актуализируем данные..."}
    >
      <Outlet />
    </Spin>
  );
};

export default Context;
