import axios from "@/axios.ts";
import React from "react";
import useSWR from "swr";

interface Props {
  children: React.ReactNode;
}

const Pinger: React.FC<Props> = ({ children }) => {
  useSWR("/api/ping", (url) => axios.post(url), {
    /* Каждые 5 минут пингуем */
    refreshInterval: 5 * 60 * 1000,
  });

  return <>{children}</>;
};

export default Pinger;
