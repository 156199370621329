import React from "react";
import { Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import GroupParticipantTable from "@/entities/group-participant/ui/table";
import { Link, useParams } from "react-router-dom";

const Page: React.FC = () => {
  const { group_id } = useParams();
  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <GroupParticipantTable
        hasQueryParams
        groupId={Number(group_id)}
        columns={[
          {
            dataIndex: ["member", "full_name"],
            render: (node, { member_id }) => (
              <Link to={`/manage/members/${member_id}`}>{node}</Link>
            ),
          },
          {
            dataIndex: ["member", "org_structure_assignments", "department_id"],
          },
          {
            dataIndex: ["member", "org_structure_assignments", "position_id"],
          },
          {
            dataIndex: "created_at",
          },
        ]}
      />
    </Space>
  );
};

export default Page;
