import React from "react";
import { Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import ServiceAccountsTable from "@/entities/service-account/ui/table.tsx";

const Page: React.FC = () => {
  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <ServiceAccountsTable
        hasQueryParams
        columns={[
          { dataIndex: "name" },
          { dataIndex: "type" },
          { dataIndex: "email" },
          { dataIndex: "last_used_at" },
          { dataIndex: "created_at" },
        ]}
      />
    </Space>
  );
};

export default Page;
