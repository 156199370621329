import React from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Course } from "@/models";

type CourseSelectWithSearchProps = Partial<ProFormSelectProps> & {};

const CourseSelectWithSearch: React.FC<CourseSelectWithSearchProps> = ({
  ...selectProps
}) => {
  const request: typeof selectProps.request = async (params) => {
    return axios
      .post<OrionRestIndexResponse<Course>>("/api/courses/search", {
        search: { value: params.keyWords },
      })
      .then((response) => {
        return response.data.data.map((course) => {
          return { label: course.name, value: course.id };
        });
      });
  };

  return (
    <ProFormSelect.SearchSelect
      mode={"single"}
      label={"Курс"}
      request={request}
      {...selectProps}
    />
  );
};

export default CourseSelectWithSearch;
export type { CourseSelectWithSearchProps };
