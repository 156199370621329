import React from "react";
import MeContextProvider from "@/entities/me/lib/context-provider";
import WithAuth from "@/shared/components/with-auth";
import SpaceContextProvider from "@/entities/space/lib/context-provider";
import { Outlet } from "react-router-dom";
import Pinger from "@/pages/space/pinger.tsx";

const Context: React.FC = () => {
  return (
    <SpaceContextProvider>
      <WithAuth type={"required"}>
        <MeContextProvider>
          <Pinger>
            <Outlet />
          </Pinger>
        </MeContextProvider>
      </WithAuth>
    </SpaceContextProvider>
  );
};

export default Context;
