import React from "react";
import { ProForm } from "@ant-design/pro-components";
import { Dashboard } from "@/models.ts";
import IndicatorIntervalSelect from "@/pages/space/manage/dashboards/indicator-interval-select.tsx";
import useSWR from "swr";
import axios from "@/axios.ts";
import { Pie, PieConfig } from "@ant-design/charts";
import { Card, Col, Spin, theme } from "antd";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";

type Data = {
  title: string;
  value: number;
};

export const metadata: IndicatorContentMetadata = {
  name: "education_statistics_by_department",
  title: "Статистика обучения по подразделениям",
  position: 4,
};

const IndicatorContentEducationStatisticsByDepartment: React.FC = () => {
  const form = ProForm.useFormInstance<Dashboard>();
  const { token } = theme.useToken();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );
  const interval = ProForm.useWatch(
    ["settings", metadata.name, "interval"],
    form,
  );

  const { data, isLoading, error } = useSWR(
    interval ? ["/api/dashboards/statistics/education", interval] : null,
    async ([url, interval]) => {
      return axios
        .get<Data[]>(url, {
          params: { interval },
        })
        .then((res) => res.data)
        .then((data) => {
          const totalValue = data.reduce((sum, item) => sum + item.value, 0);
          return data.flatMap((item) => {
            if (item.value === 0) {
              return [];
            }
            const percent = ((item.value / totalValue) * 100).toFixed(2);
            item.title = `${item.title}, ${item.value} п. (${percent}%)`;
            return [item];
          });
        })
        .catch((error) => {
          throw error;
        });
    },
  );

  if (error) throw error;

  const config: PieConfig = {
    data: data ?? [],
    forceFit: true,
    appendPadding: 10,
    angleField: "value",
    colorField: "title",
    innerRadius: 0.5,
    radius: 1,
    height: 250,
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    style: {
      inset: 1,
      radius: 4,
    },
    tooltip: (item) => item.title,
    legend: {
      color: {
        maxCols: 1,
        itemMarker: "circle",
        title: "type",
        tickCount: 10,
        position: "right",
        itemLabelFill: token.colorText,
        rowPadding: 15,
        layout: {
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      },
    },
  };

  return (
    <>
      {is_visible && (
        <Col span={12}>
          <Card
            title={metadata.title}
            extra={
              <IndicatorIntervalSelect
                name={["settings", metadata.name, "interval"]}
              />
            }
          >
            <Spin spinning={isLoading}>
              <Pie style={{ width: "100%" }} {...config} />
            </Spin>
          </Card>
        </Col>
      )}
    </>
  );
};

export default IndicatorContentEducationStatisticsByDepartment;
