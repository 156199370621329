import React from "react";
import { Space, Spin, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { useParams } from "react-router-dom";
import AutomationForm from "@/entities/automation/ui/form";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Automation } from "@/models";
import axios from "@/axios";
import useSWR from "swr";

const Page: React.FC = () => {
  const { automation_id } = useParams();
  const { token } = theme.useToken();

  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        direction={"vertical"}
        style={{
          height: "calc(100vh - 64px - 32px - 22px - 8px)",
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
          backgroundColor: token.colorBgContainer,
          padding: token.paddingMD,
          overflow: "auto",
        }}
      >
        <AutomationForm
          rest={{
            type: "update",
            recordKey: Number(automation_id),
          }}
        />
      </Space>
    </Space>
  );
};

export default Page;
