import React from "react";
import useSWR from "swr";
import Spin from "@/shared/components/spin.tsx";
import {
  AuthProvider as BaseAuthProvider,
  AuthProviderProps,
} from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import axios from "@/axios";
import ContextAuthRefresh from "@/pages/context-auth-refresh.tsx";
import ContextAuthCleanUrlParams from "@/pages/context-auth-clean-url-params.tsx";

type OidcAuthContextProps = {
  children: React.ReactNode;
};

export type OIDCConfig = {
  authority: string;
  client_id: string;
};

const ContextAuth: React.FC<OidcAuthContextProps> = ({ children }) => {
  const { data, error, isLoading } = useSWR("/api/.well-known/oidc", (url) =>
    axios.get<OIDCConfig>(url, { authorization: false }).then((res) => res.data),
  );

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("No data");

  const props: AuthProviderProps = {
    ...data,
    redirect_uri: window.location.origin,
    accessTokenExpiringNotificationTimeInSeconds: 20,
    revokeTokensOnSignout: true,
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };

  return (
    <BaseAuthProvider {...props}>
      <ContextAuthRefresh>
        <ContextAuthCleanUrlParams>{children}</ContextAuthCleanUrlParams>
      </ContextAuthRefresh>
    </BaseAuthProvider>
  );
};

export default ContextAuth;
export type { OidcAuthContextProps };
