import React from "react";
import { Course, Member, Pool, PoolParticipant } from "@/models";
import axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import {
  OrionRestIndexResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import PoolParticipantModalFormCreate, {
  PoolParticipantModalFormCreateProps,
} from "@/entities/pool-participant/ui/modal-form-create";
import PoolParticipantRemoveButton, {
  PoolParticipantRemoveButtonProps,
} from "@/entities/pool-participant/ui/button-remove";
import { ActionType } from "@ant-design/pro-table/es/typing";
import PoolParticipantBatchUpdateModalForm, {
  PoolParticipantBatchUpdateModalFormProps,
} from "@/entities/pool-participant/ui/modal-form-update-batch";
import { deepmerge } from "deepmerge-ts";
import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import useSWR from "swr";
import Spin from "@/shared/components/spin";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import ExportButton from "@/entities/export/ui/button";
import ImportButton from "@/entities/import/ui/button";
import statuses from "@/entities/pool-participant/lib/statuses.tsx";
import PoolModalForm from "@/entities/pool/ui/modal-form.tsx";

type Record = Omit<PoolParticipant, "pool" | "member"> & {
  pool: Omit<Pool, "course"> & {
    course: Course;
  };
  member: Member;
};

type Params = Partial<Record>;

type TableProps = ProTableProps<Record, Params>;

type PoolParticipantTableProps = TableProps & {
  poolId?: Pool["id"];
  memberId?: Member["id"];
  toolBarActionsProps?: {
    create?: PoolParticipantModalFormCreateProps;
    batchUpdate?: PoolParticipantBatchUpdateModalFormProps;
    remove?: PoolParticipantRemoveButtonProps;
  };
};

const PoolParticipantTable: React.FC<PoolParticipantTableProps> = ({
  poolId,
  memberId,
  actionRef: actionRefProp,
  ...tableProps
}) => {
  if (typeof actionRefProp === "function") {
    throw new Error(
      "actionRef as function is not supported. Please use useRef instead",
    );
  }
  let actionRef = React.useRef<ActionType | undefined | null>();
  if (actionRefProp) {
    actionRef = actionRefProp;
  }

  const {
    data: pool,
    isLoading,
    error,
    mutate: mutatePool,
  } = useSWR(poolId ? `/api/pools/${poolId}` : null, (url) =>
    axios.get<OrionRestShowResponse<Pool>>(url).then((res) => res.data.data),
  );

  const toolBarRender: TableProps["toolBarRender"] = (
    _,
    { selectedRowKeys },
  ) => [
    <PoolParticipantRemoveButton
      key={"remove"}
      {...deepmerge(
        {
          poolParticipantsIds: selectedRowKeys?.map(Number),
          afterRemove: () => {
            actionRef.current?.clearSelected?.();
            actionRef.current?.reload();
          },
        } as PoolParticipantRemoveButtonProps,
        tableProps?.toolBarActionsProps?.remove ?? {},
      )}
    />,
    <PoolParticipantBatchUpdateModalForm
      key={"batch-update"}
      {...(deepmerge(
        {
          formProps: {
            initialValues: { pool_id: poolId },
            poolParticipantsIds: selectedRowKeys?.map((key) => Number(key)),
            afterUpdate: () => actionRef.current?.reload(),
          },
          triggerProps: { disabled: selectedRowKeys?.length === 0 },
        } as PoolParticipantBatchUpdateModalFormProps,
        tableProps?.toolBarActionsProps?.batchUpdate ?? {},
      ) as PoolParticipantBatchUpdateModalFormProps)}
    />,
    poolId && (
      <PoolModalForm
        key={`update`}
        courseId={pool!.course_id}
        rest={{
          type: "update",
          recordKey: poolId,
          onAfterUpdate: () => {
            actionRef.current?.reload();
          },
        }}
      />
    ),
    poolId && pool!.is_generates_documents && (
      <Dropdown
        menu={{
          items: [
            {
              label: (
                <ExportButton
                  metaFields={{
                    pool_id: poolId,
                  }}
                  type="export_command"
                  trigger={<a>Выгрузить Приказ</a>}
                />
              ),
              key: "export-command",
            },
            {
              label: (
                <ExportButton
                  metaFields={{
                    pool_id: poolId,
                  }}
                  type="export_protocol"
                  trigger={<a>Выгрузить Протокол</a>}
                />
              ),
              key: "export-protocol",
            },
            {
              label: (
                <ExportButton
                  metaFields={{
                    pool_id: poolId,
                  }}
                  type="export_for_registry"
                  trigger={<a>Выгрузить для Реестра</a>}
                />
              ),
              key: "export-for-registry",
            },
            {
              label: (
                <ImportButton
                  type="registry"
                  poolId={poolId}
                  onAfterFinish={() => actionRef.current?.reload()}
                  trigger={<a>Загрузить из Реестра</a>}
                />
              ),
              key: "import-from-registry",
            },
          ],
        }}
      >
        <Button>
          <Space>
            Документы
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ),
    <PoolParticipantModalFormCreate
      key={"create"}
      {...deepmerge(
        {
          formProps: {
            fieldsProps: { poolId: { hidden: true } },
            initialValues: { pool_id: poolId },
            afterCreate: () => actionRef.current?.reload(),
          },
        },
        tableProps?.toolBarActionsProps?.create ?? {},
      )}
    />,
  ];

  const defaultTableProps: TableProps = {
    rowKey: "id",
    toolBarRender,
    pagination: {
      showSizeChanger: true,
    },
    rowSelection: {},
    options: false,
    search: false,
    request: async (params, sort, filter) => {
      await mutatePool();

      if (sort === undefined || Object.keys(sort).length === 0) {
        sort = { created_at: "descend" };
      }

      const config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/pool-participants/search",
        ...axiosConfigAdapter(params, sort, filter),
      };

      config.data.includes = [
        { relation: "member" },
        { relation: "pool" },
        { relation: "pool.course" },
      ];

      if (poolId) {
        config.data.filters.push({
          field: "pool_id",
          operator: "=",
          value: poolId,
        });
      }

      if (memberId) {
        config.data.filters.push({
          field: "member_id",
          operator: "=",
          value: memberId,
        });
      }

      const data = await axios
        .request<OrionRestIndexResponse<Record>>(config)
        .then((res) => res.data);

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    columns: [
      {
        title: "Курс",
        dataIndex: ["pool", "course", "id"],
        renderText: (_, record) => {
          return <>{record.pool.course.name}</>;
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/courses/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Course>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Поток",
        dataIndex: ["pool", "id"],
        renderText: (_, record) => {
          return <>{record.pool.name}</>;
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/pools/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Pool>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((pool) => ({
                  label: pool.name,
                  value: pool.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        filters: true,
        filterMode: "search",
        sorter: true,
      },
      {
        title: "Номер в реестре",
        dataIndex: "registry_number",
        filters: true,
        filterMode: "search",
        sorter: true,
        hideInTable: !(pool?.is_generates_documents ?? true),
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: statuses,
        filters: true,
      },
      {
        title: "Прогресс прохождения",
        dataIndex: "progress_percent",
        render: (_, { progress_percent: percent }) =>
          `${Number(percent).toFixed(2)}%`,
        sorter: true,
      },
      {
        title: "Планируемая дата начала",
        dataIndex: "starts_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: "Фактическая дата начала",
        dataIndex: "started_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: "Планируемая дата завершения",
        dataIndex: "ends_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: "Фактическая дата завершения",
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
    ],
  };

  const overrideProps: TableProps = {
    actionRef,
  };

  tableProps = {
    ...deepmergeProTableProps(defaultTableProps as any, tableProps as any),
    ...overrideProps,
  };

  if (poolId && isLoading) return <Spin />;
  if (poolId && error) throw error;
  if (poolId && !pool) throw new Error("Pool not found");

  return <ProTable<Record, Params> {...tableProps} />;
};
export default PoolParticipantTable;
export type { PoolParticipantTableProps };
