import React, { useRef } from "react";
import { Course } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { ActionType } from "@ant-design/pro-table/es/typing";
import { AxiosRequestConfig } from "axios";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import CourseDeleteButton from "@/entities/course/ui/delete-button";
import { RestCallbacks } from "@/shared/rest/lib/types";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { Button, Dropdown } from "antd";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { MenuProps } from "antd/lib";
import useImportModal from "@/entities/import/hooks/use-import-modal";
import { EllipsisOutlined } from "@ant-design/icons";
import useFeatures from "@/entities/features/lib/use.ts";
import CourseModalForm from "@/entities/course/ui/modal-form.tsx";

type Data = Course;

type Params = Partial<Course>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<Course>;
};

const CoursesTable: React.FC<Props> = ({ rest, ...props }) => {
  const features = useFeatures();
  const action = useRef<ActionType>();

  const [registryImportModal, registryImportModalHolder] = useImportModal({
    type: "registry",
    massiveImports: true,
    trigger: <></>,
    onAfterFinish: () => action.current?.reload(),
  });

  const [courseImportModal, courseImportModalHolder] = useImportModal({
    type: "course",
    trigger: <></>,
    onAfterFinish: () => action.current?.reload(),
  });

  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/courses/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const data = await axios
      .request<OrionRestIndexResponse<Course>>(actionConfig)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const items: MenuProps["items"] = [
    {
      label: "Импорт курса",
      key: "course",
      onClick: () => {
        courseImportModal.open();
      },
    },
    {
      label: "Импорт номеров из реестра",
      key: "registry",
      onClick: () => {
        registryImportModal.open();
      },
    },
  ];

  const toolBarRender: TableProps["toolBarRender"] = (
    _,
    { selectedRowKeys },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");

    return [
      <CourseDeleteButton
        key={"delete"}
        recordKeys={selectedRowKeys as number[]}
        afterRemove={() => {
          action.current?.reload();
          action.current?.clearSelected?.();
          rest?.onAfterDelete?.();
        }}
      />,
      <CourseModalForm
        key={`update-${selectedRowKeys.join("-")}`}
        rest={{
          type: "update",
          recordKey: selectedRowKeys,
          onAfterUpdate: (record) => {
            action.current?.reload();
            rest?.onAfterUpdate?.(record);
          },
        }}
      />,
      <Dropdown
        key={"more"}
        placement={"bottom"}
        trigger={["click"]}
        menu={{ items }}
      >
        <Button type={"primary"} icon={<EllipsisOutlined />} />
      </Dropdown>,
      <CourseModalForm
        key={"create"}
        rest={{
          type: "create",
          onAfterCreate: (record) => {
            action.current?.reload();
            rest?.onAfterCreate?.(record);
          },
        }}
      />,
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {},
    options: false,
    pagination: { showSizeChanger: true },
    toolBarRender,
    request,
    actionRef: action,
    search: false,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        filters: true,
        filterMode: "search",
      },
      {
        title: "Идентификатор",
        dataIndex: "key",
        filters: true,
        filterMode: "search",
        hideInTable: !features.isEnabled("courses_keys"),
      },
      {
        title: "Описание",
        dataIndex: "description",
      },
      {
        title: "Время создания",
        dataIndex: "created_at",
        valueType: "dateTime",
        sorter: true,
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
      },
    ],
  };

  return (
    <>
      {courseImportModalHolder}
      {registryImportModalHolder}
      <ProTable<Data, Params>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </>
  );
};

export default CoursesTable;
