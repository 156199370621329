import React, { useEffect, useState } from "react";
import { File, Lesson } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
  ProFormUploadButton,
  ProFormUploadDragger,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { FilePreviewProps } from "@/entities/file/ui/preview";
import uploadRequest from "@/entities/file/lib/upload-request";
import {
  Alert,
  Flex,
  message,
  Modal,
  Spin,
  Typography,
  UploadFile,
} from "antd";
import { ButtonProps } from "antd/es/button";
import { RestProps } from "@/shared/rest/lib/types";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import fileDownload from "js-file-download";
import FilePreviewModal from "@/entities/file/ui/preview-modal.tsx";

type FormData = Omit<Lesson, "content_file" | "attachment_files"> & {
  content_files: (File & UploadFile)[] | null;
  attachment_files?: (File & UploadFile)[] | null;
};

type Props = ProFormProps<FormData> & {
  rest: RestProps<Lesson>;
  onDelete?: () => void;
};

const LessonForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = ProForm.useForm<FormData>();
  const [preview, setPreview] = useState<FilePreviewProps | undefined>(
    undefined,
  );
  const [toRemoveFileIds, setToRemoveFileIds] = useState<number[]>([]);
  const [modal, modalHolder] = Modal.useModal();

  let defaultProps: Partial<typeof props> = {
    submitter: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  const syncFiles = async (formData: FormData) => {
    const resources = (formData.attachment_files ?? [])
      .filter((file) => !file.id)
      .map((file) => ({
        key: file.response.key,
        name: file.name,
        type: file.type,
        attached_to_id: formData.id,
        attached_to_type: "lesson",
      }));

    if (resources.length > 0) {
      await axios
        .post<OrionRestBatchCreateResponse<File>>("/api/files/batch", {
          resources,
        })
        .then((res) => res.data.data);
    }

    if (formData.content_files) {
      if (formData.content_files.length > 1) {
        throw new Error("Only one content file is allowed");
      }

      if (formData.content_files.length === 1) {
        const contentFile = formData.content_files[0];

        if (!contentFile.id) {
          await axios
            .post<OrionRestCreateResponse<File>>(`/api/files`, {
              key: contentFile.response.key,
              name: contentFile.name,
              type: contentFile.type,
              attached_to_id: formData.id,
              attached_to_type: "lesson",
            })
            .then((res) => res.data.data)
            .then((file) => {
              return axios.put<OrionRestUpdateResponse<Lesson>>(
                `/api/lessons/${formData.id}`,
                { content_file_id: file.id },
              );
            });
        }
      }
    }

    if (toRemoveFileIds.length > 0) {
      await axios
        .delete("/api/files/batch", {
          data: { resources: toRemoveFileIds },
        })
        .then(() => {
          setToRemoveFileIds([]);
        });
    }
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    const request = async () => {
      return axios
        .get<OrionRestShowResponse<Lesson>>(`/api/lessons/${rest.recordKey}`)
        .then((res) => res.data.data)
        .then((data) => {
          return {
            ...data,
            attachment_files: (data.attachment_files ?? []).map((file) => ({
              ...file,
              status: "done",
            })),
            content_files: data.content_file ? [data.content_file] : [],
          } as FormData;
        });
    };

    defaultProps.request = async () => {
      setIsLoading(true);
      return request().finally(() => setIsLoading(false));
    };

    defaultProps.onFinish = async (values) => {
      setIsLoading(true);
      return axios
        .put<OrionRestUpdateResponse<Lesson>>(
          `/api/lessons/${rest.recordKey}`,
          values,
        )
        .then(async (res) => res.data.data)
        .then(async (lesson) => {
          await syncFiles(values);
          rest.onAfterUpdate?.(lesson);
          await request().then((res) => form.setFieldsValue(res as any));
          message.success("Урок успешно обновлен");
          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении урока: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const onPreview = async (file: any) => {
    setPreview(
      file.originFileObj
        ? {
            file: {
              type: file.type,
              url: URL.createObjectURL(file.originFileObj),
            },
          }
        : { file },
    );
  };

  const onRemove = (file: any) => {
    if (file.id) setToRemoveFileIds((prev) => [...prev, file.id]);
  };

  const onDelete: ButtonProps["onClick"] = async () => {
    return modal.warning({
      title: "Удалить текущий урок?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await axios.delete(`/api/lessons/${props.id}`);
        await onDeleteProp?.();
        message.success("Урок успешно удалён");
      },
    });
  };

  const content_files = ProForm.useWatch("content_files", form);
  const [isContentFilesContainsVideo, setIsContentFilesContainsVideo] =
    useState(false);
  useEffect(() => {
    setIsContentFilesContainsVideo(
      content_files?.some((file) => file.type.split("/")[0] === "video") ??
        false,
    );
  }, [content_files]);

  return (
    <Spin spinning={isLoading}>
      {modalHolder}
      <ProForm<FormData> {...props}>
        <ProFormDigit name="id" label="ID" hidden />
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          <Button danger type={"primary"} onClick={onDelete}>
            Удалить
          </Button>
          <Button type={"primary"} onClick={form.submit}>
            Сохранить
          </Button>
        </Flex>
        <ProFormDigit name="course_id" label="ID курса" hidden />
        <ProFormText
          name="name"
          label="Название"
          rules={[{ max: 255, required: true }]}
        />
        <ProFormTextArea name="description" label="Описание" />
        <ProFormUploadDragger
          name="content_files"
          label="Содержание"
          title={"Загрузите файл"}
          description={"Перетащите файл в эту область или нажмите на нее"}
          rules={[
            { required: true },
            () => ({
              validator(_, files) {
                const emptyFileExtension = files[0].type === "";
                if (emptyFileExtension) {
                  return Promise.reject(
                    new Error("Прикрепите файл с указанным расширением"),
                  );
                }
                if (files[0].name.length > 255) {
                  return Promise.reject(
                    new Error("Слишком длинное название файла"),
                  );
                }
                const isVideo = files[0].type.split("/")[0] === "video";
                const isPdf = files[0].type === "application/pdf";
                const isVideoWebm = files[0].type === "video/webm";
                if (isVideoWebm) {
                  return Promise.reject(
                    new Error(
                      "Недопустимый тип формат видео: Нельзя загружать файлы в формате WebM. Пожалуйста, выберите другой формат видео.",
                    ),
                  );
                }
                if (isVideo || isPdf) return Promise.resolve();
                return Promise.reject(
                  new Error(
                    "Недопустимый тип файла: Можно загружать только видео или PDF",
                  ),
                );
              },
            }),
          ]}
          max={1}
          fieldProps={{
            listType: "picture",
            customRequest: uploadRequest,
            onPreview,
            onRemove,
            accept: ".pdf, video/*",
          }}
        />
        {isContentFilesContainsVideo && (
          <Alert
            message={
              <Typography.Text>
                <Typography.Text>
                  Рекомендуется загружать видео в формате MP4 с кодеком H.264.
                </Typography.Text>
                <br />
                <Typography.Text type={"secondary"}>
                  Так как не все браузеры поддерживают просмотр видео в любом
                  формате и кодеке.
                  <br />
                  MP4 с кодеком H.264 - поддерживается большинством браузеров.
                </Typography.Text>
              </Typography.Text>
            }
            type={"warning"}
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}
        <ProFormUploadButton
          name="attachment_files"
          label="Прикреплённые файлы"
          title={"Загрузить"}
          max={5}
          rules={[{ required: false }, { type: "array", max: 5 }]}
          fieldProps={{
            listType: "picture-card",
            multiple: true,
            onRemove: onRemove,
            customRequest: uploadRequest,
            onDownload: (file) => {
              axios
                .get(file.url!, {
                  authorization: false,
                  responseType: "blob",
                })
                .then((response) => {
                  fileDownload(response.data, file.name);
                })
                .catch(() => {
                  message.error("Ошибка загрузки файла");
                });
            },
            showUploadList: {
              showPreviewIcon: false,
              showRemoveIcon: true,
              showDownloadIcon: true,
            },
          }}
        />
      </ProForm>
      <FilePreviewModal
        open={preview !== undefined}
        onCancel={() => setPreview(undefined)}
        previewProps={preview!}
      />
    </Spin>
  );
};

export default LessonForm;
