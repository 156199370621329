import React, { useEffect } from "react";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Scorm } from "@/models";
import { Button, Flex, Grid, message, Result, Typography } from "antd";
import Spin from "@/shared/components/spin";
import useSWR from "swr";
import axios from "@/axios";
import "scorm-again";
import styled from "styled-components";
import {
  FullScreen as BaseFullScreen,
  useFullScreenHandle,
} from "react-full-screen";

type BaseScormDescriptionsProps = {
  dataSource: Scorm;
};
type ScormDescriptionsProps = BaseScormDescriptionsProps & {
  onAfterSuccessFinish?: () => void;
  poolParticipantId: string;
};

declare global {
  interface Window {
    API?: any;
    API_1484_11?: any;
  }
}
const StyledButton = styled(Button)`
  opacity: 0.5;
  position: absolute;
  top: 95%;
  left: 95%;
  transform: translate(-50%, -50%);

  &:hover {
    opacity: 1;
  }
`;

const FullScreen = styled(BaseFullScreen)`
  height: 100%;
`;

const { useBreakpoint } = Grid;
const ScormDescriptions: React.FC<ScormDescriptionsProps> = ({
  dataSource: scorm,
  ...props
}) => {
  const {
    data: manifest,
    error,
    isLoading,
  } = useSWR(`${scorm.url}/imsmanifest.xml`, async (url) => {
    return axios
      .get(url, { authorization: false })
      .then(({ data }) => new DOMParser().parseFromString(data, "text/xml"));
  });

  const screen = useFullScreenHandle();
  const screens = useBreakpoint();

  const [isCompletedStatus, setIsCompletedStatus] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (isCompletedStatus) {
      message.success("Вы успешно завершили SCORM");
      props.onAfterSuccessFinish?.();
    }
  }, [isCompletedStatus]);

  useEffect(() => {
    const settings = {};

    const scormsKey = `scorms:${scorm!.url!}/pool-participant:${
      props.poolParticipantId
    }`;

    if (manifest) {
      const scormVersion =
        manifest.getElementsByTagName("schemaversion")[0]?.textContent;

      const suspendData = localStorage.getItem(scormsKey);

      if (scormVersion === "1.2") {
        //@ts-ignore
        window.API = new Scorm12API(settings);
        window.API.on("LMSSetValue.cmi.suspend_data", () => {
          localStorage.setItem(scormsKey, window.API.cmi.suspend_data);
        });

        if (suspendData) {
          window.API.loadFromJSON(
            {
              cmi: {
                suspend_data: suspendData,
              },
            },
            "",
          );
        }

        window.API.on("LMSSetValue.cmi.lesson_status", () => {
          const isCompleted = window.API.cmi.lesson_status === "completed";
          if (isCompleted && !isCompletedStatus) {
            setIsCompletedStatus(true);
          }
        });
      } else {
        //@ts-ignore
        window.API_1484_11 = new Scorm2004API(settings);

        window.API_1484_11.on("SetValue.cmi.suspend_data", () => {
          localStorage.setItem(scormsKey, window.API_1484_11.cmi.suspend_data);
        });

        if (suspendData) {
          window.API_1484_11.loadFromJSON(
            {
              cmi: {
                suspend_data: suspendData,
              },
            },
            "",
          );
        }

        window.API_1484_11.on("SetValue.cmi.completion_status", () => {
          const isCompleted =
            window.API_1484_11.cmi.completion_status === "completed";
          if (isCompleted && !isCompletedStatus) {
            setIsCompletedStatus(true);
          }
        });
      }
    }

    return () => {
      if (window.API) {
        window.API = null;
      }
      if (window.API_1484_11) {
        window.API_1484_11 = null;
      }
    };
  }, [manifest]);

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!manifest) throw new Error("Не удалось загрузить содержимое пакета");

  switch (scorm.status) {
    case "pending":
      return (
        <Result
          status={"warning"}
          title={"Пакет еще не загружен"}
          subTitle={"Пожалуйста, обратитесь к администратору"}
        />
      );
    case "active":
      break;
    default:
      return (
        <Result
          status={"error"}
          title={"Пакет не активен"}
          subTitle={"Пожалуйста, дождитесь активации пакета"}
        />
      );
  }

  const href = manifest
    .getElementsByTagName("resource")[0]
    .getAttribute("href");

  return (
    <Flex
      vertical
      gap={8}
      style={{
        height: `calc(100vh - 64px - 16px * 2 - 22px - 8px - 16px * 2 - 32px - 8px ${
          !screens.lg ? "- 32px - 8px" : ""
        })`,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {scorm.name}
      </Typography.Title>

      <Typography.Text>{scorm.description}</Typography.Text>

      <div
        style={{
          width: "100%",
          flex: "auto",
          position: "relative",
        }}
      >
        <StyledButton
          icon={<FullscreenOutlined />}
          type="text"
          onClick={screen.enter}
        />
        <FullScreen handle={screen}>
          <StyledButton
            icon={<FullscreenExitOutlined />}
            type="text"
            onClick={screen.exit}
            style={{ display: `${!screen.active ? "none" : "block"}` }}
          />
          <iframe
            loading="eager"
            src={`${scorm.url}/${href}`}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            sandbox={"allow-scripts allow-same-origin allow-popups allow-forms"}
          />
        </FullScreen>
      </div>
    </Flex>
  );
};

export default ScormDescriptions;
export type { ScormDescriptionsProps };
