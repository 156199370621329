import React from "react";

const themes = ["light", "dark"];

type ThemeValue = (typeof themes)[number];

const ThemeContext = React.createContext<{
  value: ThemeValue;
  set: (theme: ThemeValue) => void;
  switch: () => void;
}>(undefined as any);

export default ThemeContext;
export type { ThemeValue };
