import React from "react";
import { Button, Layout as AntLayout, theme } from "antd";
import Menu from "./menu";
import { LogoutOutlined, MoonOutlined, SunOutlined } from "@ant-design/icons";
import styled from "styled-components";
import type { GlobalToken } from "antd/es/theme/interface";
import { Outlet } from "react-router-dom";
import AuthButton from "@/shared/components/auth-button.tsx";
import useTheme from "@/shared/theme/lib/use.tsx";

const { Header, Content } = AntLayout;

const HeaderStyled = styled(Header)<{ token: GlobalToken }>`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 ${({ token }) => token.sizeMD}px;
`;

const ContentStyled = styled(Content)<{ token: GlobalToken }>`
  margin: ${({ token }) => token.sizeMD}px;
  min-height: calc(100vh - 64px - ${({ token }) => token.sizeMD * 2}px);
  display: flex;
  align-items: stretch;
`;

const Layout: React.FC = () => {
  /** Styling */

  const { token } = theme.useToken();
  const themeAlgorithm = useTheme();

  /** Render */

  const LogoutButton = () => (
    <AuthButton type={"text"} icon={<LogoutOutlined />}>
      Выйти
    </AuthButton>
  );

  const ThemeSwitch = () => (
    <Button
      type={"text"}
      icon={
        themeAlgorithm.value === "light" ? <MoonOutlined /> : <SunOutlined />
      }
      onClick={() => {
        themeAlgorithm.switch();
      }}
    />
  );

  return (
    <AntLayout>
      <HeaderStyled token={token}>
        <Menu />
        <div>
          <ThemeSwitch />
          <LogoutButton />
        </div>
      </HeaderStyled>
      <ContentStyled token={token}>
        <Outlet />
      </ContentStyled>
    </AntLayout>
  );
};

export default Layout;
