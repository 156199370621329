import React, { useState } from "react";
import { Longread } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormText,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Flex, message, Modal, Spin, theme } from "antd";
import { ButtonProps } from "antd/es/button";
import { RestProps } from "@/shared/rest/lib/types";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import BaseReactQuill from "react-quill";
import styled from "styled-components";
import { GlobalToken } from "antd/es/theme/interface";
import "react-quill/dist/quill.snow.css";

type FormData = Longread & {
  content: string | null;
};

type Props = ProFormProps<FormData> & {
  rest: RestProps<Longread>;
  onDelete?: () => void;
};

type ReactQuillProps = React.ComponentProps<typeof BaseReactQuill>;
const ReactQuill = styled(BaseReactQuill)<
  ReactQuillProps & { token: GlobalToken }
>`
  height: calc(100vh - 342px - 20px - 16px - 16px);
  word-break: break-word;
  @media screen and (max-width: 1224px) {
    height: calc(100vh - 342px - 20px - 16px - 16px - 24px);
  }

  .ql-snow {
    border-color: ${({ token }) => token.colorBorder};
  }

  .ql-toolbar {
    // left top + right top
    border-radius: ${({ token }) =>
      `${token.borderRadius}px ${token.borderRadius}px 0 0`};
  }

  .ql-container {
    // left buttom + right buttom
    border-radius: ${({ token }) =>
      `0 0 ${token.borderRadius}px ${token.borderRadius}px`};
  }
`;

const LongreadForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const { token } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = ProForm.useForm<FormData>();
  const content = ProForm.useWatch("content", form);
  const [modal, modalHolder] = Modal.useModal();

  const defaultProps: Partial<typeof props> = {
    submitter: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      setIsLoading(true);
      return axios
        .get<OrionRestShowResponse<Longread>>(
          `/api/longreads/${rest.recordKey}`,
          { params: { include: "content_file" } },
        )
        .then((res) => res.data.data)
        .then((longread) => {
          if (!longread.content_file) {
            return { ...longread, content: null };
          }

          return axios
            .get(longread.content_file!.url!, { authorization: false })
            .then((res) => {
              return { ...longread, content: res.data };
            });
        })
        .finally(() => setIsLoading(false));
    };

    defaultProps.onFinish = async (values) => {
      setIsLoading(true);
      const { content, ...restValues } = values;
      return axios
        .put<OrionRestUpdateResponse<Longread>>(
          `/api/longreads/${rest.recordKey}`,
          restValues,
        )
        .then(async (res) => res.data.data)
        .then(async (longread) => {
          if (content) {
            const name = `${longread.uuid}.longread`;
            await axios
              .post(`/api/filesystem/s3`, { name })
              .then((res) => res.data.data)
              .then(async (data) => {
                await axios.put(data.url, content, { authorization: false });
                return data.key;
              })
              .then(async (key) => {
                return axios.post(`/api/files`, {
                  name,
                  type: "longread",
                  attached_to_id: longread.id,
                  attached_to_type: "longread",
                  key,
                });
              });
          }

          return longread;
        })
        .then(async (longread) => {
          rest.onAfterUpdate?.(longread);
          message.success("Лонгрид успешно обновлен");
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? `Ошибка при обновлении лонгрида`,
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => setIsLoading(false));
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const onDelete: ButtonProps["onClick"] = async () => {
    return modal.warning({
      title: "Удалить текущий лонгрид?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await axios.delete(`/api/longreads/${props.id}`);
        await onDeleteProp?.();
        message.success("Лонгрид успешно удалён");
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      {modalHolder}
      <ProForm<FormData> {...props}>
        <ProFormDigit name="id" label="ID" hidden />
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          <Button danger type={"primary"} onClick={onDelete}>
            Удалить
          </Button>
          <Button type={"primary"} onClick={form.submit}>
            Сохранить
          </Button>
        </Flex>
        <ProFormDigit name="course_id" label="ID курса" hidden />
        <ProFormText
          name="name"
          label="Название"
          rules={[{ required: true, max: 255 }]}
        />
        <ProFormText name="content" hidden />
        <div data-text-editor="quill_wrapper">
          <ReactQuill
            token={token}
            theme={"snow"}
            bounds={`[data-text-editor="quill_wrapper"]`}
            value={content ? content : undefined}
            onChange={(value) => form.setFieldsValue({ content: value })}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ align: [] }],
                [{ color: [] }, { background: [] }],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "video", "formula"],
                ["code-block"],
                ["clean"],
              ],
            }}
          />
        </div>
      </ProForm>
    </Spin>
  );
};

export default LongreadForm;
