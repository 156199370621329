import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Space, Tabs, TabsProps, theme } from "antd";
import CourseEmbedsTreeEditor from "@/pages/space/manage/courses/[course_id]/embeds-tree-editor";
import PoolTable from "@/entities/pool/ui/table";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import PoolTemplateTable from "@/entities/pool-template/ui/table";

const Page: React.FC = () => {
  const { token } = theme.useToken();
  const { course_id } = useParams();
  const navigate = useNavigate();

  const tabs: TabsProps["items"] = [
    {
      key: "embeds",
      label: "Содержание",
      children: <CourseEmbedsTreeEditor courseId={Number(course_id!)} />,
    },
    {
      key: "pools",
      label: "Потоки",
      children: (
        <PoolTable
          courseId={Number(course_id)}
          hasQueryParams
          columns={[
            {
              dataIndex: "name",
              render: (dom, { course_id, id: pool_id }) => {
                return (
                  <Link to={`/manage/courses/${course_id}/pools/${pool_id}`}>
                    {dom}
                  </Link>
                );
              },
            },
            { dataIndex: "key" },
            { dataIndex: "status" },
            { dataIndex: "participants_count" },
            { dataIndex: "starts_at" },
            { dataIndex: "ends_at" },
            { dataIndex: "created_at" },
          ]}
          rest={{
            onAfterCreate: (pool) => {
              navigate(
                `/manage/courses/${pool.course_id}/pools/${pool.id}?pool_participant_create=true`,
              );
            },
          }}
        />
      ),
    },
    {
      key: "pool-template",
      label: "Шаблоны потоков",
      children: (
        <PoolTemplateTable
          courseId={Number(course_id)}
          columns={[{ dataIndex: "name" }, { dataIndex: "created_at" }]}
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useQueryTabs("pools", tabs);

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <AutoBreadcrumb />
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <Tabs
          activeKey={activeTab}
          items={tabs}
          onChange={setActiveTab}
          tabBarStyle={{ backgroundColor: token.colorBgBase }}
        />
      </Space>
    </Space>
  );
};

export default Page;
