import axios from "@/axios";
import { Department, LearningMatrixEntry, Position } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import {
  ProForm,
  ProFormGroup,
  ProFormProps,
  ProFormSelect,
} from "@ant-design/pro-components";
import { message } from "antd";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type DepartmentPositionSelectDependentMatrixProps = {
  form: Required<ProFormProps>["form"];
  index: number;
  disabled?: boolean;
};

const DepartmentPositionSelectDependentMatrix: React.FC<
  DepartmentPositionSelectDependentMatrixProps
> = ({ form: _form, index, disabled }) => {
  const [form] = ProForm.useForm(_form);
  const [positionIds, setPositionIds] = useState<(number | null)[] | null>(
    null,
  );
  const [departmentIds, setDepartmentIds] = useState<(number | null)[] | null>(
    null,
  );

  const positionId = ProForm.useWatch(
    ["learning_matrix_entries", index, "position_id"],
    form,
  );
  const departmentId = ProForm.useWatch(
    ["learning_matrix_entries", index, "department_id"],
    form,
  );

  useEffect(() => {
    if (!positionId && !departmentId) return;
    form.validateFields([
      ["learning_matrix_entries", index, "position_id"],
      ["learning_matrix_entries", index, "department_id"],
    ]);
  }, [positionId, departmentId]);

  const getLearningMatrixRecords = async (filter: { [key: string]: any }) => {
    const axiosConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/learning-matrix-entries/search",
      ...axiosConfigAdapter(
        {
          pageSize: 20,
        },
        {},
        filter,
      ),
    };

    return await axios
      .request<OrionRestIndexResponse<LearningMatrixEntry>>(axiosConfig)
      .then(({ data }) => data.data)
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const getLearningMatrixRecordsCourseList = async (
    index: number,
    currentRowData: any,
  ) => {
    const filter = {
      department_id: currentRowData?.department_id
        ? [currentRowData?.department_id]
        : [null],
      position_id: currentRowData?.position_id
        ? [currentRowData?.position_id]
        : [null],
    };

    const learningMatrixRecordsCourseList = await getLearningMatrixRecords(
      filter,
    )
      .then((res) => res?.filter((record) => record.course_type === "course"))
      .then((res) => {
        return res?.map((record) => ({
          course_id: record.course_id,
          pool_type: "pool",
          learning_matrix_entry_id: record.id,
          uuid: uuidv4(),
        }));
      });

    form.setFieldValue(
      ["learning_matrix_entries", index, "course_pools"],
      learningMatrixRecordsCourseList,
    );
  };

  const getLearningMatrixOrgStructureIds = async (
    name: "department_id" | "position_id",
    value: any,
  ) => {
    const filter = {
      [name]: value ? [value] : [null],
    };

    const learningMatrixEntries = await getLearningMatrixRecords(filter)
      .then((res) => res?.filter((record) => record.course_type === "course"))
      .then((res) =>
        res?.map((record) =>
          name === "department_id" ? record.position_id : record.department_id,
        ),
      );

    if (!learningMatrixEntries) return;

    if (name === "department_id") {
      setPositionIds(learningMatrixEntries);
    } else {
      setDepartmentIds(learningMatrixEntries);
    }
  };
  return (
    <ProFormGroup>
      <ProFormSelect
        name={"department_id"}
        colProps={{ span: 12 }}
        label={"Подразделение"}
        showSearch
        disabled={disabled}
        rules={[{ required: !positionId }]}
        params={{ departmentIds, index }}
        debounceTime={500}
        request={async (params, props) => {
          const filter: any = {};
          if (params?.departmentIds?.length > 0) {
            filter.id = params?.departmentIds;
          }

          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/departments/search",
            ...axiosConfigAdapter(
              {
                pageSize: 100,
              },
              {},
              filter,
            ),
          };

          axiosConfig.data.filters.push({
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          });

          axiosConfig.data.scopes.push({
            name: "whereHaveInLearningMatrix",
          });

          return axios
            .request<OrionRestIndexResponse<Department>>(axiosConfig)
            .then(async (res) => {
              const data: any = [];

              res.data.data.forEach((department) => {
                data.push({
                  label: department.name,
                  value: department.id,
                });
              });

              if (
                props?.fieldProps.value &&
                !data.some((obj: any) => obj.value === props.fieldProps.value)
              ) {
                const currentData = await axios
                  .get(`/api/departments/${props.fieldProps.value}`)
                  .then(({ data }) => ({
                    label: data.data.name,
                    value: data.data.id,
                  }));
                data.unshift(currentData);
              }

              return data;
            });
        }}
        onChange={async (value) => {
          await getLearningMatrixOrgStructureIds("department_id", value);
          await getLearningMatrixRecordsCourseList(index, {
            department_id: value ?? null,
            position_id: positionId,
          });
        }}
      />
      <ProFormSelect
        name={"position_id"}
        showSearch
        colProps={{ span: 12 }}
        label={"Должность"}
        disabled={disabled}
        params={{ positionIds }}
        rules={[{ required: !departmentId }]}
        debounceTime={500}
        request={async (params, props) => {
          const filter: any = {};
          if (params?.positionIds?.length > 0) {
            filter.id = params?.positionIds;
          }

          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/positions/search",
            ...axiosConfigAdapter(
              {
                pageSize: 100,
              },
              {},
              filter,
            ),
          };

          axiosConfig.data.filters.push({
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          });

          axiosConfig.data.scopes.push({
            name: "whereHaveInLearningMatrix",
          });

          return axios
            .request<OrionRestIndexResponse<Position>>(axiosConfig)
            .then(async (res) => {
              const data: any = [];

              res.data.data.forEach((position) => {
                data.push({
                  label: position.name,
                  value: position.id,
                });
              });

              if (
                props?.fieldProps.value &&
                !data.some((obj: any) => obj.value === props.fieldProps.value)
              ) {
                const currentData = await axios
                  .get(`/api/positions/${props.fieldProps.value}`)
                  .then(({ data }) => ({
                    label: data.data.name,
                    value: data.data.id,
                  }));
                data.unshift(currentData);
              }

              return data;
            });
        }}
        onChange={async (value) => {
          await getLearningMatrixOrgStructureIds("position_id", value);
          await getLearningMatrixRecordsCourseList(index, {
            department_id: departmentId,
            position_id: value ?? null,
          });
        }}
      />
    </ProFormGroup>
  );
};

export default DepartmentPositionSelectDependentMatrix;
export type { DepartmentPositionSelectDependentMatrixProps };
