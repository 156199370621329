import React from "react";
import { Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import AutomationTable from "@/entities/automation/ui/table";

const Page: React.FC = () => {
  return (
    <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <AutomationTable />
    </Space>
  );
};

export default Page;
