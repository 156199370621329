import * as React from "react";
import { Outlet } from "react-router-dom";
import { Grid, Layout as AntLayout, Result } from "antd";
import Menu from "@/pages/space/manage/menu";
import "./layout.css";
import PageContainer from "@/shared/ant-design/page-container/ui";

const { useBreakpoint } = Grid;

let Layout: React.FC = () => {
  const screens = useBreakpoint();

  if (!screens.lg) {
    return (
      <PageContainer
        style={{
          height: "calc(100vh - 64px - 32px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Result
          status={"warning"}
          title={
            "Мобильное разрешение в данном разделе не поддерживается, перейдите в desktop-версию"
          }
        />
      </PageContainer>
    );
  }

  return (
    <AntLayout hasSider>
      <AntLayout.Sider
        className="manage-sider"
        style={{
          width: "200px",
          overflow: "auto",
          height: "calc(100vh - 64px)",
          position: "fixed",
          left: 0,
          top: "64px",
          bottom: 0,
        }}
      >
        <Menu />
      </AntLayout.Sider>

      <AntLayout.Content
        style={{
          marginLeft: 200,
          width: "100%",
          height: "100%",
        }}
      >
        <Outlet />
      </AntLayout.Content>
    </AntLayout>
  );
};

export default Layout;
