import axios from "@/axios.ts";
import { Dashboard, PoolParticipant } from "@/models.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { Pie, PieConfig } from "@ant-design/charts";
import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import dayjs from "dayjs";
import IndicatorIntervalSelect from "@/pages/space/manage/dashboards/indicator-interval-select.tsx";
import { ProForm } from "@ant-design/pro-components";
import statuses from "@/entities/pool-participant/lib/statuses.tsx";
import { Card, Col, Spin, theme } from "antd";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";

type Data = {
  title: React.ReactNode;
  value: number;
};

export const metadata: IndicatorContentMetadata = {
  name: "general_education_statistics",
  title: "Общая статистика обучения",
  position: 3,
};

const IndicatorContentGeneralEducationStatisticPie: React.FC = () => {
  const form = ProForm.useFormInstance<Dashboard>();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );
  const interval = ProForm.useWatch(
    ["settings", metadata.name, "interval"],
    form,
  );
  const { token } = theme.useToken();

  const { data, isLoading, error } = useSWR(
    interval ? ["/api/pool-participants/search", interval] : null,
    async ([url, interval]) => {
      const axiosConfig: AxiosRequestConfig = {
        method: "POST",
        url,
        ...axiosConfigAdapter(),
      };

      const today = dayjs();
      const intervalDaysAgo = today.subtract(interval, "day").toISOString();

      axiosConfig.data.filters = [
        {
          field: "created_at",
          operator: ">",
          value: intervalDaysAgo,
        },
      ];

      const data = [] as Data[];

      for (const [status, statusConfig] of Object.entries(statuses)) {
        axiosConfig.data.filters[1] = {
          field: "status",
          operator: "=",
          value: status,
        };
        const totalPoolParticipantsByStatus = await axios
          .request<OrionRestIndexResponse<PoolParticipant>>(axiosConfig)
          .then((res) => ({
            value: res.data.meta.total,
            title: statusConfig.label,
          }));

        data.push(totalPoolParticipantsByStatus);
      }

      const totalValue = data.reduce((sum, item) => sum + item.value, 0);

      data.forEach((item) => {
        const percent = ((item.value / totalValue) * 100).toFixed(2);
        item.title = `${item.title}, ${item.value} п. (${percent}%)`;
      });

      return data;
    },
  );

  if (error) throw error;

  const config: PieConfig = {
    data: data ?? [],
    forceFit: true,
    appendPadding: 10,
    angleField: "value",
    colorField: "title",
    innerRadius: 0.5,
    radius: 1,
    height: 250,
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    style: {
      inset: 1,
      radius: 4,
    },
    tooltip: false,
    legend: {
      color: {
        itemMarker: "circle",
        title: "type",
        tickCount: 10,
        position: "right",
        rowPadding: 15,
        itemLabelFill: token.colorText,
        layout: {
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      },
    },
  };

  return (
    <>
      {is_visible && (
        <Col span={12}>
          <Card
            title={metadata.title}
            extra={
              <IndicatorIntervalSelect
                name={["settings", metadata.name, "interval"]}
              />
            }
          >
            <Spin spinning={isLoading}>
              <Pie style={{ width: "100%" }} {...config} />
            </Spin>
          </Card>
        </Col>
      )}
    </>
  );
};

export default IndicatorContentGeneralEducationStatisticPie;
