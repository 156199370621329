import React from "react";
import { Course, PoolTemplate } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import PoolTemplateDeleteButton from "@/entities/pool-template/ui/delete-button";
import PoolTemplateModalForm from "@/entities/pool-template/ui/modal-form.tsx";

type Record = PoolTemplate;

type Params = Partial<PoolTemplate>;

type TableProps = BaseProTableProps<Record, Params>;

type PoolTemplateTableProps = TableProps & {
  courseId: Course["id"];
};

const PoolTemplateTable: React.FC<PoolTemplateTableProps> = ({
  courseId,
  ...props
}) => {
  const request: TableProps["request"] = async (params, sort, filter) => {
    const config = {
      method: "POST",
      url: "/api/pool-templates/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    config.data.filters.push({
      field: "course_id",
      operator: "=",
      value: courseId,
    });

    const data = await axios
      .request<OrionRestIndexResponse<PoolTemplate>>(config)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    request,
    toolBarRender: (action, { selectedRowKeys }) => {
      if (action === undefined) {
        throw new Error("action is undefined");
      }
      if (!selectedRowKeys) {
        throw new Error("selectedRowKeys is undefined");
      }

      const toUpdateRecordKey =
        selectedRowKeys.length === 1 ? Number(selectedRowKeys?.[0]) : undefined;
      return [
        <PoolTemplateDeleteButton
          recordKeys={selectedRowKeys.map(Number)}
          onAfterDelete={() => {
            action.reload();
            action.clearSelected?.();
          }}
        />,
        <>
          <PoolTemplateModalForm
            key={`update-${toUpdateRecordKey}`}
            courseId={courseId}
            rest={{
              type: "update",
              recordKey: toUpdateRecordKey,
              onAfterUpdate: () => {
                action.reload();
              },
            }}
          />
        </>,
        <PoolTemplateModalForm
          key={"create"}
          courseId={courseId}
          rest={{
            type: "create",
            onAfterCreate: () => {
              action.reload();
            },
          }}
        />,
      ];
    },
    search: false,
    options: false,
    pagination: { showSizeChanger: true },
    rowSelection: {},
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        filters: true,
        filterMode: "search",
        renderText: (text) => (
          <span style={{ boxSizing: "border-box", wordBreak: "break-word" }}>
            {text}
          </span>
        ),
      },
      {
        title: "Дата и время создания",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
        sorter: true,
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return <ProTable<PoolTemplate> {...props} />;
};

export default PoolTemplateTable;
export type { PoolTemplateTableProps };
