import React, { useEffect } from "react";
import {
  Button,
  Col,
  Flex,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  TabsProps,
  theme,
  Typography,
} from "antd";
import { Member, MemberOrgStructureAssignment } from "@/models";
import useMe from "@/entities/me/lib/use";
import {
  ModalForm,
  ModalFormProps,
  ProDescriptions,
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormList,
  ProFormProps,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useSpace from "@/entities/space/lib/use";
import { date, dateNormalize } from "@/shared/dayjs/lib/formats";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component.tsx";
import ProFormSnils from "@/shared/ant-design-pro-components/form-snils/ui/component.tsx";
import { transliterate } from "@/shared/helpers/transliterate";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Link } from "react-router-dom";
import MemberDepartmentInput from "./department-input";
import MemberPositionInput from "./position-input";
import copy from "copy-to-clipboard";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum.tsx";
import emailPattern from "@/shared/patterns/lib/email-pattern.tsx";

type Props = ProFormProps<Member> & {
  modal?: boolean;
  mode?: "default" | "personal";
  rest: RestProps<Member>;
  memberId?: number;
};

const MemberForm: React.FC<Props> = ({
  modal = false,
  mode = "default",
  rest,
  ...props
}) => {
  const member = useMe();
  const space = useSpace();
  const [form] = ProForm.useForm<Member>(props.form);
  const [toDeleteOrgStructureAssignments, setToDeleteOrgStructureAssignments] =
    React.useState<MemberOrgStructureAssignment[]>([]);
  const orgStructureAssignments = ProForm.useWatch(
    "org_structure_assignments",
    form,
  );

  const [modalMember, modalMemberHolder] = Modal.useModal();
  const { token } = theme.useToken();

  const generateLogin = async () => {
    try {
      await form.validateFields(["last_name", "first_name"]);
    } catch {
      message.error("Для генерации Логина заполните Фамилия и Имя");
      return;
    }

    let generated = "";

    const lastNameInitial = form.getFieldValue("last_name").charAt(0);
    const firstNameInitial = form.getFieldValue("first_name").charAt(0);
    const middleNameInitial =
      form.getFieldValue("middle_name")?.charAt(0) || "";

    generated = transliterate(
      `${lastNameInitial}${firstNameInitial}${middleNameInitial}`.toLowerCase(),
    );

    const randomNumbers = Math.floor(100000 + Math.random() * 900000);
    generated += randomNumbers;
    form.setFieldsValue({ username: generated });
  };

  const showUserModalOnCreate = (member: Member) => {
    modalMember.info({
      title: `Информация о созданном пользователе`,
      okText: "Скопировать все и закрыть",
      onOk: () => {
        let copiedData = `ФИО: ${member.full_name}`;

        if (member.email) {
          copiedData += `\nПочта: ${member.email}`;
        }

        if (member.snils) {
          copiedData += `\nСНИЛС: ${member.snils}`;
        }

        if (member.username) {
          copiedData += `\nЛогин: ${member.username}`;
        }
        copy(copiedData);
        message.info("Данные скопированы в буфер обмена");
      },
      width: "60%",
      icon: null,
      content: (
        <Space direction="vertical">
          <ProDescriptions
            column={1}
            dataSource={member}
            layout="horizontal"
            columns={[
              {
                dataIndex: "full_name",
                title: <Typography.Text>ФИО</Typography.Text>,
              },
              {
                dataIndex: "snils",
                hideInDescriptions: !member.snils,
                title: <Typography.Text>СНИЛС</Typography.Text>,
              },
              {
                dataIndex: "email",
                hideInDescriptions: !member.email,
                title: <Typography.Text>Почта</Typography.Text>,
              },
              {
                dataIndex: "username",
                hideInDescriptions: !member.username,
                title: <Typography.Text>Логин</Typography.Text>,
              },
            ]}
          />
        </Space>
      ),
    });
  };

  let defaultProps: Partial<typeof props> = {
    submitter: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Member>>("/api/members", values)
        .then((res) => {
          message.success("Пользователь успешно создан");
          rest.onAfterCreate?.(res.data.data);
          showUserModalOnCreate(res.data.data);
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании пользователя",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Member>>(`/api/members/${rest.recordKey}`, {
          params: {
            includes: [
              { relation: "org_structure_assignments.position" },
              { relation: "org_structure_assignments.department" },
            ],
          },
        })
        .then((res) => {
          return res.data.data;
        });
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<Member>>(
          `/api/members/${rest.recordKey}`,
          { ...values },
        )
        .catch((err) => {
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
            setActiveTab(
              Object.keys(err.response.data.errors)[0].startsWith("education")
                ? "education"
                : "personal",
            );
          }

          throw err;
        })
        .then(async (res) => {
          if (toDeleteOrgStructureAssignments?.length === 0) return res;
          await axios
            .delete(`/api/member-org-structure-assignments/batch`, {
              data: {
                resources: toDeleteOrgStructureAssignments.map((el) => el.id),
              },
            })
            .then(() => {
              setToDeleteOrgStructureAssignments([]);
            });
          return res;
        })
        .then(async (res) => {
          if (!orgStructureAssignments) return res;
          const toUpdateOrgStructureAssignments =
            orgStructureAssignments.filter((el) => el.id);
          if (toUpdateOrgStructureAssignments.length === 0) return res;
          await axios.patch(`/api/member-org-structure-assignments/batch`, {
            resources: toUpdateOrgStructureAssignments
              .map((el) => {
                return {
                  id: el.id ?? null,
                  member_id: el.member_id ?? null,
                  department_id: el.department_id ?? null,
                  position_id: el.position_id ?? null,
                };
              })
              .reduce(
                (acc, assigment) => {
                  acc[assigment.id] = assigment;
                  return acc;
                },
                {} as Record<string, Partial<MemberOrgStructureAssignment>>,
              ),
          });
          return res;
        })
        .then(async (res) => {
          if (!orgStructureAssignments) return res;
          const toCreateOrgStructureAssignments =
            orgStructureAssignments.filter((el) => !el.id);
          if (toCreateOrgStructureAssignments.length === 0) return res;
          await axios.post<
            OrionRestBatchCreateResponse<MemberOrgStructureAssignment>
          >(`/api/member-org-structure-assignments/batch`, {
            resources: toCreateOrgStructureAssignments.map((el) => {
              return {
                id: el.id ?? null,
                member_id: el.member_id ?? null,
                department_id: el.department_id ?? null,
                position_id: el.position_id ?? null,
              };
            }),
          });
          return res;
        })
        .then(async (res) => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          message.success("Пользователь успешно обновлен");
          rest.onAfterUpdate?.(res.data.data);
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении пользователя: ${messageText}`);

          return false;
        });
    };
  }

  // -------------------------------- Pre Render -------------------------------- //

  const FormComponent = modal ? ModalForm<Member> : ProForm<Member>;
  props = deepmerge(defaultProps, props, overrideProps);

  // -------------------------------- Type Modal -------------------------------- //

  if (modal) {
    const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
    const disabled = rest.type === "update" && rest.recordKey === undefined;

    const modalFormProps: Partial<ModalFormProps<Member>> = {
      title:
        rest.type === "create"
          ? "Создание пользователя"
          : "Редактирование пользователя",
      trigger: (
        <Button type={"primary"} disabled={disabled}>
          {triggerText}
        </Button>
      ),
      modalProps: {
        destroyOnClose: true,
      },
    };

    props = deepmerge(modalFormProps, props);
  }

  // -------------------------------- Render -------------------------------- //
  const employeeEditableSelf =
    !space.is_allow_employee_edit_self_member && member.role === "employee";
  const Submitter: React.FC = () => {
    return (
      <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
        <Button
          disabled={employeeEditableSelf}
          type={"primary"}
          onClick={async () => {
            try {
              await form.validateFields();
            } catch (error: any) {
              const errorFieldName = error.errorFields[0].name[0];
              if (errorFieldName.startsWith("education")) {
                setActiveTab("education");
              } else if (activeTab !== "personal") {
                setActiveTab("personal");
              }
              return;
            }
            form.submit();
          }}
        >
          {rest.type === "create" ? "Создать" : "Сохранить"}
        </Button>
      </Flex>
    );
  };

  const PersonalFormFields: React.FC = () => {
    return (
      <Row gutter={token.paddingXS} style={{ width: "100%" }}>
        <Col xs={24} sm={24} md={rest.type === "update" ? 12 : 24}>
          <ProFormText
            label={"Фамилия"}
            name={"last_name"}
            rules={[{ max: 255, required: true }]}
          />

          <ProFormText
            label={"Имя"}
            name={"first_name"}
            rules={[{ max: 255, required: true }]}
          />

          <ProFormText
            label={"Отчество"}
            name={"middle_name"}
            rules={[{ max: 255, required: false }]}
          />
          <ProFormDatePicker
            label="Дата рождения"
            name="birth_date"
            rules={[{ required: false }]}
            hidden={rest.type !== "update"}
            fieldProps={{ format: date, style: { width: "100%" } }}
            normalize={dateNormalize}
          />
          <ProFormRadio.Group
            label="Пол"
            name="gender"
            rules={[{ required: false }]}
            hidden={rest.type !== "update"}
            options={[
              { value: "male", label: "Мужской" },
              { value: "female", label: "Женский" },
            ]}
            fieldProps={{ optionType: "button", buttonStyle: "solid" }}
          />

          <ProFormText
            label="Гражданство"
            name="citizenship"
            rules={[{ required: false, max: 255 }]}
            hidden={rest.type !== "update"}
          />
        </Col>

        <Col xs={24} sm={24} md={rest.type === "update" ? 12 : 24}>
          <ProFormSnils
            disabled={employeeEditableSelf}
            label={"СНИЛС"}
            name={"snils"}
            style={{ padding: `0 8px` }}
            rules={[{ required: false }]}
          />
          <ProFormPhone
            disabled={employeeEditableSelf}
            label={"Телефон"}
            name={"phone"}
            style={{ padding: `0 8px` }}
            rules={[{ required: false }]}
            hidden={rest.type !== "update"}
          />
          <ProFormText
            label={"Почта"}
            name={"email"}
            rules={[
              {
                required: space.authentication_types.includes("email+password"),
              },
              {
                type: "email",
                message: "Атрибут email должен быть действительным email",
              },
              { pattern: emailPattern, message: "Некорректный формат почты" },
            ]}
          />
          <Flex vertical style={{ position: "relative", width: "100%" }}>
            <Button
              type="link"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
                padding: 0,
                paddingBottom: 4,
              }}
              onClick={generateLogin}
            >
              Сгенерировать
            </Button>
            <ProFormText
              label={"Логин"}
              name={"username"}
              rules={[
                {
                  required:
                    space.authentication_types.includes("username+password"),
                  max: 255,
                },
              ]}
            />
          </Flex>
          {member.role === "administrator" && mode === "default" && (
            <ProFormSelect
              label="Роль"
              name="role"
              rules={[{ required: true }]}
              options={[
                { value: "administrator", label: "Администратор" },
                { value: "employee", label: "Сотрудник" },
              ]}
            />
          )}
          {member.role === "administrator" && mode === "default" && (
            <ProFormSelect
              label="Статус"
              name="status"
              rules={[{ required: rest.type === "update" }]}
              hidden={rest.type !== "update"}
              valueEnum={statusValueEnum}
            />
          )}
        </Col>
      </Row>
    );
  };

  const tabs: TabsProps["items"] = [
    ...(mode !== "personal" && rest.type === "update"
      ? [
          {
            key: "pools",
            label: "Назначенное обучение",
            children: (
              <PoolParticipantTable
                memberId={Number(rest.recordKey)}
                toolBarActionsProps={{
                  create: { triggerProps: { style: { display: "none" } } },
                  remove: { children: "Удалить обучение" },
                }}
                columns={[
                  { dataIndex: "registry_number" },
                  {
                    dataIndex: ["pool", "course", "id"],
                    render: (dom, { pool: { course } }) =>
                      course.deleted_at === null ? (
                        <Link to={`/manage/courses/${course.id}`}>{dom}</Link>
                      ) : (
                        <Typography.Text>{dom}</Typography.Text>
                      ),
                  },
                  {
                    dataIndex: ["pool", "id"],
                    render: (dom, { pool: { course, ...pool } }) =>
                      course.deleted_at === null ? (
                        <Link
                          to={`/manage/courses/${course.id}/pools/${pool.id}`}
                        >
                          {dom}
                        </Link>
                      ) : (
                        <Typography.Text>{dom}</Typography.Text>
                      ),
                  },
                  { dataIndex: "status" },
                  { dataIndex: "progress_percent" },
                  { dataIndex: "starts_at" },
                  { dataIndex: "started_at" },
                  { dataIndex: "ends_at" },
                  { dataIndex: "ended_at" },
                ]}
                search={false}
              />
            ),
          },
        ]
      : []),
    {
      key: "personal",
      label: "Личная информация",
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{ padding: token.paddingMD, paddingTop: 0, width: "100%" }}
        >
          <ProForm
            disabled={employeeEditableSelf}
            form={form}
            initialValues={{ role: "employee" }}
            grid={true}
            {...props}
          >
            <Submitter />
            <PersonalFormFields />
          </ProForm>
        </Space>
      ),
    },
    {
      key: "org_structure_assignments",
      label: "Работа",
      forceRender: true,
      children: (
        <Space
          direction={"vertical"}
          style={{
            width: "100%",
            height: "100%",
            padding: token.paddingMD,
            paddingTop: 0,
          }}
        >
          <ProForm
            disabled={employeeEditableSelf}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter />
            <ProFormList
              max={5}
              name={"org_structure_assignments"}
              creatorRecord={{
                // @ts-ignore
                member_id: rest.recordKey ?? undefined,
              }}
              creatorButtonProps={{
                creatorButtonText: "Добавить запись о работе",
                type: "primary",
                block: false,
                icon: false,
                style: { marginLeft: "4px" },
              }}
              copyIconProps={false}
              deleteIconProps={
                employeeEditableSelf ? false : { tooltipText: "Удалить" }
              }
              onAfterRemove={async (index) => {
                if (
                  orgStructureAssignments &&
                  typeof index === "number" &&
                  orgStructureAssignments[index].id
                ) {
                  setToDeleteOrgStructureAssignments((prev) => [
                    ...prev,
                    orgStructureAssignments[index],
                  ]);
                }
              }}
            >
              {(_, index, action) => {
                return (
                  <ProForm.Group>
                    <ProFormDigit name={"id"} hidden />
                    <ProFormDigit name={"member_id"} hidden />
                    <Row gutter={token.paddingXS} style={{ width: "100%" }}>
                      <Col span={12}>
                        <MemberDepartmentInput
                          label={"Подразделение"}
                          placeholder={"Введите значение"}
                          name={"department_id"}
                        />
                      </Col>
                      <Col span={12}>
                        <MemberPositionInput
                          label={"Должность"}
                          name={"position_id"}
                          placeholder={"Выберите значение"}
                        />
                      </Col>
                    </Row>
                  </ProForm.Group>
                );
              }}
            </ProFormList>
          </ProForm>
        </Space>
      ),
    },
    {
      key: "education",
      label: "Образование",
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{ padding: token.paddingMD, paddingTop: 0, width: "100%" }}
        >
          <ProForm
            disabled={employeeEditableSelf}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter />
            <Row gutter={token.paddingXS} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={12}>
                <ProFormText
                  label="Уровень образования"
                  name="education_level"
                  rules={[{ required: false, max: 255 }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Серия диплома"
                  name="education_diploma_series"
                  rules={[{ required: false, max: 255, min: 10 }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Номер диплома"
                  name="education_diploma_number"
                  rules={[{ required: false }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Фамилия в дипломе"
                  name="education_diploma_last_name"
                  rules={[{ required: false, max: 255 }]}
                  hidden={rest.type !== "update"}
                />
              </Col>
            </Row>
          </ProForm>
        </Space>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useQueryTabs(
    rest.type === "create" ? undefined : (mode === "personal" ? "personal" : "pools"),
    tabs,
  );

  useEffect(() => {
    console.debug(
      "toDeleteOrgStructureAssignments",
      toDeleteOrgStructureAssignments,
    );
  }, [toDeleteOrgStructureAssignments]);

  useEffect(() => {
    console.debug("orgStructureAssignments", orgStructureAssignments);
  }, [orgStructureAssignments]);

  return (
    <>
      {rest.type === "create" && (
        <>
          {modalMemberHolder}
          <FormComponent
            form={form}
            grid={true}
            initialValues={{
              role: "employee",
            }}
            {...props}
            submitter={{
              resetButtonProps: false,
              searchConfig: {
                submitText: "Создать",
              },
            }}
          >
            <PersonalFormFields />
          </FormComponent>
        </>
      )}
      {rest.type === "update" && (
        <Tabs
          activeKey={activeTab}
          items={tabs}
          destroyInactiveTabPane={false}
          onChange={setActiveTab}
          style={{ width: "100%" }}
        />
      )}
    </>
  );
};
export default MemberForm;
