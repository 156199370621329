import React, { useState } from "react";
import {
  Button,
  Divider,
  Input,
  Space,
  Typography,
  message,
  theme,
} from "antd";
import useSWR from "swr";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import { Course, ExternalCourse } from "@/models";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";

type CourseSelectWithExternalProps = ProFormSelectProps;

const CourseSelectWithExternal: React.FC<CourseSelectWithExternalProps> = ({
  ...props
}) => {
  const { token } = theme.useToken();
  const { data, mutate } = useSWR("/api/courses", async (url) => {
    const courses = await axios
      .get<OrionRestShowResponse<Course[]>>(url)
      .then((res) =>
        res.data.data.map((item) => ({
          ...item,
          type: "course",
        })),
      );

    const externalCourses = await axios
      .get<OrionRestShowResponse<ExternalCourse[]>>("/api/external-courses")
      .then((res) =>
        res.data.data.map((item) => ({
          ...item,
          type: "external_course",
        })),
      );

    return [...courses, ...externalCourses];
  });
  const [name, setName] = useState("");

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addExternalCourse = async () => {
    await axios
      .post<OrionRestCreateResponse<Course>>(`/api/external-courses`, {
        name,
      })
      .then(async (res) => {
        await mutate();
        return res.data.data;
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "Ошибка добавления курса");
      });
    setName("");
  };

  return (
    <ProFormSelect
      showSearch
      className="course-select"
      options={data?.map((value) => ({
        value: `${value.type}#${value.id}`,
        label: value.name,
        type: value.type,
      }))}
      fieldProps={{
        dropdownRender: (menu) => (
          <>
            {menu}

            <>
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input
                  placeholder="Введите значение"
                  value={name}
                  onChange={onNameChange}
                  maxLength={255}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => addExternalCourse()}
                  disabled={!name}
                >
                  Создать курс
                </Button>
              </Space>
            </>
          </>
        ),
        optionItemRender: (item: any) => (
          <div
            style={{
              background:
                item.type === "external_course" ? token.gold1 : "none",
              borderRadius: token.borderRadius,
              paddingLeft: token.paddingXXS,
              display: "flex",
            }}
            className={item.type}
          >
            <Typography.Text ellipsis> {item.label}</Typography.Text>
          </div>
        ),
      }}
      {...props}
    />
  );
};

export default CourseSelectWithExternal;
export type { CourseSelectWithExternalProps };
